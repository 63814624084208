import "../css/Questionnaire.sass";
import "animate.css";
import "rodal/lib/rodal.css";

import { AnimatePresence, motion } from "framer-motion";
// import QuestionnaireOffrir from "./QuestionnaireOffrir.js";
import { BrowserView, MobileView } from "react-device-detect";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import React, { useCallback, useEffect, useRef, useState } from "react";

import AlgoliaPlaces from "algolia-places-react";
// import ButtonLivraisonMode from "../components/ButtonLivraisonMode.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
// import parsePhoneNumber from "libphonenumber-js";
import InputSlider from "react-input-slider";
// import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import OutsideClickHandler from "react-outside-click-handler";
import PopupNewsletter from "../components/questionnaire/PopupNewsletter.js";
import PopupNoDelivery from "../components/questionnaire/PopupNoDelivery.js";
import PopupRecapGift from "../components/questionnaire/PopupRecapGift.js";
import QuestionnaireCreneau from "../components/questionnaire/QuestionnaireCreneau";
import QuestionnaireTaste from "../components/questionnaire/QuestionnaireTaste.js";
import RegionsCheckboxes from "../components/questionnaire/regionsCheckboxes";
import Rodal from "rodal";
import Slider from "react-slick";
import SliderPack from "../components/SliderPack";
import arrow from "../img/steps/arrow.svg";
import axios from "axios";
import bouteille from "../img/bouteille.svg";
import checked from "../img/steps/1_checked.svg";
import paymentOK from "../img/steps/3_payment_OK.svg";
import phone from "../img/steps/2_phone.svg";
import preloader from "../img/preloader.gif";
import shipping from "../img/steps/4_shipping.svg";

console.log({ ChartDataLabels });

//Algolia Places
var validator = require("email-validator");

function Questionnaire(props) {
  const [customerAirTableID, setCustomerAirTableID] = useState("");

  const [email, setEmail] = useState("");
  const [codeCadeau, setCodeCadeau] = useState("");
  const [prenomNom, setPrenomNom] = useState("");
  const [budgetTotal, setBudgetTotal] = useState(500);

  const [nbBouteilles, setNbBouteilles] = useState(24);
  const [nature, setNature] = useState("");
  const [propRouge, setPropRouge] = useState(35);
  const [propBlanc, setPropBlanc] = useState(35);
  const [propRosé, setPropRosé] = useState(15);
  const [propChampagne, setPropChampagne] = useState(15);
  const [propHDG, setPropHDG] = useState(40);
  const [propMDG, setPropMDG] = useState(40);
  const [propEDG, setPropEDG] = useState(40);
  // const [rougeLegerPuissant, setRougeLegerPuissant] = useState(1);
  // const [blancSecFruite, setBlancSecFruite] = useState(1);
  // const [goutsCarteBlanche, setGoutsCarteBlanche] = useState("Oui");
  const [blancTagsDisliked, setBlancTagsDisliked] = useState("");
  const [blancTagsNormal, setBlancTagsNormal] = useState("");
  const [blancTagsLiked, setBlancTagsLiked] = useState("");
  const [rougeTagsDisliked, setRougeTagsDisliked] = useState("");
  const [rougeTagsNormal, setRougeTagsNormal] = useState("");
  const [rougeTagsLiked, setRougeTagsLiked] = useState("");
  const [regionsPlusBlanc, setRegionsPlusBlanc] = useState([]);
  const [regionsMoinsBlanc, setRegionsMoinsBlanc] = useState([]);
  const [regionsPlusRouge, setRegionsPlusRouge] = useState([]);
  const [regionsMoinsRouge, setRegionsMoinsRouge] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [aContacter, setAContacter] = useState("tel");
  const [adresseVoie, setAdresseVoie] = useState("");
  const [adresseNotes, setAdresseNotes] = useState("");
  const [adresseCP, setAdresseCP] = useState("");
  const [adresseVille, setAdresseVille] = useState("");
  const [livraisonMode, setLivraisonMode] = useState("zoneC");
  // const [livraisonPrix, setLivraisonPrix] = useState();
  const [livraisonCreneau, setLivraisonCreneau] = useState(undefined);

  const [isValidGift, setIsValidGift] = useState(false);
  const [loading, setLoading] = useState(false);

  const [adresseValue, setAdresseValue] = useState("");
  // const [livraisonOfferte, setLivraisonOfferte] = useState("");
  const [questionnaireTerminé, setQuestionnaireTerminé] = useState(false);
  const [livraisonCreneauTime, setLivraisonCreneauTime] = useState(7);
  const [livraisonCreneauDate, setLivraisonCreneauDate] = useState(undefined);

  const [chartdata, setChartdata] = useState({});
  const [chart2data, setChart2data] = useState({});
  const [chartoptions, setChartoptions] = useState({});
  const [chartoptionsMobile, setChartoptionsMobile] = useState({});
  const [cardHDGOpened, setCardHDGOpened] = useState(false);
  const [cardMDGOpened, setCardMDGOpened] = useState(false);
  const [cardEDGOpened, setCardEDGOpened] = useState(false);
  const [lockBudget, setLockBudget] = useState("propGammes");
  const [regionCardOpened, setRegionCardOpened] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  //CONFIRME POPUP START
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [sentPopup, setSentPopup] = useState(false);
  const [infoNaturePopup, setInfoNaturePopup] = useState(false);
  const [noDeliveryPopup, setNoDeliveryPop] = useState(false);
  const [popupNewsletter, setPopupNewsletter] = useState(false);
  // const [popupCalendly, setPopupCalendly] = useState(false);

  const nbRegions = props.data.items[0].fields.regions.length;

  const prixUnitEDG = 8;
  const prixUnitMDG = 18;
  const prixUnitHDG = 30;

  var slider = useRef();
  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}

  // var zoneAPrice = props.data.items[0].fields.livraisons.fields.prixZoneA;
  // var zoneBPrice = props.data.items[0].fields.livraisons.fields.prixZoneB;
  // var zoneCPrice = props.data.items[0].fields.livraisons.fields.prixZoneC;

  var threeDaysFromToday = new Date();
  threeDaysFromToday.setDate(threeDaysFromToday.getDate() + 3);

  // console.log({ livraisonMode });
  // console.log({ livraisonPrix });

  const toggleCardsRegionsOff = (index) => {
    var stateArray = [];
    for (let i = 0; i < nbRegions; i++) {
      //Quand on arrive à la ligne de l'événement cliqué
      if (i === index) {
        //Toggle l'ouverture
        stateArray.push(!regionCardOpened[index]);
      } else {
        //Fermer les autres
        stateArray.push(false);
      }
    }
    setRegionCardOpened(stateArray);
  };

  const updateOptions = () => {
    setChartoptions({
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          fontColor: "rgb(255, 255, 255)",
        },
      },
      // DISPLAY TEXT ON PIE SEGMENTS
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return sum;
            });
            let percentage = ((value * 100) / sum).toFixed(0) + "%";
            return percentage;
          },
          color: "white",
          font: {
            weight: "bold",
            size: 18,
          },
        },
      },
    });
    setChartoptionsMobile({
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          fontColor: "black",
        },
      },
      // DISPLAY TEXT ON PIE SEGMENTS
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return sum;
            });
            let percentage = ((value * 100) / sum).toFixed(0) + "%";
            return percentage;
          },
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    });
  };

  //USEEFFECT CRÉNEAU LIVRAISON
  useEffect(() => {
    if (livraisonCreneauDate !== undefined) {
      var newLivraisonCreneau = new Date(livraisonCreneauDate);
      newLivraisonCreneau.setHours(livraisonCreneauTime, 0, 0);
      console.log("newLivraisonCreneau : ", newLivraisonCreneau);
      setLivraisonCreneau(newLivraisonCreneau);
    }
  }, [livraisonCreneauDate, livraisonCreneauTime]);

  //USEEFFECT DATA CHARTJS GRAPHS
  useEffect(() => {
    setChartdata({
      labels: ["Rouge", "Blanc", "Rosé", "Champagne"],
      datasets: [
        {
          label: "Répartition types de vin",
          backgroundColor: ["#F50208", "#0773A1", "#F08F7C", "#F8DBA1"],
          data: [propRouge, propBlanc, propRosé, propChampagne],
        },
      ],
    });

    setChart2data({
      labels: ["Haut de gamme", "Moyen de gamme", "Entrée de gamme"],
      datasets: [
        {
          label: "Répartition gammes de vin",
          backgroundColor: ["#ffe888", "#DCDCDC", "#d2be93"],
          data: [propHDG, propMDG, propEDG],
        },
      ],
    });
    updateOptions();
  }, [
    propBlanc,
    propChampagne,
    propEDG,
    propHDG,
    propMDG,
    propRosé,
    propRouge,
  ]);

  // useEffect(() => {
  //   const

  // }, [
  //   propEDG,
  //   propMDG,
  //   propHDG,
  // ]);

  const minBtle = Math.floor(budgetTotal / prixUnitHDG);
  const maxBtle = Math.floor(budgetTotal / prixUnitEDG);

  const ratioGammes = (nbBouteilles - maxBtle) / (minBtle - maxBtle);

  // const ratioGammes = useCallback(() => {
  //   return (nbBouteilles - maxBtle) / (minBtle - nbBouteilles);
  // }, [maxBtle, minBtle, nbBouteilles]);

  const qttEDG = useCallback(() => {
    return (
      Math.floor(nbBouteilles - budgetTotal / prixUnitHDG) /
      (1 -
        prixUnitEDG / prixUnitHDG +
        ratioGammes * (1 - prixUnitMDG / prixUnitHDG))
    );
  }, [budgetTotal, nbBouteilles, ratioGammes]);

  const qttMDG = useCallback(() => {
    return Math.floor(ratioGammes * qttEDG());
  }, [qttEDG, ratioGammes]);

  const qttHDG = useCallback(() => {
    const newQttMDG = Math.floor(
      (budgetTotal - prixUnitEDG * qttEDG() - prixUnitMDG * qttMDG()) /
        prixUnitHDG
    );
    if (newQttMDG <= 0) {
      return 0;
    } else {
      return newQttMDG;
    }
  }, [budgetTotal, qttEDG, qttMDG]);

  // console.log("-------------");
  // console.log("nbBouteilles : ", nbBouteilles);
  // console.log("ETA : ", ratioGammes);
  // console.log("minBtle : ", minBtle);
  // console.log("maxBtle : ", maxBtle);
  // console.log("qttEDG : ", qttEDG());
  // console.log("qttMDG : ", qttMDG());
  // console.log("qttHDG : ", qttHDG());
  // console.log("propEDG : ", propEDG);
  // console.log("propMDG : ", propMDG);
  // console.log("propHDG : ", propHDG);

  //USEEFFECT UPDATE PROP GAMMES NBBOUTEILLES
  useEffect(() => {
    // console.log("minBtle : ", minBtle());
    // console.log("maxBtle : ", maxBtle());

    const newNbBouteilles = () => {
      return Math.floor(
        budgetTotal /
          (prixUnitEDG * (propEDG / (propHDG + propMDG + propEDG)) +
            prixUnitMDG * (propMDG / (propHDG + propMDG + propEDG)) +
            prixUnitHDG * (propHDG / (propHDG + propMDG + propEDG)))
      );
    };

    if (lockBudget === "propGammes") {
      // setRatioGammes(qttMDG() / qttEDG());
      if (nbBouteilles >= minBtle && nbBouteilles < maxBtle) {
        setNbBouteilles(newNbBouteilles());
      } else if (nbBouteilles < minBtle) {
        setNbBouteilles(minBtle);
      } else if (nbBouteilles >= maxBtle) {
        setNbBouteilles(maxBtle);
      }
    } else if (lockBudget === "nbBouteilles") {
      if (nbBouteilles > minBtle && nbBouteilles < maxBtle) {
        setPropEDG(qttEDG());
        setPropMDG(qttMDG());
        setPropHDG(qttHDG());
      } else if (nbBouteilles <= minBtle) {
        setNbBouteilles(minBtle);
        setPropEDG(0);
        setPropMDG(0);
        setPropHDG(qttHDG());
      } else if (nbBouteilles >= maxBtle) {
        setNbBouteilles(maxBtle);
        setPropEDG(qttEDG());
        setPropMDG(0);
        setPropHDG(0);
      }
    }
  }, [
    budgetTotal,
    nbBouteilles,
    lockBudget,
    propEDG,
    propMDG,
    propHDG,
    qttEDG,
    qttMDG,
    qttHDG,
    maxBtle,
    minBtle,
  ]);

  useEffect(() => {
    var stateArray = [];
    for (let i = 0; i < props.data.items[0].fields.regions.length; i++) {
      stateArray.push(false);
    }
    setRegionCardOpened(stateArray);
  }, [props.data]);

  useEffect(() => {
    if (
      props.data.items[0].fields.livraisons.fields.zoneA.includes(adresseCP)
    ) {
      console.log("Code postal = zone A");
      setLivraisonMode("zoneA");
    } else if (
      props.data.items[0].fields.livraisons.fields.zoneB.includes(adresseCP)
    ) {
      console.log("Code postal = zone B");
      setLivraisonMode("zoneB");
    } else if (adresseCP === "") {
      console.log("Code postal = hors France");
      setLivraisonMode("");
    } else {
      console.log("Code postal = zone C");
      setLivraisonMode("zoneC");
      setLivraisonCreneauTime(9);
    }
  }, [adresseCP, props.data.items]);

  // START SLIDE
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    swipe: false,
    initialSlide: 0,
    arrows: false,
    beforeChange: (current, next) => startChangeSlide(next),
  };

  // window.addEventListener("resize", () => {
  //   // We execute the same script as before
  //   let vhmobileheight = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty(
  //     "--vhmobileheight",
  //     `${vhmobileheight}px`
  //   );
  // });

  const sliderStyles = {
    track: {
      display: "block",
      height: "30px",
      zIndex: "0",
      minWidth: "200px",
      maxWidth: "300px",
      width: "100%",
      backgroundColor: "#ffdfdf",
      boxShadow: "2px 2px 5px 2px rgba(42, 42, 42, 0.17)",
      cursor: "pointer",
      transition: "all 0.3s",
    },
    active: {
      backgroundColor: "#000000",
    },
    thumb: {
      width: 0,
      height: 0,
    },
    disabled: {
      opacity: 0.5,
    },
  };

  const startChangeSlide = (next) => {
    setCurrentSlide(next);
    // console.log("current slide", next);
    // console.log("current slide state", currentSlide);
  };

  const allCardsGammeOff = () => {
    setCardHDGOpened(false);
    setCardMDGOpened(false);
    setCardEDGOpened(false);
  };

  return (
    <div className="q_container">
      <Navbar logo={props.logo} page="questionnaire" />
      <div
        className="q_page"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (questionnaireTerminé) {
              setConfirmPopup(true);
            } else if (isValidGift) {
              slider.slickNext();
            }
          }
        }}
      >
        <PopupNewsletter
          visible={popupNewsletter}
          toggleStateNewsletter={() => {
            setPopupNewsletter(!popupNewsletter);
          }}
        />
        {/* POPUP INFO VIN NATURE ET BIO --------------- */}
        <Rodal
          visible={infoNaturePopup}
          onClose={(event) => setInfoNaturePopup(false)}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <div className="smallPopupTitre">Le vin nature</div>
              <div className="smallPopupText">
                En plus d'une viticulture biologique, la vinification se veut la
                plus naturelle possible. Les levures aromatiques externes sont
                proscrites, et seules celles issues du produit originel déjà
                contenues dans le raisin sont à l'œuvre. Aucun sucre n'est
                ajouté, le vin est peu ou pas filtré (ce qui peut donner parfois
                une couleur un peu trouble).
              </div>
              <div className="smallPopupEnd">
                <div>
                  Retrouve l'article complet sur{" "}
                  <a href="/blog/les-traitements-de-la-vigne" target="_blank">
                    <i>S.O.I.F</i>
                  </a>
                  , le blog de La Cave des Vendanges d'Antoine.
                </div>
              </div>
            </div>
          </div>
        </Rodal>
        {/* POPUP CALENDLY --------------- */}
        {/* <Rodal
          visible={popupCalendly}
          onClose={(event) => {
            setPopupCalendly(false);
            window.location.href = "/";
          }}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <InlineWidget url="https://calendly.com/antoine-maffray/30min" />
            </div>
          </div>
        </Rodal> */}

        {/* POPUP CONFIRMATION QUESTIONNAIRE ENVOYÉ --------------- */}
        <Rodal
          visible={sentPopup}
          onClose={(event) => (window.location.href = "/")}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <div className="smallPopupTitre">
                Toutes les informations ont bien été transmises.
              </div>
              <div className="sentPopupStepsContainer">
                <div className="stepContainer">
                  <img className="stepImage" src={paymentOK} alt="pay" />
                  <span className="stepTexte">Paiement</span>
                </div>
                <div className="stepArrowContainer">
                  <img className="stepImage" src={arrow} alt="arrow" />
                  <div className="stepTexte"></div>
                </div>
                <div className="stepContainer">
                  <img className="stepImage" src={checked} alt="checked" />
                  <span className="stepTexte">Questionnaire</span>
                </div>
                {aContacter !== "non" && (
                  <>
                    <div className="stepArrowContainer">
                      <img className="stepImage" src={arrow} alt="arrow" />
                      <div className="stepTexte"></div>
                    </div>
                    <div className="stepContainer">
                      <img className="stepImage" src={phone} alt="phone" />
                      <span className="stepTexte">Débrief</span>
                    </div>
                  </>
                )}
                <div className="stepArrowContainer">
                  <img className="stepImage" src={arrow} alt="arrow" />
                  <div className="stepTexte"></div>
                </div>
                <div className="stepContainer">
                  <img className="stepImage" src={shipping} alt="colis" />
                  <span className="stepTexte">Livraison</span>
                </div>
              </div>
              {/* <div>{customerAirTableID}</div> */}
              {(() => {
                if (aContacter === "mail") {
                  return (
                    <div className="smallPopupEnd">
                      <div className="smallPopupTexte">
                        Antoine te contacte bientôt pour un petit débrief par
                        mail !
                      </div>
                    </div>
                  );
                } else if (aContacter === "tel") {
                  return (
                    <div className="smallPopupEnd">
                      <a
                        href={
                          "https://calendly.com/antoine-maffray/30min?name=" +
                          prenomNom +
                          "&email=" +
                          email
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="q_button"
                        // onClick={() => {
                        //   setSentPopup(false);
                        //   setPopupCalendly(true);
                        // }}
                      >
                        BOOKER UN APPEL
                      </a>
                    </div>
                  );
                } else if (aContacter === "non") {
                  return <div className="smallPopupEnd"></div>;
                }
              })()}
            </div>
          </div>
        </Rodal>
        {/* POPUP CONFIRMATION ENVOI QUESTIONNAIRE --------------- */}

        {/* POPUP PAS DE LIVRAISON ICI --------------- */}
        <PopupNoDelivery
          noDeliveryPopup={noDeliveryPopup}
          onClosePopup={() => {
            setAdresseVoie("");
            setAdresseCP("");
            setAdresseVille("");
            setNoDeliveryPop(false);
          }}
          slider={slider}
          onClickButton={() => {
            setNoDeliveryPop(false);
            setPopupNewsletter(true);
          }}
          codesPostaux={
            props.data.items[0].fields.livraisons.fields.codesPostaux
          }
          adresseVille={adresseVille}
        />
        {/* POPUP PAS DE LIVRAISON ICI --------------- */}

        {/* POPUP RÉCAPITULATIF --------------- */}
        <PopupRecapGift
          visible={confirmPopup}
          slider1={() => slider.slickGoTo(1)}
          slider2={() => slider.slickGoTo(2)}
          slider3={() => slider.slickGoTo(3)}
          slider4={() => slider.slickGoTo(4)}
          slider5={() => slider.slickGoTo(5)}
          slider6={() => slider.slickGoTo(6)}
          slider7={() => slider.slickGoTo(7)}
          slider8={() => slider.slickGoTo(8)}
          slider9={() => slider.slickGoTo(9)}
          setConfirmPopup={(bool) => setConfirmPopup(bool)}
          setNoDeliveryPop={(bool) => setNoDeliveryPop(bool)}
          handleSend={async () => {
            console.log("handleSend /savequestionnairecadeau");
            const response = await axios.post(
              props.backendurl + "/savequestionnairecadeau",
              {
                customerId: customerAirTableID,
                // prenomNom: prenomNom,
                nbBouteilles: nbBouteilles,
                nature: nature,
                propRouge: propRouge,
                propBlanc: propBlanc,
                propRosé: propRosé,
                propChampagne: propChampagne,
                propHDG: propHDG,
                propMDG: propMDG,
                propEDG: propEDG,
                budgetTotal: budgetTotal,
                // rougeLegerPuissant: rougeLegerPuissant,
                // blancSecFruite: blancSecFruite,
                // goutsCarteBlanche: goutsCarteBlanche,
                blancTagsDisliked: blancTagsDisliked,
                blancTagsNormal: blancTagsNormal,
                blancTagsLiked: blancTagsLiked,
                rougeTagsDisliked: rougeTagsDisliked,
                rougeTagsNormal: rougeTagsNormal,
                rougeTagsLiked: rougeTagsLiked,
                regionsMoinsBlanc: regionsMoinsBlanc,
                regionsPlusBlanc: regionsPlusBlanc,
                regionsMoinsRouge: regionsMoinsRouge,
                regionsPlusRouge: regionsPlusRouge,
                telephone: telephone,
                // email: email,
                aContacter: aContacter,
                adresseVoie: adresseVoie,
                adresseNotes: adresseNotes,
                adresseCP: adresseCP,
                adresseVille: adresseVille,
                livraisonMode: livraisonMode,
                // livraisonPrix: livraisonPrix,
                livraisonCreneau: livraisonCreneau,
              }
            );
            console.log(response);
            // QUESTIONNAIRE AJOUTÉ OU MISE À JOUR EN BDD => ENVOI LIEN DE PAIEMENT
            if (response.data.message === "ok") {
              // var newCustomerId = response.data.id;
              setConfirmPopup(false);
              setSentPopup(true);
              // setCustomerAirTableID(newCustomerId);
              axios.post(props.backendurl + "/sendlink", {
                // customerId: customerAirTableID,
                customerId: customerAirTableID,
              });
              //SAVE RÉPONSES IMPOSSIBLE, COMMANDE DÉJÀ PAYÉE
            } else if (response.data.message === "error") {
              console.log("ERREUR MISE À JOUR COMMANDE CADEAU");
              console.log("response.data : ", response.data);
              const win = window.open(
                "/paiement?id=" + response.data,
                "_blank"
              );
              win.focus();
            }
          }}
          codesPostaux={
            props.data.items[0].fields.livraisons.fields.codesPostaux
          }
          prenomNom={prenomNom}
          nbBouteilles={nbBouteilles}
          nature={nature}
          propRouge={propRouge}
          propBlanc={propBlanc}
          propRosé={propRosé}
          propChampagne={propChampagne}
          propHDG={propHDG}
          propMDG={propMDG}
          propEDG={propEDG}
          budgetTotal={budgetTotal}
          // rougeLegerPuissant={rougeLegerPuissant}
          // blancSecFruite={blancSecFruite}
          // goutsCarteBlanche={goutsCarteBlanche}
          blancTagsDisliked={blancTagsDisliked}
          blancTagsNormal={blancTagsNormal}
          blancTagsLiked={blancTagsLiked}
          rougeTagsDisliked={rougeTagsDisliked}
          rougeTagsNormal={rougeTagsNormal}
          rougeTagsLiked={rougeTagsLiked}
          regionsMoinsBlanc={regionsMoinsBlanc}
          regionsPlusBlanc={regionsPlusBlanc}
          regionsMoinsRouge={regionsMoinsRouge}
          regionsPlusRouge={regionsPlusRouge}
          telephone={telephone}
          email={email}
          aContacter={aContacter}
          adresseValue={adresseValue}
          livraisonCreneau={livraisonCreneau}
          // livraisonPrix={livraisonPrix}
          adresseNotes={adresseNotes}
        />
        {/* POPUP RÉCAPITULATIF --------------- */}

        <Slider
          {...settings}
          // style={{ paddingTop: "70px" }}
          ref={(c) => (slider = c)}
          id="slider"
        >
          {/* ______________________ SLIDE 0 LOGIN */}
          <div className="q_slide_container">
            <div className="q_slide">
              <div style={{ width: "100%" }}>
                <h5
                  className={
                    currentSlide === 0
                      ? "q_question animate__animated animate__fadeInDown animate__delay-3s"
                      : "q_question"
                  }
                >
                  C'est parti, on a d'abord besoin de ton mail
                </h5>
                <input
                  placeholder="Email"
                  name="name"
                  value={email}
                  autoComplete="nope"
                  onChange={(e) => setEmail(e.target.value)}
                  type="input"
                  className={
                    currentSlide === 0
                      ? "form-control-material animate__animated animate__fadeInDown animate__delay-3s"
                      : "form-control-material"
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h5
                  className={
                    currentSlide === 0
                      ? "q_question animate__animated animate__fadeInDown animate__delay-3s"
                      : "q_question"
                  }
                >
                  Et de ton code cadeau
                </h5>
                <input
                  placeholder="Code cadeau"
                  name="code"
                  value={codeCadeau}
                  autoComplete="nope"
                  onChange={(e) => setCodeCadeau(e.target.value)}
                  type="input"
                  className={
                    currentSlide === 0
                      ? "form-control-material animate__animated animate__fadeInDown animate__delay-3s"
                      : "form-control-material"
                  }
                />
              </div>
              <AnimatePresence exitBeforeEnter>
                <div
                  style={{
                    minHeight: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isValidGift && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                    >
                      <h5 className="q_question">
                        Email et code cadeau valides !<br />
                      </h5>
                      <button
                        name="Commencer"
                        className={
                          currentSlide === 0
                            ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                            : "q_button"
                        }
                        onClick={async () => slider.slickNext()}
                      >
                        COMMENCER
                      </button>
                    </motion.div>
                  )}
                  {!isValidGift && loading && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                    >
                      <img
                        style={{ maxHeight: "100px" }}
                        src={preloader}
                        alt="loading"
                      ></img>
                    </motion.div>
                  )}
                  {!isValidGift && !loading && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1.5 }}
                      name="Valider"
                      className={
                        currentSlide === 0
                          ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                          : "q_button"
                      }
                      style={{
                        visibility: email === "" ? "hidden" : "visible",
                      }}
                      disabled={email === ""}
                      onClick={async () => {
                        setLoading(true);
                        const response = await axios.post(
                          props.backendurl + "/checkmailcodecadeau",
                          {
                            email: email,
                            codeCadeau: codeCadeau,
                          }
                        );
                        console.log(
                          "response.data.message : ",
                          response.data.message
                        );

                        if (response.data.message === "valide") {
                          setIsValidGift(true);
                          setLoading(false);
                          setCustomerAirTableID(response.data.records[0].id);
                          setPrenomNom(
                            response.data.records[0].fields["Prénom Nom"]
                          );
                          setBudgetTotal(
                            response.data.records[0].fields["Budget TOTAL"]
                          );
                          setEmail(response.data.records[0].fields["email"]);
                          setNbBouteilles(40);
                          setCodeCadeau(
                            response.data.records[0].fields["Code cadeau"]
                          );
                        } else {
                          setIsValidGift(false);
                          setLoading(false);
                        }
                      }}
                    >
                      Valider
                    </motion.button>
                  )}
                </div>
              </AnimatePresence>
            </div>
          </div>
          {/* ______________________ SLIDE 0 LOGIN */}
          {/* ______________________ SLIDE 1 NBBOUTEILLES */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 1
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 1
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Salut {prenomNom.substr(0, prenomNom.indexOf(" ")) + " "} !
                  <br />
                  Tu disposes de {budgetTotal}€ pour composer ta cave.
                  <span role="img" aria-label="coucou">
                    👋
                  </span>
                  <br />À combien de bouteilles estimes-tu la taille de ta cave
                  ?
                </h5>
              </div>
              <div>
                <div
                  className="nbBouteilleRappel"
                  onMouseDown={() => setLockBudget("nbBouteilles")}
                  onTouchStart={() => setLockBudget("nbBouteilles")}
                >
                  <div
                    className={
                      currentSlide === 1
                        ? "budgetsTitre animate__animated animate__fadeInDown animate__delay-3s"
                        : "budgetsTitre"
                    }
                  >
                    Nombre de bouteilles
                  </div>
                  <div
                    className={
                      props.currentSlide === 1
                        ? "animate__animated animate__fadeInDown animate__delay-1s"
                        : ""
                    }
                  >
                    <SliderPack
                      value={nbBouteilles}
                      setValue={(e) => setNbBouteilles(e)}
                      min={24}
                      max={150}
                      eur=""
                      steps={6}
                    />
                  </div>
                </div>
              </div>
              {/* BOTTLES */}
              <div>
                <img
                  className={
                    nbBouteilles > 24
                      ? "q_bottle_img giftbottle bottle_1"
                      : "q_bottle_img giftbottle bottle_1 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 36
                      ? "q_bottle_img giftbottle bottle_2"
                      : "q_bottle_img giftbottle bottle_2 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 48
                      ? "q_bottle_img giftbottle bottle_3"
                      : "q_bottle_img giftbottle bottle_3 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 60
                      ? "q_bottle_img giftbottle bottle_4"
                      : "q_bottle_img giftbottle bottle_4 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 72
                      ? "q_bottle_img giftbottle bottle_5"
                      : "q_bottle_img giftbottle bottle_5 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 90
                      ? "q_bottle_img giftbottle bottle_6"
                      : "q_bottle_img giftbottle bottle_6 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 120
                      ? "q_bottle_img giftbottle bottle_7"
                      : "q_bottle_img giftbottle bottle_7 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles >= 150
                      ? "q_bottle_img giftbottle bottle_8"
                      : "q_bottle_img giftbottle bottle_8 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
              </div>
              <br />
              <button
                className={
                  currentSlide === 2
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                  }
                }}
              >
                SUIVANT
              </button>
            </div>
          </div>
          {/* ______________________ SLIDE 1 NBBOUTEILLES */}
          {/* ______________________ SLIDE 2  NATURE ET BIO*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 2
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 2
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Ça marche, c'est noté !
                  <br />
                  On aimerait connaître ta position sur le vin nature :
                </h5>
              </div>
              <br />
              <div
                className={
                  currentSlide === 2
                    ? "bioButtons animate__animated animate__fadeInDown animate__delay-3s"
                    : "bioButtons"
                }
              >
                <button
                  className={
                    nature === "Oui"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Oui");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                    }
                  }}
                >
                  J'adore !
                </button>
                <button
                  className={
                    nature === "Curieux"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Curieux");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                    }
                  }}
                >
                  Je veux bien goûter
                </button>
                <button
                  className={
                    nature === "Non"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Non");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                    }
                  }}
                >
                  Très peu pour moi
                </button>
                <button
                  className="q_button infos choice"
                  onClick={() => {
                    setInfoNaturePopup(true);
                  }}
                >
                  C'est quoi ?
                </button>
              </div>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 2  NATURE ET BIO*/}
          {/* ______________________ SLIDE 3 PROPORTION TYPES*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 3
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 3
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  C'est l'heure de nous donner un aperçu de la répartition que
                  tu aimerais avoir dans ta cave idéale !
                </h5>
              </div>
              <div
                className={
                  currentSlide === 3
                    ? "propsetter animate__animated animate__fadeIn animate__delay-4s"
                    : "propsetter"
                }
              >
                <div className="propsetterChartjs">
                  <BrowserView>
                    <Doughnut
                      data={chartdata}
                      width={100}
                      height={50}
                      options={chartoptions}
                      // plugins={ChartDataLabels}
                    />
                  </BrowserView>
                  <MobileView>
                    <Doughnut
                      data={chartdata}
                      width={100}
                      height={50}
                      options={chartoptionsMobile}
                      // plugins={ChartDataLabels}
                    />
                  </MobileView>
                </div>
                <div className="propsetterControler">
                  {/* __________________________________________ PROPSETTER ROUGE */}

                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propRouge <= 0}
                        onClick={() => {
                          setPropRouge(propRouge - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille rouge"></div>
                        <div className="cardTitre">Vins rouges</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropRouge(propRouge + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propRouge}</span> */}
                  </div>

                  {/* __________________________________________ PROPSETTER Blanc */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propBlanc <= 0}
                        onClick={() => {
                          setPropBlanc(propBlanc - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille blanc"></div>
                        <div className="cardTitre">Vins blancs</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropBlanc(propBlanc + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propBlanc}</span> */}
                  </div>
                  {/* __________________________________________ PROPSETTER ROsé */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propRosé <= 0}
                        onClick={() => {
                          setPropRosé(propRosé - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille rosé"></div>
                        <div className="cardTitre">Vins rosés</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropRosé(propRosé + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propRosé}</span> */}
                  </div>
                  {/* __________________________________________ PROPSETTER Champagne */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propChampagne <= 0}
                        onClick={() => {
                          setPropChampagne(propChampagne - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille champagne"></div>
                        <div className="cardTitre">Vins effervescents</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropChampagne(propChampagne + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propChampagne}</span> */}
                  </div>
                </div>
              </div>
              <button
                className={
                  currentSlide === 4
                    ? "q_button animate__animated animate__fadeInDown animate__delay-3s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                  }
                }}
              >
                Suivant
              </button>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 3 PROPORTION TYPES*/}
          {/* ______________________ SLIDE 4 PROPORTION GAMMES*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 4
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 4
                      ? "q_question budget animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question budget"
                  }
                >
                  C'est l'heure de définir la répartition des gammes dans ta
                  cave à vin
                </h5>
              </div>
              <div
                className={
                  currentSlide === 4
                    ? "propsetter animate__animated animate__fadeIn animate__delay-4s"
                    : "propsetter"
                }
              >
                <div className="propsetterChartjs">
                  <BrowserView>
                    <Doughnut
                      data={chart2data}
                      width={100}
                      height={50}
                      options={chartoptions}
                      // plugins={ChartDataLabels}
                    />
                  </BrowserView>
                  <MobileView>
                    <Doughnut
                      data={chart2data}
                      width={100}
                      height={50}
                      options={chartoptionsMobile}
                      // plugins={ChartDataLabels}
                    />
                  </MobileView>
                </div>
                <div className="propsetterControler">
                  {/* __________________________________________ PROPSETTER EDG */}
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      allCardsGammeOff();
                    }}
                  >
                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("propGammes")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propMDG === 0 && propHDG === 0) || propEDG <= 0
                          }
                          onClick={() => {
                            if (propEDG < 5) {
                              setPropEDG(0);
                            } else {
                              setPropEDG(propEDG - 5);
                            }
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardEDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(false);
                          setCardMDGOpened(false);
                          setCardEDGOpened(!cardEDGOpened);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille EDG"></div>
                          <div className="cardTitre">Entrée de gamme ~ 8€</div>
                          <div
                            className={
                              cardEDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 8€ la bouteille</i>
                            <br />
                            Un copain qui débarque à l'improviste ? Il y a
                            toujours un coup de blanc au frais ou un rouge
                            gourmand pour l’accueillir.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropEDG(propEDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propRosé}</span> */}
                    </div>
                    {/* __________________________________________ PROPSETTER MDG */}
                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("propGammes")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propEDG === 0 && propHDG === 0) || propMDG <= 0
                          }
                          onClick={() => {
                            if (propMDG < 5) {
                              setPropMDG(0);
                            } else {
                              setPropMDG(propMDG - 5);
                            }
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardMDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(false);
                          setCardMDGOpened(!cardMDGOpened);
                          setCardEDGOpened(false);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille MDG"></div>
                          <div className="cardTitre">Milieu de gamme ~ 18€</div>
                          <div
                            className={
                              cardMDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 18€ la bouteille</i>
                            <br />
                            12h30, je viens de finir de cuisiner un risotto aux
                            cèpes du marché. J’ai quand même bien envie de me
                            récompenser et de tirer mon plat vers le haut avec
                            une belle bouteille.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropMDG(propMDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propBlanc}</span> */}
                    </div>
                    {/* __________________________________________ PROPSETTER HDG */}

                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("propGammes")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propEDG === 0 && propMDG === 0) || propHDG <= 0
                          }
                          onClick={() => {
                            if (propHDG < 5) {
                              setPropHDG(0);
                            } else {
                              setPropHDG(propHDG - 5);
                            }
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardHDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(!cardHDGOpened);
                          setCardMDGOpened(false);
                          setCardEDGOpened(false);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille HDG"></div>
                          <div className="cardTitre">Haut de gamme ~ 30€</div>
                          <div
                            className={
                              cardHDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 30€ la bouteille</i>
                            <br />
                            Les beaux-parents viennent déjeuner dimanche pour
                            célébrer les 3 ans du petit Auguste. Il faut sortir
                            le grand jeu pour marquer cet événement de la vie.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropHDG(propHDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propRouge}</span> */}
                    </div>
                  </OutsideClickHandler>
                  {/* <div className="budgetsTitre">Nombre de bouteilles</div>
                        <button className="q_button nbbottles">
                          {nbBouteilles}
                        </button> */}
                  <div
                    className="nbBouteilleRappel"
                    onMouseDown={() => setLockBudget("nbBouteilles")}
                    onTouchStart={() => setLockBudget("nbBouteilles")}
                  >
                    <div className="budgetsTitre">Nombre de bouteilles</div>
                    <InputSlider
                      className="inputSlider"
                      styles={sliderStyles}
                      axis="x"
                      x={nbBouteilles}
                      xmin={16}
                      xmax={150}
                      onChange={({ x }) => {
                        setNbBouteilles(x);
                      }}
                    />
                    <span className="labelSlider rappel">{nbBouteilles}</span>
                  </div>
                  <div
                    className="nbBouteilleRappel"
                    // onMouseDown={() => setLockBudget("budgetTotal")}
                    // onTouchStart={() => setLockBudget("budgetTotal")}
                  >
                    <div className="budgetsTitre">Budget total</div>
                    <InputSlider
                      className="inputSlider"
                      styles={sliderStyles}
                      axis="x"
                      x={budgetTotal}
                      xmin={500}
                      xmax={4000}
                      // onChange={({ x }) => setBudgetTotal(x)}
                      disabled
                    />
                    <span className="labelSlider rappel">{budgetTotal} €</span>
                  </div>
                </div>
                <div className="propsetter-footer">
                  <button
                    className={
                      currentSlide === 4
                        ? "q_button animate__animated animate__fadeInDown animate__delay-3s"
                        : "q_button"
                    }
                    onClick={() => {
                      if (questionnaireTerminé) {
                        setConfirmPopup(true);
                      } else {
                        slider.slickNext();
                      }
                    }}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ______________________ SLIDE 4 PROPORTION GAMMES*/}
          {/* ______________________ SLIDE 5 GOUT VIN */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 5
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 5
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Ok, quelles sont tes préférences concernant le vin blanc et le
                  vin rouge ?
                </h5>
              </div>
              {(currentSlide === 5 ||
                blancTagsDisliked !== "" ||
                blancTagsLiked !== "" ||
                rougeTagsDisliked !== "" ||
                rougeTagsLiked !== "") && (
                <QuestionnaireTaste
                  data={props.data}
                  setBlancTagsDisliked={(e) => setBlancTagsDisliked(e)}
                  setBlancTagsNormal={(e) => setBlancTagsNormal(e)}
                  setBlancTagsLiked={(e) => setBlancTagsLiked(e)}
                  setRougeTagsDisliked={(e) => setRougeTagsDisliked(e)}
                  setRougeTagsNormal={(e) => setRougeTagsNormal(e)}
                  setRougeTagsLiked={(e) => setRougeTagsLiked(e)}
                  blancTagsDisliked={blancTagsDisliked}
                  blancTagsNormal={blancTagsNormal}
                  blancTagsLiked={blancTagsLiked}
                  rougeTagsDisliked={rougeTagsDisliked}
                  rougeTagsNormal={rougeTagsNormal}
                  rougeTagsLiked={rougeTagsLiked}
                />
              )}
              <div className="bioButtons" style={{ marginTop: "30px" }}>
                <button
                  className={
                    currentSlide === 5
                      ? "q_button choice animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button choice"
                  }
                  onClick={() => {
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  Valider
                </button>
              </div>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 5 GOUT VIN */}
          {/* ______________________ SLIDE 6 RÉGIONS*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 6
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 6
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Ok, on va rapidement définir les régions à favoriser dans ta
                  cave, et celles à exclure.
                </h5>
              </div>
              <div
                className={
                  currentSlide === 6
                    ? "regionPickersContainer animate__animated animate__fadeIn animate__delay-3s"
                    : "regionPickersContainer"
                }
              >
                <div className="regionsTitreContainer">
                  <div className="regionsTitreBloc">
                    <div className="regionsTitreBlocHead left">
                      <span className="regionsTitre line1">EXCLURE</span>
                    </div>
                    <div className="regionsTitreBody">
                      <span className="regionsTitre">Blanc</span>
                      <span className="regionsTitre">Rouge</span>
                    </div>
                  </div>
                  <div className="regionsTitreBloc">
                    <div className="regionsTitreBlocHead right">
                      <span className="regionsTitre line1">FAVORISER</span>
                    </div>
                    <div className="regionsTitreBody">
                      <span className="regionsTitre">Blanc</span>
                      <span className="regionsTitre">Rouge</span>
                    </div>
                  </div>
                </div>
                <div className="regionPickers">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      toggleCardsRegionsOff();
                    }}
                  >
                    <RegionsCheckboxes
                      regionsMoinsBlanc={regionsMoinsBlanc}
                      regionsPlusBlanc={regionsPlusBlanc}
                      regionsMoinsRouge={regionsMoinsRouge}
                      regionsPlusRouge={regionsPlusRouge}
                      data={props.data}
                      setRegionsMoinsBlanc={(e) => setRegionsMoinsBlanc(e)}
                      setRegionsPlusBlanc={(e) => setRegionsPlusBlanc(e)}
                      setRegionsMoinsRouge={(e) => setRegionsMoinsRouge(e)}
                      setRegionsPlusRouge={(e) => setRegionsPlusRouge(e)}
                      regionCardOpened={regionCardOpened}
                      setRegionCardOpened={(e) => setRegionCardOpened(e)}
                      nbRegions={nbRegions}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
              <button
                className={
                  currentSlide === 6
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                  }
                }}
              >
                {regionsPlusBlanc.length > 0 ||
                regionsMoinsBlanc.length > 0 ||
                regionsPlusRouge.length > 0 ||
                regionsMoinsRouge.length > 0
                  ? "Valider"
                  : "Carte blanche"}
              </button>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 6 RÉGIONS*/}
          {/* ______________________ SLIDE 7 TÉLÉPHONE */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 7
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <h5
                className={
                  currentSlide === 7
                    ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                    : "q_question"
                }
              >
                Merci pour toutes ces infos.
              </h5>
              <span
                className={
                  currentSlide === 7
                    ? "subquestion animate__animated animate__fadeInDown animate__delay-2s"
                    : "subquestion"
                }
              >
                Pour continuer on a besoin de tes coordonnées.
              </span>
              <input
                placeholder="Ton numéro"
                name="telephone"
                type="input"
                className={
                  currentSlide === 7
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-3s"
                    : "form-control-material"
                }
                onChange={(e) => setTelephone(e.target.value)}
              />
              <br />
              <motion.span
                initial={{ opacity: 0 }}
                animate={{
                  opacity:
                    telephone === "" || !validator.validate(email) ? 0 : 1,
                }}
                className="subquestion"
              >
                À la cave on porte beaucoup d’importance à chaque composition,
                et on pense que ça passe également par une petite discussion de
                vive voix, si tu en ressens le besoin, bien entendu.
                {/* <br />
                Il est également possible d’échanger par mail ou avec le chat
                directement sur le site, c’est Antoine qui est juste derrière. */}
                <br />
                Sinon tu peux aussi payer directement pour que ta cave soit
                expédiée plus vite.
              </motion.span>
              <br />
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity:
                    telephone === "" || !validator.validate(email) ? 0 : 1,
                }}
                className="contactButtonsContainer"
              >
                <button
                  className={
                    currentSlide === 7
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  disabled={telephone === "" || !validator.validate(email)}
                  onClick={() => {
                    setAContacter("tel");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Débrief par téléphone
                </button>
                <button
                  className={
                    currentSlide === 7
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  style={{
                    visibility: telephone === "" ? "hidden" : "visible",
                  }}
                  disabled={telephone === ""}
                  onClick={() => {
                    setAContacter("mail");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Débrief par email
                </button>
                <button
                  className={
                    currentSlide === 7
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  style={{
                    visibility: telephone === "" ? "hidden" : "visible",
                  }}
                  disabled={telephone === ""}
                  onClick={() => {
                    setAContacter("non");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Pas de débrief
                </button>
              </motion.div>
            </div>
          </div>
          {/* ______________________ SLIDE 7 TÉLÉPHONE */}
          {/* ______________________ SLIDE 8 ADRESSE */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 8
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <h5
                className={
                  currentSlide === 8
                    ? "q_question animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_question"
                }
              >
                Et enfin, la livraison !<br />
                Où est-ce qu'on achemine tes précieuses bouteilles ?
                <br />
              </h5>
              {/* <input
                placeholder="Numéro et voie"
                name="adresseVoie"
                type="input"
                className={
                  currentSlide === 9
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-2s"
                    : "form-control-material"
                }
                onChange={(e) => setDataAdresse(e.target.value)}
              />
              <br /> */}
              <div
                className={
                  currentSlide === 8
                    ? "form-address-material animate__animated animate__fadeInDown animate__delay-2s"
                    : "form-address-material "
                }
                autoComplete={false}
              >
                <AlgoliaPlaces
                  style={{
                    padding: "13px",
                    height: "4%",
                    borderRadius: "10px",
                    fontFamily: "Helvetica",
                    color: "black",
                  }}
                  placeholder="Ton adresse de livraison"
                  options={{
                    appId: "plZ1WX40I63E",
                    apiKey: "7cefbbe25b1724a2c4ef55503e2de1cc",
                    language: "fr",
                    countries: ["fr"],
                    type: "address",
                    autoComplete: "off",
                  }}
                  onChange={({
                    query,
                    rawAnswer,
                    suggestion,
                    suggestionIndex,
                  }) => {
                    console.log("suggestion : ", suggestion);
                    setAdresseVoie(suggestion.name);
                    setAdresseCP(suggestion.postcode);
                    setAdresseVille(suggestion.city);
                    setAdresseValue(suggestion.value);
                  }}
                  onClear={() =>
                    // "Fired when the input is cleared.")
                    {
                      setAdresseVoie("");
                      setAdresseCP("");
                      setAdresseVille("");
                      console.log("adresse cleared");
                    }
                  }
                  onLimit={({ message }) => {
                    console.log(message);
                  }}
                />
              </div>
              <br />
              <input
                placeholder="Code, bâtiment, étage..."
                type="input"
                autoComplete="nope"
                className={
                  currentSlide === 8
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-5s"
                    : "form-control-material"
                }
                onChange={(e) => setAdresseNotes(e.target.value)}
              />
              <button
                type="submit"
                name="adresses"
                className={
                  currentSlide === 8
                    ? "q_button animate__animated animate__fadeInDown animate__delay-6s"
                    : "q_button"
                }
                style={{
                  visibility:
                    adresseVoie === "" ||
                    adresseCP === "" ||
                    adresseVille === ""
                      ? "hidden"
                      : "visible",
                }}
                disabled={
                  adresseVoie === "" || adresseCP === "" || adresseVille === ""
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                  }
                }}
              >
                Suivant
              </button>
            </div>
          </div>
          {/* ______________________ SLIDE 8 ADRESSE */}
          {/* ______________________ SLIDE 9 LIVRAISON */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 9
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div className="livraisonCreneauContainer">
                <QuestionnaireCreneau
                  currentSlide={currentSlide}
                  componentSlide={9}
                  livraisonMode={livraisonMode}
                  livraisonCreneauTime={livraisonCreneauTime}
                  livraisonCreneauDate={livraisonCreneauDate}
                  setLivraisonCreneauTime={(e) => setLivraisonCreneauTime(e)}
                  setLivraisonCreneauDate={(e) => setLivraisonCreneauDate(e)}
                />
              </div>
              <button
                type="submit"
                name="adresses"
                className={
                  currentSlide === 9
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                style={{
                  visibility:
                    adresseVoie === "" ||
                    adresseCP === "" ||
                    adresseVille === ""
                      ? "hidden"
                      : "visible",
                }}
                disabled={
                  adresseVoie === "" || adresseCP === "" || adresseVille === ""
                }
                onClick={() => {
                  setQuestionnaireTerminé(true);
                  setConfirmPopup(true);
                }}
              >
                ENVOYER
              </button>
            </div>
          </div>
          {/* ______________________ SLIDE 10 LIVRAISON */}
        </Slider>
      </div>
    </div>
  );
}
export default Questionnaire;
