import "../css/Paiement.sass";

// import { AnimatePresence, motion } from "framer-motion";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import CadeauCard from "../components/cadeau/CadeauCard.jsx";
import QuestionnaireCreneau from "../components/questionnaire/QuestionnaireCreneau";
import arrow from "../img/steps/arrow.svg";
import axios from "axios";
import checked from "../img/steps/1_checked.svg";
import paymentOK from "../img/steps/3_payment_OK.svg";
import paymentlogoblack from "../img/payment-logo-black.png";
import preloader from "../img/preloader.gif";
import shipping from "../img/steps/4_shipping.svg";
import unchecked from "../img/steps/1_unchecked.svg";

const CheckoutForm = (props) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [gift, setGift] = useState(false);
  const [creneauInvalid, setCreneauInvalid] = useState(false);
  const [almaPay, setAlmaPay] = useState(false);
  const [almaAuth, setAlmaAuth] = useState(false);

  const [livraisonCreneau, setLivraisonCreneau] = useState(undefined);

  const [livraisonCreneauTime, setLivraisonCreneauTime] = useState(7);
  const [livraisonCreneauDate, setLivraisonCreneauDate] = useState();

  const [dataCustomer, setDataCustomer] = useState(0);

  const logo = props.logo;
  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}

  //customerId = ID en param d'URL
  const search = props.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const customerId = params.get("id"); // bar
  const almaPID = params.get("pid"); // bar

  // console.log("almaPID : ", almaPID);
  // console.log("customerId : ", customerId);
  // console.log("dataCustomer : ", dataCustomer);

  //Check Alma Auth
  useEffect(() => {
    axios
      .post(props.backendurl + "/almaAuth", {
        customerId: customerId,
      })
      .then(function (response) {
        console.log("response : ", response.data);
        if (response.data) {
          setAlmaAuth(true);
        }
      });
  }, [customerId, props.backendurl]);

  //SI PARAM PID EN URL, CHECK SI PAIEMENT ALMA OK
  useEffect(() => {
    if (almaPID) {
      console.log("pid ok");
      axios
        .post(props.backendurl + "/almaCheck", {
          customerId: customerId,
          almaPID: almaPID,
        })
        .then(function (response) {
          console.log("response : ", response.data);
          if (response.data.status === "ok") {
            console.log("paiement alma ok");
            history.push(response.data.url);
            window.location.reload();
          }
        });
    }
  }, [almaPID, customerId, props.backendurl, history]);

  //DÉLCENCHEMENT DU PAIEMENT ALMA
  const almaCreatePayment = () => {
    var win = window.open();
    axios
      .post(props.backendurl + "/almaPay", {
        customerId: customerId,
      })
      .then(function (response) {
        if (response.data.status === 200) {
          win.location = response.data.url;
          win.focus();
        }
      });
  };

  useEffect(() => {
    if (livraisonCreneauDate !== undefined) {
      var newLivraisonCreneau = new Date(livraisonCreneauDate);
      newLivraisonCreneau.setHours(livraisonCreneauTime, 0, 0);
      console.log("newLivraisonCreneau : ", newLivraisonCreneau);
      setLivraisonCreneau(newLivraisonCreneau);
    }
  }, [livraisonCreneauDate, livraisonCreneauTime]);

  //On récupère toutes les données du client_id en URL param
  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(
        props.backendurl + "/getcustomerinfos",
        {
          customerId: customerId,
        }
      );
      if (response.data === "notfound") {
        setNotFound(true);
        if (!almaPID) {
          setLoading(false);
        }
      } else {
        setDataCustomer(response.data.fields);
        if (!almaPID) {
          setLoading(false);
        }
        if (
          response.data.fields.Statut === "Cadeau payé" ||
          response.data.fields.Statut === "Paiement reçu" ||
          response.data.fields.Statut === "Expédié" ||
          response.data.fields.Statut === "Livré"
        ) {
          setCompleted(true);
        }
        if (response.data.fields.Statut.includes("Cadeau")) {
          setGift(true);
        }
        if (response.data.creneauInvalid) {
          setCreneauInvalid(true);
        }
      }
    }
    fetchData();
  }, [customerId, props.backendurl, almaPID]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPaying(true);
    const cardElement = elements.getElement(CardElement);
    // const stripeResponse = await stripe.createToken(cardElement, {
    //   name: customerId,
    // });
    // const stripeToken = stripeResponse.token.id;

    axios
      .post(props.backendurl + "/stripeSecret", { customerId })
      .then(function (response) {
        console.log("response : ", response);
        return response.data.client_secret;
      })
      .then(async (client_secret) => {
        console.log("client_secret : ", client_secret);
        // Call stripe.confirmCardPayment() with the client secret.

        const responsePay = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: customerId,
            },
          },
        });

        console.log("responsePay : ", responsePay);
        //Paiement bien effectué côté back
        if (responsePay.paymentIntent?.status === "succeeded") {
          if (gift) {
            var responseProcess = await axios.post(
              props.backendurl + "/paygift",
              {
                client_secret,
                customerId,
              }
            );
          } else {
            responseProcess = await axios.post(props.backendurl + "/pay", {
              client_secret,
              customerId,
            });
          }
          console.log("responseProcess : ", responseProcess);
          //Fiche client AirTable mis à jour côté Backend
          setIsPaying(false);
          setCompleted(true);
        } else {
          setIsError(responsePay.error.message);
        }
      });
  };

  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  // window.addEventListener("resize", () => {
  //   // We execute the same script as before
  //   let vhmobileheight = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty(
  //     "--vhmobileheight",
  //     `${vhmobileheight}px`
  //   );
  // });

  return (
    <div className="paiementContainer">
      {loading ? (
        <div className="resumeContainer loadingData">
          <img src={preloader} alt="loading"></img>
        </div>
      ) : isError ? (
        <div className="resumeContainer paiementeffectue">
          <div className="paiementOk">Oups, erreur de paiement...</div>
          <div className="paiementHeaderBloc">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => window.location.reload()}
              className="headerBloclogo"
              src={logo}
              alt="lacave"
            />
          </div>
          <div className="paiementOk">{isError}</div>
        </div>
      ) : completed ? (
        creneauInvalid ? (
          <div className="resumeContainer creneauInvalid">
            <div className="paiementHeaderBloc">
              <Link to="/">
                <img src={logo} className="headerBloclogo" alt="lacave" />
              </Link>
            </div>
            <div className="paiementHeaderBloc">
              Paiement bien reçu !<br />
              Il faudrait juste définir un nouveau créneau de livraison
            </div>
            <div className="paiementCreneauBloc">
              <QuestionnaireCreneau
                currentSlide={0}
                componentSlide={0}
                livraisonMode={dataCustomer["Livraison : Mode"]}
                livraisonCreneauTime={livraisonCreneauTime}
                livraisonCreneauDate={livraisonCreneauDate}
                setLivraisonCreneauTime={(e) => setLivraisonCreneauTime(e)}
                setLivraisonCreneauDate={(e) => setLivraisonCreneauDate(e)}
              />
            </div>
            <button
              className="q_button"
              onClick={async () => {
                const response = await axios.post(
                  props.backendurl + "/updatecreneau",
                  {
                    customerId: customerId,
                    livraisonCreneau: livraisonCreneau,
                  }
                );
                if (response.data.message === "update creneau OK") {
                  setCreneauInvalid(false);
                } else {
                  console.log("Erreur update créneau bdd");
                  setIsError(true);
                }
              }}
            >
              Valider
            </button>
          </div>
        ) : gift ? (
          <div className="resumeContainer paiementeffectue">
            <div
              className="paiementHeaderBloc"
              style={{ marginTop: "10px", height: "fit-content" }}
            >
              <div>Paiement confirmé.</div>
              <br />
              <div>
                La carte cadeau t'a été envoyée par email.
                <br />
                Tu n'as plus qu'à l'envoyer à {dataCustomer["Prénom Nom"]} pour
                qu'il ou elle puisse composer sa cave sur mesure !
              </div>
            </div>
            <div className="sentPopupStepsContainer">
              <div className="stepContainer">
                <img className="stepImage" src={paymentOK} alt="pay" />
                <span className="stepTexte">Paiement</span>
              </div>
              <div className="stepArrowContainer">
                <img className="stepImage" src={arrow} alt="arrow" />
                <div className="stepTexte"></div>
              </div>
              <div className="stepContainer">
                <img className="stepImage" src={unchecked} alt="unchecked" />
                <span className="stepTexte">Questionnaire</span>
              </div>
              <div className="stepArrowContainer">
                <img className="stepImage" src={arrow} alt="arrow" />
                <div className="stepTexte"></div>
              </div>
              <div className="stepContainer">
                <img className="stepImage" src={shipping} alt="colis" />
                <span className="stepTexte">Livraison</span>
              </div>
            </div>
            <div className="cadeauCardBloc">
              <CadeauCard
                prenomNom={dataCustomer["Prénom Nom"]}
                price={dataCustomer["Budget TOTAL"]}
                email={dataCustomer["email"]}
                giftCode={dataCustomer["Code cadeau"]}
                message={dataCustomer["Cadeau Mot"]}
              />
            </div>
          </div>
        ) : (
          <div className="resumeContainer paiementeffectue">
            <div className="paiementHeaderBloc">
              <Link to="/">
                <img src={logo} className="headerBloclogo" alt="lacave" />
              </Link>
              <div className="paiementOk">Paiement effectué ! </div>
            </div>
            <div className="sentPopupStepsContainer">
              <div className="stepContainer">
                <img className="stepImage" src={checked} alt="checked" />
                <span className="stepTexte">Questionnaire</span>
              </div>
              <div className="stepArrowContainer">
                <img className="stepImage" src={arrow} alt="arrow" />
                <div className="stepTexte"></div>
              </div>
              <div className="stepContainer">
                <img className="stepImage" src={paymentOK} alt="pay" />
                <span className="stepTexte">Paiement</span>
              </div>
              <div className="stepArrowContainer">
                <img className="stepImage" src={arrow} alt="arrow" />
                <div className="stepTexte"></div>
              </div>
              <div className="stepContainer">
                <img className="stepImage" src={shipping} alt="colis" />
                <span className="stepTexte">Livraison</span>
              </div>
            </div>
          </div>
        )
      ) : notFound ? (
        <div className="resumeContainer paiementeffectue">
          <div className="paiementOk">Oups, mauvais lien de paiement...</div>
          <div className="paiementHeaderBloc">
            <Link to="/">
              <img src={logo} className="headerBloclogo" alt="lacave" />
            </Link>
          </div>
          <div className="paiementOk">
            Réponds au questionnaire pour pouvoir t'offrir la cave à vin de tes
            rêves !
          </div>
          <br />
          <br />
          <br />
          <div className="paiementHeaderBloc">
            <Link to={"/composer"}>
              <button className="q_button">COMPOSER MA CAVE</button>
            </Link>
          </div>
        </div>
      ) : (
        //BLOC DE PAIEMENT CLASSIQUE
        <form className="resumeContainer" onSubmit={handleSubmit}>
          <div className="paiementHeaderBloc">
            <img src={logo} className="headerBloclogo" alt="lacave" />
            <div className="headerBlocText">
              <div className="blocTextNom">
                La Cave {gift && <span>cadeau </span>}
                de {dataCustomer["Prénom Nom"]}
              </div>
              <div className="blocTextPrix">
                {dataCustomer["Budget TOTAL"]}€ +{" "}
                {dataCustomer["Livraison : Prix"]}€
              </div>
              {/* SWITCH STRIPE / ALMA */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
              >
                <div
                  style={{ width: "40%" }}
                  className={almaPay ? "q_button" : "q_button chosen"}
                  onClick={() => setAlmaPay(false)}
                >
                  En une fois
                </div>
                <div
                  style={{ width: "40%" }}
                  className={almaPay ? "q_button chosen" : "q_button"}
                  onClick={() => setAlmaPay(true)}
                >
                  3x sans frais
                </div>
              </div>
              {/* SWITCH STRIPE / ALMA */}
            </div>
          </div>
          {/* PAIEMENT STRIPE */}
          <div className="paiementFormBloc">
            {!almaPay && <CardElement options={style} />}
            {!almaPay && isPaying && (
              <div style={{ width: "100%", height: "58px" }}>
                <img
                  style={{ width: "58px", height: "58px" }}
                  src={preloader}
                  alt="loading"
                ></img>
              </div>
            )}
            {!almaPay && !isPaying && (
              <button
                className="q_button"
                type="submit"
                style={{ maxWidth: "60%" }}
                disabled={isPaying}
              >
                Payer
              </button>
            )}
            {/* PAIEMENT STRIPE */}
            {/* PAIEMENT ALMA */}
            {almaPay && !isPaying && !almaAuth && (
              <div className="almaBlock">
                Indisponible pour cette commande !
              </div>
            )}
            {almaPay && !isPaying && almaAuth && (
              <div
                className="q_button"
                onClick={() => {
                  setIsPaying(true);
                  almaCreatePayment();
                }}
              >
                Payer en 3x
              </div>
            )}
            {almaPay && isPaying && (
              <div style={{ width: "100%", height: "58px" }}>
                <img
                  style={{ width: "58px", height: "58px" }}
                  src={preloader}
                  alt="loading"
                ></img>
              </div>
            )}
            {/* PAIEMENT ALMA */}
          </div>

          <img
            className="paymentImg"
            src={paymentlogoblack}
            alt="paiement sécurisé"
          />
        </form>
      )}
    </div>
  );
};
export default CheckoutForm;
