import Accueil2 from "../pages/Accueil2.js";
// import Accueil from "../pages/Accueil.js";
// import AccueilMobile from "../pages/AccueilMobile.js";
import React from "react";
import { useContentful } from "react-contentful";

function AccueilQueries(props) {
  const { data } = useContentful({
    contentType: "PAGE ACCUEIL",
    query: {
      // "fields.slug[in]": `/${props.match.slug || ""}`,
      content_type: "pageAccueil",
      "sys.id": "HVjHPQveafOIa7T3bUsuf",
    },
  });
  if (!data) {
    return null;
  }
  // console.log("QUERIES ACCUEIL : ");
  // console.log(data);

  // const logo = data.items[0].fields.image.fields.file.url;

  //__________________________RETURN
  return (
    <Accueil2 logo={props.logo} backendurl={props.backendurl} data={data} />
  );
}

export default AccueilQueries;
