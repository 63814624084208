import "../../css/CardCadeau.css";

import React from "react";
import logo from "../../img/logo.svg";

function CadeauCard(props) {
  return (
    <div className="container">
      <div className="content">
        <div className="main">
          <div className="wrapper">
            <div className="leftPannel">
              <div className="giftLogoContainer">
                <img className="giftLogo" src={logo} alt="logo" />
              </div>
              <div className="leftPannelFooter">
                <div className="leftPannelFooterLine1">À composer sur</div>
                <div className="leftPannelFooterLine2">
                  lacavedesvendangesdantoine.com
                </div>
              </div>
            </div>
            <div className="rightPannel">
              <h2 className="rightPannelTitle">
                La Cave cadeau de {props.prenomNom}
              </h2>
              {props.message && (
                <i className="rightPannelMessage">« {props.message} »</i>
              )}

              <div className="giftInput">{props.email}</div>
              <div className="giftInput giftCode">{props.giftCode}</div>
            </div>
            <div className="cardFooter">{props.price}€</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadeauCard;
