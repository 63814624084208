import "../css/ButtonLivraisonMode.sass";

import { FaRunning, FaSkating, FaWalking } from "react-icons/fa";

import React from "react";

function ButtonLivraisonMode(props) {
  return (
    <div
      type="button"
      name="livraisonMode"
      className={
        props.currentSlide === 9
          ? props.livraisonMode === props.value
            ? "livraison_button_container chosen animate__animated animate__fadeInDown animate__delay-4s"
            : props.price === "INDISPO"
            ? "livraison_button_container disabled animate__animated animate__fadeInDown animate__delay-4s"
            : "livraison_button_container disabled animate__animated animate__fadeInDown animate__delay-4s"
          : "livraison_button_container"
      }
      onClick={() => {
        props.setLivraisonMode(props.value);
      }}
    >
      <div className="left-part">
        {(() => {
          switch (props.value) {
            case "zoneA":
              return (
                <div className="icon">
                  <FaWalking />
                </div>
              );
            case "zoneB":
              return (
                <div className="icon">
                  <FaRunning />
                </div>
              );
            case "zoneC":
              return (
                <div className="icon">
                  <FaSkating />
                </div>
              );
            default:
              break;
          }
        })()}{" "}
        {props.value}
      </div>
      <div className="right-part">
        <div className="right-up-part">
          {props.oldPrice && (
            <del className="right-up-part-oldPrice">{props.oldPrice}</del>
          )}{" "}
          {(() => {
            if (props.price === 0) {
              return "OFFERT";
            } else {
              if (props.price === "INDISPO") {
                return props.price;
              } else {
                return props.price + "€";
              }
            }
          })()}
        </div>
        <div className="right-down-part">{props.territoire}</div>
      </div>
    </div>
  );
}

export default ButtonLivraisonMode;
