import "../css/Accueil2SectionConcept.sass";

import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";

function Accueil2SectionHome(props) {
  const [slideMoved, setSlideMoved] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 6,
  };

  var sliderConcept = useRef();

  useEffect(() => {
    // if (scrollY < 500 && process.env.NODE_ENV === "production") {
    //   console.log("HIDE");
    //   window.FB.CustomerChat.hide();
    //   window.FB.CustomerChat.hideDialog();
    // } else if (scrollY > 500 && process.env.NODE_ENV === "production") {
    //   console.log("SHOW");
    //   window.FB.CustomerChat.show();
    //   window.FB.CustomerChat.hideDialog();
    // }

    if (props.scrollY > 500 && !slideMoved) {
      sliderConcept.slickNext();
      setSlideMoved(true);
    }
  }, [props.scrollY, slideMoved]);

  const slideshowAccueil = props.data.items[0].fields.slideshow.fields.slide.map(
    (slide, index) => {
      return (
        // <div className="test">coucou</div>
        <div key={index} className="accueilSection2slide">
          <div className="imgcontainer">
            <img
              className="accueilSection2Img1"
              src={slide.fields.image.fields.file.url}
              alt=""
            ></img>
          </div>
          <h2 className="accueilSection2Nb">{slide.fields.blocTitre}</h2>
          <div className="accueilSection2Txt">
            <h3>{slide.fields.blocTexte}</h3>
          </div>
        </div>
      );
    }
  );
  return (
    <>
      <div className="sectionTitre">Comment ça marche ?</div>
      <Slider ref={(c) => (sliderConcept = c)} id="sliderConcept" {...settings}>
        {slideshowAccueil}
      </Slider>
    </>
  );
}

export default Accueil2SectionHome;
