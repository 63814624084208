import "../css/Navbar.sass";

import { FaBars, FaFacebookF, FaInstagram } from "react-icons/fa";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import onClickOutside from "react-onclickoutside";

// import Scrollchor from "react-scrollchor";

function Navbar(props) {
  const [menuOpen, setmenuOpen] = useState(false);

  const logo = props.logo;

  Navbar.handleClickOutside = () => setmenuOpen(false);

  // const handleCloseMenu = () => {
  //   setmenuOpen(false);
  // };

  const handleToggleMenu = () => {
    setmenuOpen(!menuOpen);
  };

  const boutons = () => {
    if (props.page === "questionnaire") {
      return (
        <div className="navbar-menu-buttons-container">
          <Link to="/" style={{ textDecoration: "none" }}>
            <button className="blog">
              <div className="blogbuttonLine2">ACCUEIL</div>
            </button>
          </Link>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <button className="blog">
              <div className="blogbuttonLine1">S.O.I.F</div>
              <div className="blogbuttonLine2">LE BLOG</div>
            </button>
          </Link>
          <Link to="/composer" style={{ textDecoration: "none" }}>
            <button className="commander">
              JE CRÉE
              <br /> MA CAVE
            </button>
          </Link>
        </div>
      );
    } else if (props.page === "blog") {
      return (
        <div className="navbar-menu-buttons-container">
          <Link to="/" style={{ textDecoration: "none" }}>
            <button className="blog">
              <div className="blogbuttonLine2">ACCUEIL</div>
            </button>
          </Link>
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <button className="blog">
              <div className="blogbuttonLine1">S.O.I.F</div>
              <div className="blogbuttonLine2">LE BLOG</div>
            </button>
          </Link>
          <Link to="/composer" style={{ textDecoration: "none" }}>
            <button className="commander">
              JE CRÉE
              <br /> MA CAVE
            </button>
          </Link>
        </div>
      );
    } else if (props.page === "accueil") {
      return (
        <div className="navbar-menu-buttons-container">
          <Link to="/blog" style={{ textDecoration: "none" }}>
            <button className="blog">
              <div className="blogbuttonLine1">S.O.I.F</div>
              <div className="blogbuttonLine2">LE BLOG</div>
            </button>
          </Link>
          <Link to="/composer" style={{ textDecoration: "none" }}>
            <button className="commander">
              <div className="startbuttonLine">
                JE CRÉE
                <br /> MA CAVE
              </div>
            </button>
          </Link>
        </div>
      );
    }
  };

  return (
    // <div className={props.currentPage ? "navbar-sticky" : "navbar-relative"}>
    <motion.div
      initial={{ marginTop: 0 }}
      animate={{
        marginTop: props.scrollToBottom ? -70 : 0,
      }}
      className="navbar-sticky"
    >
      <div
        className={menuOpen ? "navbar menuOpened" : "navbar"}
        style={
          props.page === "accueil"
            ? { maxHeight: "410px" }
            : { maxHeight: "300px" }
        }
      >
        {/* HOME LOGO ---------*/}
        <button
          onClick={handleToggleMenu}
          className={
            menuOpen
              ? "navbar-burger-menu-button menuOpened"
              : "navbar-burger-menu-button"
          }
        >
          <FaBars />
        </button>

        {(() => {
          if (props.page === "accueil") {
            return (
              <Link to="/" className="navbar-logo-container">
                <div onClick={() => props.slide0()}>
                  <img src={logo} className="navbar-logo" alt="lacave" />
                </div>
              </Link>
            );
          } else {
            return (
              <Link to="/" className="navbar-logo-container">
                <div>
                  <img src={logo} className="navbar-logo" alt="lacave" />
                </div>
              </Link>
            );
          }
        })()}

        {/* MENU ---------*/}
        <div className="navbar-menu-shortcutsButtons">
          {(() => {
            if (props.page === "accueil") {
              return (
                <div className="navbar-menu-accueilShortcuts">
                  <div
                    className="navbar-menu-container"
                    onClick={() => {
                      handleToggleMenu();
                      props.slide1();
                    }}
                  >
                    <h4 className="navbar-menu">Concept</h4>
                  </div>
                  <div
                    className="navbar-menu-container"
                    onClick={() => {
                      handleToggleMenu();
                      props.slide2();
                    }}
                  >
                    <h4 className="navbar-menu">Les gammes</h4>
                  </div>
                  <div
                    className="navbar-menu-container"
                    onClick={() => {
                      handleToggleMenu();
                      props.slide3();
                    }}
                  >
                    <h4 className="navbar-menu">Pourquoi La Cave ?</h4>
                  </div>
                  <div
                    className="navbar-menu-container"
                    onClick={() => {
                      handleToggleMenu();
                      props.slide4();
                    }}
                  >
                    <h4 className="navbar-menu">Avis</h4>
                  </div>
                </div>
              );
            }
          })()}

          {/* BLOG-&-START------------- */}

          {boutons()}
        </div>

        {/* SOCIAL ICONS ---------*/}
        <div className="navbar-social-icons-container">
          <a
            href="https://www.facebook.com/lesVendangesdAntoine/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaFacebookF />
            </div>
          </a>
          <a
            href="https://www.instagram.com/lesvendangesdantoine/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="navbar-social-icon">
              <FaInstagram />
            </div>
          </a>
        </div>
      </div>
      {props.texteNavbar && (
        <div
          className="livraisonTexteContainer"
          onClick={() => props.toggleStateNewsletter()}
        >
          <h5 className="livraisonTexte">{props.texteNavbar}</h5>
        </div>
      )}
    </motion.div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Navbar.handleClickOutside,
};
export default onClickOutside(Navbar, clickOutsideConfig);
