import "../css/Faq.sass";

import Footer from "../components/Footer.js";
// import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import React from "react";
import ReactMarkdown from "react-markdown/with-html";

// import { FaAngleRight } from "react-icons/fa";

function Cgv(props) {
  console.log("CGV QUERY DATA : ", props.data);
  return (
    <div className="faqContainer">
      <Navbar logo={props.logo} page="blog" />
      <div className="titreContainer">
        <div className="titreFaq">Contact</div>
        {/* <div className="soustitreFaq">& Conditions générales de vente</div> */}
        {/* <div className="soustitreBlog">LE BLOG</div> */}
      </div>
      <div
        className="ParallaxContainer"
        style={{
          backgroundImage:
            "url(" + props.data.banniereFaq.fields.file.url + ")",
        }}
      ></div>
      <div className="faqContent">
        <div className="faqCGV">
          <div className="cgvBloc">
            <div className="faqTitle">Contact</div>
            <ReactMarkdown
              source={props.data.contact.fields.blocTexte}
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cgv;
