import "../css/Accueil2sectionPourquoi.sass";

import FlippingCard from "./FlippingCard.js";
import React from "react";

function Accueil2SectionPourquoi(props) {
  const dataPourquoi = props.data.items[0].fields.pourquoi;

  const pourquoiCards = dataPourquoi.map((pourquoi, index) => {
    return (
      <FlippingCard
        key={index}
        titre={pourquoi.fields.titrecontenu}
        texte={pourquoi.fields.blocTitre}
        detail={pourquoi.fields.blocTexte}
        image={pourquoi.fields.image.fields.file.url}
      ></FlippingCard>
    );
  });

  return (
    // <div className="accueilSection4slide">
    <>
      <div className="sectionTitre">POURQUOI LA CAVE ?</div>
      <div className="section4cards">{pourquoiCards}</div>
    </>
    //</div>
  );
}

export default Accueil2SectionPourquoi;
