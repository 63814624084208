import "../css/SliderPack.sass";

import InputSlider from "react-input-slider";
import React from "react";

const sliderStyles = {
  track: {
    display: "block",
    height: "50px",
    zIndex: "0",
    width: "40%",
    backgroundColor: "#ffdfdf",
    boxShadow: "2px 2px 5px 2px rgba(42, 42, 42, 0.17)",
    cursor: "pointer",
    transition: "all 0.3s",
  },
  active: {
    backgroundColor: "#000000",
  },
  thumb: {
    width: 0,
    height: 0,
  },
  disabled: {
    opacity: 0.5,
  },
};

function SliderPack(props) {
  return (
    <div className="sliderContainer">
      <div className="sliderAndButton">
        <div
          className="slider_button"
          onClick={() => {
            if (props.value > 7) {
              props.setValue(props.value - 1);
            }
          }}
        >
          -
        </div>
        <InputSlider
          className={
            props.currentSlide === props.slide
              ? "slider_slide animate__animated animate__fadeInDown animate__delay-1s"
              : "slider_slide"
          }
          styles={sliderStyles}
          axis="x"
          x={props.value}
          xmin={props.min}
          xmax={props.max}
          onChange={({ x }) => props.setValue(x)}
        />
        <div
          className="slider_button"
          onClick={() => {
            if (props.value < 18) {
              props.setValue(props.value + 1);
            }
          }}
        >
          +
        </div>
      </div>
      <span
        className={
          props.currentSlide === props.slide
            ? "slider_label creneau animate__animated animate__fadeInDown animate__delay-1s"
            : "slider_label creneau"
        }
      >
        entre {props.value}
        {props.unit} et {props.value + 3}
        {props.unit}
      </span>
    </div>
  );
}

export default SliderPack;
