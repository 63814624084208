import "../css/BlogArticle.sass";

import React, { useState } from "react";

import { FaAngleRight } from "react-icons/fa";
import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import PopupNewsletter from "../components/questionnaire/PopupNewsletter.js";
import ReactMarkdown from "react-markdown/with-html";
import commentBox from "commentbox.io";
import moment from "moment";
import { useContentful } from "react-contentful";

function BlogArticle(props) {
  const [popupNewsletter, setPopupNewsletter] = useState(false);

  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}

  commentBox("5676276006256640-proj");

  var blogArticleSlug = props.match.params.article_slug;

  const { data } = useContentful({
    contentType: "Blog > Blog post",
    query: {
      // "fields.slug[in]": `/${props.match.slug || ""}`,
      content_type: "blogPost",
      "fields.slug": blogArticleSlug,
    },
  });
  if (!data) {
    return null;
  }

  console.log(data.items[0].fields.articlesRecommands.fields.blogPosts);
  const listPublishedBlogArticles = data.items[0].fields.articlesRecommands.fields.blogPosts.filter(
    (post) => post.fields !== undefined
  );

  var lastArticles = listPublishedBlogArticles.slice(0, 5).map((article) => {
    return (
      <Link
        to={"/blog/" + article.fields.slug}
        style={{ textDecoration: "none" }}
      >
        <div className="recommendedArticle">
          <div className="titre" key={article.id}>
            <FaAngleRight />{" "}
            <div className="spantitre">{article.fields.title}</div>
          </div>
        </div>
      </Link>
    );
  });

  console.log("BLOG PAGE QUERY DATA : ", data.items[0].fields);

  var date = moment(data.items[0].fields.publishDate);

  return (
    <div className="blogArticleContainer">
      <Navbar logo={props.logo} page="blog" />
      <div className="titreContainer">
        <h2 className="titreBlog">{data.items[0].fields.title}</h2>
        {/* <div className="soustitreBlog">LE BLOG</div> */}
      </div>
      <PopupNewsletter
        visible={popupNewsletter}
        toggleStateNewsletter={() => {
          setPopupNewsletter(!popupNewsletter);
        }}
      />
      <div
        className="ParallaxContainer"
        style={{
          backgroundImage:
            "url(" + data.items[0].fields.heroImage.fields.file.url + ")",
        }}
      ></div>
      <div className="blogContent">
        <div className="blogArticles">
          <div className="date">
            {" Le " +
              date.format("DD/MM/YY") +
              " par " +
              data.items[0].fields.author.fields.name}
          </div>
          <div className="content">
            <ReactMarkdown
              source={data.items[0].fields.body}
              escapeHtml={false}
            />
          </div>
          <div className="commentbox" />
        </div>
        <div className="articlesRecentsContainer">
          <div className="titrebloc">Derniers articles</div>
          {lastArticles}
        </div>
      </div>
      <Footer
        toggleStateNewsletter={() => {
          setPopupNewsletter({ newsletterPopup: !popupNewsletter });
        }}
      />
    </div>
  );
}

export default BlogArticle;
