import "../css/Accueil2SectionAvis.sass";

import React, { useEffect, useState } from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Link } from "react-router-dom";

function Accueil2SectionPourquoi(props) {
  const { scrollYProgress } = useViewportScroll();
  const parallaxBgAvis = useTransform(scrollYProgress, [0, 1], [-600, 50]);

  //State tableau, une entrée par événement
  const [clientOpened, setClientOpened] = useState([]);

  //Tableau tampon, vide
  useEffect(() => {
    var stateArray = [];
    for (let i = 0; i < props.data.length; i++) {
      if (i === 0) {
        stateArray.push(true);
      } else {
        stateArray.push(false);
      }
    }
    setClientOpened(stateArray);
  }, [props.data.length]);

  const toggleClientOpened = (index) => {
    //vider le tableau tampon
    var stateArray = [];
    for (let i = 0; i < props.data.length; i++) {
      //Quand on arrive à la ligne de l'événement cliqué
      if (i === index) {
        //Toggle l'ouverture
        stateArray.push(true);
      } else {
        //Fermer les autres
        stateArray.push(false);
      }
    }
    console.log("stateArray : ", stateArray);
    setClientOpened(stateArray);
  };

  const headerPersons = props.data.map((client, index) => {
    return (
      <div
        key={index}
        className="headerPerson"
        onMouseEnter={() => {
          toggleClientOpened(index);
        }}
      >
        <img
          className={clientOpened[index] ? "pic" : "pic inactive"}
          src={client.fields.photo.fields.file.url}
          alt=""
        />
        <div className="nom">{client.fields.nom}</div>
        <div className="age">{client.fields.soustitre}</div>
      </div>
    );
  });

  const avisContent = props.data.map((client, index) => {
    return (
      <div
        key={index}
        className={
          clientOpened[index] ? "contentPerson" : "contentPerson cache"
        }
      >
        <div className="date">{client.fields.titreContenu}</div>
        <div className="texte">{client.fields.texteContenu}</div>
      </div>
    );
  });

  return (
    <>
      <motion.div
        style={{
          y: parallaxBgAvis,
        }}
        className="accueilSection5backgroundimage"
      ></motion.div>
      <div className="sectionTitre">ILS NOUS FONT CONFIANCE</div>
      <div className="accueilSection5Slide">
        <div className="avisContainer">
          <div className="avisHeader">
            <div className="avisHeaderTitre">PROFESSIONNELS</div>
            <div className="avisHeaderTitre">PARTICULIERS</div>
          </div>
          <div className="avisHeader">{headerPersons}</div>
          <div className="avisContent">{avisContent}</div>
          <div className="avisFooter">
            <Link
              to="/blog/exemple-de-cave"
              style={{ textDecoration: "none" }}
              className="a_button"
            >
              EXEMPLES DE CAVES
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accueil2SectionPourquoi;
