import "rodal/lib/rodal.css";
import "../../css/NewsletterPopup.sass";

import React from "react";
import Rodal from "rodal";

const newsletterPopupStyle = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "100%",
  overflow: "scroll",
  maxHeight: "80vh",
};

function NewsletterPopup(props) {
  return (
    <Rodal
      visible={props.visible}
      onClose={() => props.toggleStateNewsletter()}
      closeMaskOnClick={true}
      showCloseButton={true}
    >
      <iframe
        title="newsletterPopup"
        width={650}
        height={700}
        src="https://3c5c36eb.sibforms.com/serve/MUIEAC-I3eK32JbdZ-ag0-E1n1Z3YeMAw5v2FoZ6j9CRrwRuEypSLZT26J8TXrmUdBshvBv9lFuqj_cP756RdhkHFTRuypltvQd5NzeCi2VEoq5qmDAwpD0lKvvieJ4iZuBrYyZ-H1sugB4iAnpzWJ_4YaA9vFqOytilN6GRTSKbBMfdrqjVDtWrSqq-OaZFIP-ytGE5slKlo-1w"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        style={newsletterPopupStyle}
      ></iframe>
    </Rodal>
  );
}

export default NewsletterPopup;
