import Questionnaire from "../pages/Questionnaire.js";
import React from "react";
import { useContentful } from "react-contentful";

function QueriesQuestionnaire(props) {
  const { data } = useContentful({
    contentType: "QUESTIONNAIRE",
    query: {
      // "fields.slug[in]": `/${props.match.slug || ""}`,
      content_type: "questionnaire",
      "sys.id": "5GWHS9NcrYhSwgif1vAF3K",
    },
  });
  if (!data) {
    return null;
  }
  // console.log("QUERIES ACCUEIL : ");
  // console.log(data);

  // const logo = data.items[0].fields.image.fields.file.url;

  //__________________________RETURN
  return (
    <Questionnaire
      logo={props.logo}
      backendurl={props.backendurl}
      data={data}
    />
  );
}

export default QueriesQuestionnaire;
