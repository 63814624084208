import "../css/Accueil2.sass";

import React, { useEffect, useState } from "react";

import Accueil2SectionAvis from "../components/Accueil2SectionAvis";
import Accueil2SectionConcept from "../components/Accueil2SectionConcept";
import Accueil2SectionGammes from "../components/Accueil2SectionGammes";
import Accueil2SectionHome from "../components/Accueil2SectionHome";
import Accueil2SectionPourquoi from "../components/Accueil2SectionPourquoi";
import CookieConsent from "react-cookie-consent";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import PopupNewsletter from "../components/questionnaire/PopupNewsletter.js";
import Rodal from "rodal";
import logo from "../img/logo.svg";
import useScrollPosition from "@react-hook/window-scroll";

function Accueil2(props) {
  const [popupNewsletter, setPopupNewsletter] = useState(false);
  const [agePopupVisible, setAgePopupVisible] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const scrollY = useScrollPosition(60);

  useEffect(() => {
    let visited = localStorage["alreadyVisited"];
    if (visited) {
      //do not view Popup
      setAgePopupVisible(false);
    } else {
      //this is the first time
      localStorage["alreadyVisited"] = true;
      setAgePopupVisible(true);
    }
  }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollToBottom(scrollY > lastScrollY ? true : false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // console.log("scrollToBottom : ", props.data);

  return (
    <div className="accueilContainer">
      <Navbar
        logo={props.logo}
        page="accueil"
        slide0={() => window.scrollTo(0, 0)}
        slide1={() => window.scrollTo(0, (90 * window.innerHeight) / 100)}
        slide2={() => window.scrollTo(0, (180 * window.innerHeight) / 100)}
        slide3={() => window.scrollTo(0, (270 * window.innerHeight) / 100)}
        slide4={() => window.scrollTo(0, (360 * window.innerHeight) / 100)}
        texteNavbar={props.data.items[0].fields.texteNavbar}
        toggleStateNewsletter={() => setPopupNewsletter(!popupNewsletter)}
        scrollToBottom={scrollToBottom}
      />
      <CookieConsent buttonText="COMPRIS !" debug={false}>
        En poursuivant ta navigation, tu acceptes l'utilisation de cookies dans
        le but de proposer une expérience plus sûre et du contenu personnalisé.
        <br />
        Il est possible de modifier à tout moment ces paramètres depuis ton
        navigateur.
      </CookieConsent>
      <PopupNewsletter
        visible={popupNewsletter}
        toggleStateNewsletter={() => {
          setPopupNewsletter(!popupNewsletter);
        }}
      />
      <Rodal
        visible={agePopupVisible}
        onClose={() => setAgePopupVisible(false)}
        className="accueil-rodal"
        closeMaskOnClick={false}
        showCloseButton={false}
        animation="fade"
        delay="2"
      >
        <div className="accueil-rodal">
          <div className="accueil-rodal-logo">
            <img src={logo} alt="logo"></img>
          </div>
          <div className="accueil-rodal-titre">
            <h4>Bienvenue dans La Cave !</h4>
            <h4>Valide ton âge ci dessous, et fais comme chez toi</h4>
          </div>
          <button
            onClick={() => setAgePopupVisible(false)}
            className="a_button"
          >
            J'ai plus de 18 ans
          </button>
        </div>
      </Rodal>
      {/* ____________________________________________SECTION 1 */}
      <section className="accueilSectionHome">
        <Accueil2SectionHome data={props.data} />
      </section>

      {/* ____________________________________________SECTION 2 */}
      <section className="accueilSectionConcept" id="commentcamarche">
        <Accueil2SectionConcept scrollY={scrollY} data={props.data} />
      </section>

      {/* ____________________________________________SECTION 3 GAMMES */}
      <section className="accueilSectionGammes" id="gammes">
        <Accueil2SectionGammes data={props.data} />
      </section>

      {/* ____________________________________________SECTION 4 POURQUOI LA CAVE*/}
      <section className="accueilSectionPourquoi" id="pourquoi">
        <Accueil2SectionPourquoi data={props.data} />
      </section>

      {/* ____________________________________________SECTION 5 AVIS CLIENTS*/}
      <section className="accueilSectionAvis" id="avis">
        <Accueil2SectionAvis data={props.data.items[0].fields.avis} />
      </section>

      {/* ------------------------------ FOOTER -------------------------*/}
      <Footer
        toggleStateNewsletter={() => {
          setPopupNewsletter(!popupNewsletter);
        }}
      />
    </div>
  );
}

export default Accueil2;
