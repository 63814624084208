import "../css/Footer.sass";

import { FaFacebookF, FaInstagram } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { createClient } from "contentful";
import paymentlogo from "../img/payment-logo.png";

const client = createClient({
  space: "guch2e4karc9",
  accessToken: "_Dg44avSaFH_aB0jT8Wilid6WXATK_qJNlM3klBXS9s",
});

function Footer(props) {
  const [dossierPresse, setDossierPresse] = useState();

  useEffect(() => {
    client
      .getAsset("1LYpRVQGaJ8vZgQYW6vXHr")
      .then((asset) => {
        console.log("asset : ", asset);
        setDossierPresse(asset.fields.file.url);
      })
      .catch(console.error);
  }, []);

  console.log("dossierPresse : ", dossierPresse);

  return (
    <div className="footerContainer">
      <div className="newsletterBloc">
        <button
          className="buttonNewsletter"
          onClick={() => {
            props.toggleStateNewsletter();
          }}
        >
          S'INSCRIRE
        </button>
      </div>
      <div className="calltoactionBloc">
        Inscris toi pour recevoir des offres spéciales et tous nos prochains
        articles de blog !
      </div>
      <div className="socialsBloc">
        <a
          href="https://www.facebook.com/lesVendangesdAntoine/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="navbar-social-icon">
            <FaFacebookF />
          </div>
        </a>
        <a
          href="https://www.instagram.com/lesvendangesdantoine/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="navbar-social-icon">
            <FaInstagram />
          </div>
        </a>
      </div>
      <div className="linksBloc">
        <Link to="/blog/qui-sommes-nous">
          <div className="navbar-social-icon">Qui sommes nous ?</div>
        </Link>
        <Link to="/faq">
          <div className="navbar-social-icon">FAQ</div>
        </Link>
        <Link to="/contact">
          <div className="navbar-social-icon">Contact</div>
        </Link>
        <a href={dossierPresse} target="_blank" rel="noopener noreferrer">
          <div className="navbar-social-icon">Dossier de presse</div>
        </a>
        <Link to="/conditions-generales-de-vente">
          <div className="navbar-social-icon">Conditions générales</div>
        </Link>
      </div>

      <div className="paymentBloc">
        <img className="paymentImg" src={paymentlogo} alt="paiement sécurisé" />
      </div>
      <div className="warningBloc">
        L'ABUS D'ALCOOL EST DANGEREUX POUR LA SANTÉ. À CONSOMMER AVEC MODÉRATION
      </div>
    </div>
  );
}

export default Footer;
