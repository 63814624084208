import "../css/Accueil2sectionHome.sass";

import { BrowserView, MobileView } from "react-device-detect";
import { motion, useTransform, useViewportScroll } from "framer-motion";

import Card from "./Card";
import { FaAngleDown } from "react-icons/fa";
import React from "react";
import drop2 from "../img/drops/drop2.svg";
import glass2 from "../img/glass.svg";
import human from "../img/standing-5.svg";

function Accueil2SectionHome(props) {
  const { scrollYProgress } = useViewportScroll();
  // const scale = useTransform(scrollYProgress, [0, 0.3], [1, 0.8]);
  const parallaxBgHome = useTransform(scrollYProgress, [0, 1], [0, 500]);
  const parallaxGlass = useTransform(scrollYProgress, [0, 1], [0, 800]);
  const parallaxGoutte = useTransform(scrollYProgress, [0, 1], [0, 200]);
  const parallaxHuman = useTransform(scrollYProgress, [0, 1], [0, -600]);

  return (
    <>
      {/* <div
        className="accueilSection1backgroundimage"
      ></div> */}
      <motion.div
        style={{
          y: parallaxBgHome,
        }}
        className="accueilSection1backgroundimage"
      ></motion.div>
      <motion.img
        style={{
          y: parallaxGlass,
        }}
        whileTap={{ scale: 0.95 }}
        initial={{ x: -500 }}
        animate={{ x: 0 }}
        transition={{ ease: "backOut", duration: 1 }}
        className="accueilSection1Verre"
        src={glass2}
        alt="Verre de vin"
      ></motion.img>
      <motion.img
        style={{
          y: parallaxHuman,
        }}
        whileTap={{ scale: 0.95 }}
        initial={{ y: -500 }}
        animate={{ y: 0 }}
        transition={{ ease: "backOut", duration: 0.8 }}
        className="accueilSection1Human"
        src={human}
        alt="Utilisateur"
      ></motion.img>
      <motion.img
        style={{
          x: parallaxGoutte,
        }}
        whileTap={{ scale: 0.95 }}
        initial={{ x: 1500 }}
        animate={{ x: 0 }}
        transition={{ ease: "backOut", duration: 1.2 }}
        className="accueilSection1Drop"
        src={drop2}
        alt="Goutte"
      ></motion.img>
      <BrowserView>
        <motion.div
          drag
          dragConstraints={{ left: -100, right: 100, top: -50, bottom: 50 }}
          // whileTap={{ scale: 0.95 }}
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{ ease: "easeOut", duration: 3 }}
          className="accueilSection1Card"
        >
          <Card
            titre={props.data.items[0].fields.contenuAccueil.fields.blocTitre}
            texte={props.data.items[0].fields.contenuAccueil.fields.blocTexte}
            width="350px"
            height="400px"
            imageSrc={
              props.data.items[0].fields.contenuAccueil.fields.image.fields.file
                .url
            }
            imagePosition="top"
          />
        </motion.div>
      </BrowserView>
      <MobileView>
        <motion.div
          style={{ zIndex: 999 }}
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{ ease: "easeOut", duration: 3 }}
          className="accueilSection1Card"
        >
          <Card
            titre={props.data.items[0].fields.contenuAccueil.fields.blocTitre}
            texte={props.data.items[0].fields.contenuAccueil.fields.blocTexte}
            width="350px"
            height="400px"
            imageSrc={
              props.data.items[0].fields.contenuAccueil.fields.image.fields.file
                .url
            }
            imagePosition="top"
          />
        </motion.div>
      </MobileView>
      <div className="arrow_more">
        <FaAngleDown
          onClick={() => window.scrollTo(0, (90 * window.innerHeight) / 100)}
        />
      </div>
    </>
  );
}

export default Accueil2SectionHome;
