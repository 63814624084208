import Blog from "../pages/Blog.js";
import React from "react";
import { useContentful } from "react-contentful";

function QueriesBlog(props) {
  const { data } = useContentful({
    contentType: "PAGE BLOG",
    query: {
      content_type: "pageBlog",
      "sys.id": "68hMkGXyPzB4yq28T9NcHj",
    },
  });
  console.log("BLOG DATA : ", data);
  if (!data) {
    return null;
  }

  return <Blog logo={props.logo} data={data} />;
}

export default QueriesBlog;
