import "../../css/QuestionnaireTaste.sass";

import React, { useEffect, useState } from "react";

import { BiReset } from "react-icons/bi";
import { motion } from "framer-motion";

// import { motion, useTransform, useViewportScroll } from "framer-motion";

function QuestionnaireTaste(props) {
  const [tagsBlanc, setTagsBlanc] = useState([]);
  const [tagsRouge, setTagsRouge] = useState([]);

  const dataBlancTab = props.data.items[0].fields.tagsGoutsBlanc;
  const dataRougeTab = props.data.items[0].fields.tagsGoutsRouge;
  // console.log("tagsBlanc : ", tagsBlanc);
  // console.log("tagsRouge : ", tagsRouge);

  //Tableau tampon, remplissage
  useEffect(() => {
    //BLANC
    let tempBlancArray = [];
    for (let i = 0; i < dataBlancTab.length; i++) {
      tempBlancArray.push({ value: dataBlancTab[i], liked: "start" });
    }
    setTagsBlanc(tempBlancArray);

    //ROUGE
    let tempRougeArray = [];
    for (let i = 0; i < dataRougeTab.length; i++) {
      tempRougeArray.push({ value: dataRougeTab[i], liked: "start" });
    }
    setTagsRouge(tempRougeArray);
  }, [dataBlancTab, dataRougeTab]);

  const {
    setBlancTagsDisliked,
    setBlancTagsNormal,
    setBlancTagsLiked,
    setRougeTagsDisliked,
    setRougeTagsNormal,
    setRougeTagsLiked,
  } = props;

  //SETSTATE QUESTIONNAIRE STRING TAGS disliked/normal/liked
  useEffect(() => {
    //BLANC DISLIKED
    function stringifyTagsTabsOnFilter(object, filter1, filter2) {
      let tagsObject = {};
      let tagsString = "";

      if (filter2) {
        tagsObject = object.filter(
          (e) => e.liked === filter1 || e.liked === filter2
        );
      } else {
        tagsObject = object.filter((e) => e.liked === filter1);
      }

      for (let i = 0; i < tagsObject.length; i++) {
        tagsString = tagsString + tagsObject[i].value + ", ";
      }
      return tagsString;
    }

    setBlancTagsDisliked(stringifyTagsTabsOnFilter(tagsBlanc, "disliked"));
    setBlancTagsNormal(stringifyTagsTabsOnFilter(tagsBlanc, "start", "normal"));
    setBlancTagsLiked(stringifyTagsTabsOnFilter(tagsBlanc, "liked"));
    setRougeTagsDisliked(stringifyTagsTabsOnFilter(tagsRouge, "disliked"));
    setRougeTagsNormal(stringifyTagsTabsOnFilter(tagsRouge, "start", "normal"));
    setRougeTagsLiked(stringifyTagsTabsOnFilter(tagsRouge, "liked"));
  }, [
    setBlancTagsDisliked,
    setBlancTagsNormal,
    setBlancTagsLiked,
    setRougeTagsDisliked,
    setRougeTagsNormal,
    setRougeTagsLiked,
    tagsBlanc,
    tagsRouge,
  ]);

  const variants = {
    initial: (i) => ({
      backgroundColor: "#fff",
      color: "black",
      x: "-350px",
    }),
    start: (i) => ({
      x: "0px",
      height: 40,
      textDecorationLine: "none",
      backgroundColor: "#f9d5ce",
      color: "black",
      paddingTop: "3px",
      paddingBottom: "3px",
      fontSize: "15px",
      fontWeight: "500",
      transition: {
        delay: i * 0.3,
      },
    }),
    disliked: {
      // x: "-70px",
      height: 30,
      // textDecorationLine: "line-through",
      backgroundColor: "#F08F7C",
      color: "white",
      fontSize: "13px",
    },
    normal: {
      x: "0px",
      height: 40,
      textDecorationLine: "none",
      backgroundColor: "#f9d5ce",
      color: "black",
      paddingTop: "3px",
      paddingBottom: "3px",
      fontSize: "15px",
      fontWeight: "500",
    },
    liked: {
      // x: "70px",
      height: 50,
      backgroundColor: "#fff",
      color: "black",
      paddingTop: "8px",
      paddingBottom: "8px",
      fontSize: "17px",
      fontWeight: "700",
    },
  };

  // const variantsButton = {
  //   liked: {
  //     padding: 0,
  //   }
  // }

  const listBlancTags = tagsBlanc.map((tagBlanc, index) => {
    return (
      <motion.div
        custom={index}
        className="tasteItem"
        initial="initial"
        animate={tagBlanc.liked}
        variants={variants}
      >
        <div
          className="tasteItemButton"
          onClick={() => {
            let tempBlancArray = [];
            for (let i = 0; i < tagsBlanc.length; i++) {
              //Quand on arrive à la ligne de l'événement cliqué
              if (i === index) {
                //Toggle l'ouverture
                if (
                  tagsBlanc[i].liked !== "normal" &&
                  tagsBlanc[i].liked !== "start"
                ) {
                  tempBlancArray.push({
                    value: tagsBlanc[i].value,
                    liked: "normal",
                  });
                } else {
                  tempBlancArray.push({
                    value: tagsBlanc[i].value,
                    liked: "disliked",
                  });
                }
              } else {
                //Fermer les autres
                tempBlancArray.push({
                  value: tagsBlanc[i].value,
                  liked: tagsBlanc[i].liked,
                });
              }
            }
            setTagsBlanc(tempBlancArray);
          }}
        >
          -
        </div>
        {tagBlanc.value}
        <div
          className="tasteItemButton"
          onClick={() => {
            let tempBlancArray = [];
            for (let i = 0; i < tagsBlanc.length; i++) {
              //Quand on arrive à la ligne de l'événement cliqué
              if (i === index) {
                //Toggle l'ouverture
                if (
                  tagsBlanc[i].liked !== "normal" &&
                  tagsBlanc[i].liked !== "start"
                ) {
                  tempBlancArray.push({
                    value: tagsBlanc[i].value,
                    liked: "normal",
                  });
                } else {
                  tempBlancArray.push({
                    value: tagsBlanc[i].value,
                    liked: "liked",
                  });
                }
              } else {
                //Fermer les autres
                tempBlancArray.push({
                  value: tagsBlanc[i].value,
                  liked: tagsBlanc[i].liked,
                });
              }
            }
            setTagsBlanc(tempBlancArray);
          }}
        >
          +
        </div>
      </motion.div>
    );
  });

  const listRougeTags = tagsRouge.map((tagRouge, index) => {
    return (
      <motion.div
        custom={index * 1.25}
        className="tasteItem"
        initial="initial"
        animate={tagRouge.liked}
        variants={variants}
      >
        <div
          className="tasteItemButton"
          onClick={() => {
            let tempRougeArray = [];
            for (let i = 0; i < tagsRouge.length; i++) {
              //Quand on arrive à la ligne de l'événement cliqué
              if (i === index) {
                //Toggle l'ouverture
                if (
                  tagsRouge[i].liked !== "normal" &&
                  tagsRouge[i].liked !== "start"
                ) {
                  tempRougeArray.push({
                    value: tagsRouge[i].value,
                    liked: "normal",
                  });
                } else {
                  tempRougeArray.push({
                    value: tagsRouge[i].value,
                    liked: "disliked",
                  });
                }
              } else {
                //Fermer les autres
                tempRougeArray.push({
                  value: tagsRouge[i].value,
                  liked: tagsRouge[i].liked,
                });
              }
            }
            setTagsRouge(tempRougeArray);
          }}
        >
          -
        </div>
        {tagRouge.value}
        <div
          className="tasteItemButton"
          onClick={() => {
            let tempRougeArray = [];
            for (let i = 0; i < tagsRouge.length; i++) {
              //Quand on arrive à la ligne de l'événement cliqué
              if (i === index) {
                //Toggle l'ouverture
                if (
                  tagsRouge[i].liked !== "normal" &&
                  tagsRouge[i].liked !== "start"
                ) {
                  tempRougeArray.push({
                    value: tagsRouge[i].value,
                    liked: "normal",
                  });
                } else {
                  tempRougeArray.push({
                    value: tagsRouge[i].value,
                    liked: "liked",
                  });
                }
              } else {
                //Fermer les autres
                tempRougeArray.push({
                  value: tagsRouge[i].value,
                  liked: tagsRouge[i].liked,
                });
              }
            }
            setTagsRouge(tempRougeArray);
          }}
        >
          +
        </div>
      </motion.div>
    );
  });

  return (
    <div className="tasteContainer">
      <div className="tasteCard">
        <div className="tasteColumn">
          <div className="tasteColumnTitre">Vins blancs</div>
          <div className="tasteColumnDnd">{listBlancTags}</div>
          <motion.div
            whileTap={{ rotate: -130, y: 2 }}
            whileHover={{ rotate: 25 }}
            className="tasteReset"
            onClick={() => {
              let tempBlancArray = [];
              for (let i = 0; i < tagsBlanc.length; i++) {
                //Quand on arrive à la ligne de l'événement cliqué
                tempBlancArray.push({
                  value: tagsBlanc[i].value,
                  liked: "normal",
                });
              }
              setTagsBlanc(tempBlancArray);
            }}
          >
            <BiReset />
          </motion.div>
        </div>
        <div className="tasteColumn">
          <div className="tasteColumnTitre">Vins rouges</div>
          <div className="tasteColumnDnd">{listRougeTags}</div>
          <motion.div
            whileTap={{ rotate: -130, y: 2 }}
            whileHover={{ rotate: 25 }}
            className="tasteReset"
            onClick={() => {
              let tempRougeArray = [];
              for (let i = 0; i < tagsRouge.length; i++) {
                //Quand on arrive à la ligne de l'événement cliqué
                tempRougeArray.push({
                  value: tagsRouge[i].value,
                  liked: "normal",
                });
              }
              setTagsRouge(tempRougeArray);
            }}
          >
            <BiReset />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default QuestionnaireTaste;
