import "../css/NotFound.sass";

import { Link } from "react-router-dom";
import React from "react";
import logo from "../img/logo.svg";

function NotFound() {
  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <div className="notFoundBg"></div>
      <div className="notFoundContainer">
        <div className="notFoundContent">
          <img className="notFoundLogo" src={logo} alt="notFoundLogo" />
        </div>
        <div className="notFoundContent">
          <div className="notFoundText">Aïe, c'est une page 404</div>
          <div className="notFoundText subText">
            Tu peux cliquer n'importe où.
          </div>
        </div>
      </div>
    </Link>
  );
}

export default NotFound;
