import "rodal/lib/rodal.css";

import React from "react";
import Rodal from "rodal";

function PopupNoDelivery(props) {
  return (
    <Rodal
      visible={props.noDeliveryPopup}
      onClose={() => props.onClosePopup()}
      className="smallPopup"
      closeMaskOnClick={true}
      showCloseButton={true}
    >
      <div className="smallPopupInner">
        <div className="smallPopupTitre">
          {props.adresseVille} <br />
          La livraison arrive bientôt !
        </div>
        <div className="smallPopupTexte">
          Désolé, nous ne livrons qu'en France métropolitaine pour le moment.
        </div>
        <div className="smallPopupEnd">
          On te tient au courant par mail quand on livre chez toi ?
        </div>
        <button className="q_button" onClick={() => props.onClickButton()}>
          Tenez moi au courant
        </button>
      </div>
    </Rodal>
  );
}

export default PopupNoDelivery;
