import "../css/Accueil2SectionGammes.sass";

import { motion, useTransform, useViewportScroll } from "framer-motion";

import React from "react";
import bouteilleEDG from "../img/bouteilleEDG.svg";
import bouteilleHDG from "../img/bouteilleHDG.svg";
import bouteilleMDG from "../img/bouteilleMDG.svg";
import propsetter from "../img/propsetter.png";
import { useInView } from "react-intersection-observer";

// import personnalise from "../img/personnalise.png";

function Accueil2SectionGammes(props) {
  const { scrollYProgress } = useViewportScroll();
  const parallaxBgGammes = useTransform(scrollYProgress, [0, 1], [-400, 200]);
  const slideLeft = useTransform(scrollYProgress, [0, 1], [-200, 0]);
  const slideRight = useTransform(scrollYProgress, [0, 1], [0, -200]);

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  // console.log("DATA GAMMES : ", props.data.items[0].fields.gammes);
  const dataGammes = props.data.items[0].fields.gammes;

  return (
    <div>
      <motion.div
        style={{
          y: parallaxBgGammes,
        }}
        className="accueilSection3backgroundimage"
      ></motion.div>
      <div className="sectionTitre">LES GAMMES</div>
      <motion.div
        ref={ref1}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView1 ? 1 : 0 }}
        transition={{ duration: 1.5 }}
        style={{ x: slideRight }}
        className="cardSection3 cardEDG"
      >
        <div className="leftContainer bouteilleEDG">
          <img className="leftImg" src={bouteilleEDG} alt=""></img>
        </div>
        <div className="rightContainer">
          <div className="titre">{dataGammes[0].fields.titrecontenu}</div>
          <div className="texte">{dataGammes[0].fields.blocTexte}</div>
          <div className="prix">{dataGammes[0].fields.blocTitre}</div>
        </div>
      </motion.div>
      <motion.div
        ref={ref2}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView2 ? 1 : 0 }}
        transition={{ duration: 1.5 }}
        style={{ x: slideLeft }}
        className="cardSection3 cardMDG"
      >
        <div className="leftContainer">
          <img className="leftImg" src={bouteilleMDG} alt=""></img>
        </div>
        <div className="rightContainer">
          <div className="titre">{dataGammes[1].fields.titrecontenu}</div>
          <div className="texte">{dataGammes[1].fields.blocTexte}</div>
          <div className="prix">{dataGammes[1].fields.blocTitre}</div>
        </div>
      </motion.div>
      <motion.div
        ref={ref3}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView3 ? 1 : 0 }}
        transition={{ duration: 1.5 }}
        style={{ x: slideRight }}
        className="cardSection3 cardHDG"
      >
        <div className="leftContainer">
          <img className="leftImg" src={bouteilleHDG} alt=""></img>
        </div>
        <div className="rightContainer">
          <div className="titre">{dataGammes[2].fields.titrecontenu}</div>
          <div className="texte">{dataGammes[2].fields.blocTexte}</div>
          <div className="prix">{dataGammes[2].fields.blocTitre}</div>
        </div>
      </motion.div>
      <div className="imgRightSection3">
        <motion.img
          animate={{ rotate: [0, -140, 230, 310, 0] }}
          transition={{ loop: Infinity, duration: 17 }}
          className="rightImg"
          src={propsetter}
          alt=""
        ></motion.img>
      </div>
    </div>
  );
}

export default Accueil2SectionGammes;
