import "../css/Questionnaire.sass";
import "animate.css";
import "rodal/lib/rodal.css";
import "react-day-picker/lib/style.css";

import { AnimatePresence, motion } from "framer-motion";
// import QuestionnaireOffrir from "./QuestionnaireOffrir.js";
import { BrowserView, MobileView } from "react-device-detect";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { HiOutlineMailOpen, HiOutlineSparkles } from "react-icons/hi";
import React, { useEffect, useRef, useState } from "react";

import AlgoliaPlaces from "algolia-places-react";
import ButtonLivraisonMode from "../components/ButtonLivraisonMode.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { FiGift } from "react-icons/fi";
// import parsePhoneNumber from "libphonenumber-js";
import InputSlider from "react-input-slider";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import OutsideClickHandler from "react-outside-click-handler";
import PopupNewsletter from "../components/questionnaire/PopupNewsletter.js";
import PopupNoDelivery from "../components/questionnaire/PopupNoDelivery.js";
import PopupRecap from "../components/questionnaire/PopupRecap.js";
import QuestionnaireCreneau from "../components/questionnaire/QuestionnaireCreneau";
import QuestionnaireTaste from "../components/questionnaire/QuestionnaireTaste.js";
import RegionsCheckboxes from "../components/questionnaire/regionsCheckboxes";
import Rodal from "rodal";
import Slider from "react-slick";
import SliderPack from "../components/SliderPack";
import arrow from "../img/steps/arrow.svg";
import axios from "axios";
import bouteille from "../img/bouteille.svg";
import checked from "../img/steps/1_checked.svg";
// import moment from "moment";
import payment from "../img/steps/3_payment.svg";
import phone from "../img/steps/2_phone.svg";
// import { useInView } from "react-intersection-observer";
import preloader from "../img/preloader.gif";
import shipping from "../img/steps/4_shipping.svg";

console.log({ ChartDataLabels });

//Algolia Places
var validator = require("email-validator");

function Questionnaire(props) {
  const [customerAirTableID, setCustomerAirTableID] = useState("");

  const [prenomNom, setPrenomNom] = useState("");
  const [nbBouteilles, setNbBouteilles] = useState(24);
  const [nature, setNature] = useState("");
  const [propRouge, setPropRouge] = useState(35);
  const [propBlanc, setPropBlanc] = useState(35);
  const [propRosé, setPropRosé] = useState(15);
  const [propChampagne, setPropChampagne] = useState(15);
  const [propHDG, setPropHDG] = useState(40);
  const [propMDG, setPropMDG] = useState(40);
  const [propEDG, setPropEDG] = useState(40);
  const [budgetTotal, setBudgetTotal] = useState(500);
  // const [rougeLegerPuissant, setRougeLegerPuissant] = useState(1);
  // const [blancSecFruite, setBlancSecFruite] = useState(1);
  // const [goutsCarteBlanche, setGoutsCarteBlanche] = useState("Oui");
  const [blancTagsDisliked, setBlancTagsDisliked] = useState("");
  const [blancTagsNormal, setBlancTagsNormal] = useState("");
  const [blancTagsLiked, setBlancTagsLiked] = useState("");
  const [rougeTagsDisliked, setRougeTagsDisliked] = useState("");
  const [rougeTagsNormal, setRougeTagsNormal] = useState("");
  const [rougeTagsLiked, setRougeTagsLiked] = useState("");
  const [regionsPlusBlanc, setRegionsPlusBlanc] = useState([]);
  const [regionsMoinsBlanc, setRegionsMoinsBlanc] = useState([]);
  const [regionsPlusRouge, setRegionsPlusRouge] = useState([]);
  const [regionsMoinsRouge, setRegionsMoinsRouge] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [aContacter, setAContacter] = useState("tel");
  const [adresseVoie, setAdresseVoie] = useState("");
  const [adresseNotes, setAdresseNotes] = useState("");
  const [adresseCP, setAdresseCP] = useState("");
  const [adresseVille, setAdresseVille] = useState("");
  const [livraisonMode, setLivraisonMode] = useState("zoneC");
  const [livraisonPrix, setLivraisonPrix] = useState();
  const [livraisonCreneau, setLivraisonCreneau] = useState(undefined);
  // const [parrainageCode, setParrainageCode] = useState("");
  // const [parrainageNbFilleuls, setParrainageNbFilleuls] = useState();
  const [parrainCode, setParrainCode] = useState("");

  // const [codeCadeau, setCodeCadeau] = useState("");

  const [livraisonCreneauTime, setLivraisonCreneauTime] = useState(7);
  const [livraisonCreneauDate, setLivraisonCreneauDate] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [adresseValue, setAdresseValue] = useState("");
  const [livraisonOfferte, setLivraisonOfferte] = useState("");
  const [reducParrainage, setReducParrainage] = useState(false);
  const [isCodeParrainOk, setIsCodeParrainOk] = useState(false);
  const [nameParrain, setNameParrain] = useState("");

  const [chartdata, setChartdata] = useState({});
  const [chart2data, setChart2data] = useState({});
  const [chartoptions, setChartoptions] = useState({});
  const [chartoptionsMobile, setChartoptionsMobile] = useState({});
  const [cardHDGOpened, setCardHDGOpened] = useState(false);
  const [cardMDGOpened, setCardMDGOpened] = useState(false);
  const [cardEDGOpened, setCardEDGOpened] = useState(false);
  const [lockBudget, setLockBudget] = useState("nbBouteilles");
  const [regionCardOpened, setRegionCardOpened] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  //CONFIRME POPUP START
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [questionnaireTerminé, setQuestionnaireTerminé] = useState(false);
  const [sentPopup, setSentPopup] = useState(false);
  const [infoNaturePopup, setInfoNaturePopup] = useState(false);
  const [noDeliveryPopup, setNoDeliveryPop] = useState(false);
  const [popupNewsletter, setPopupNewsletter] = useState(false);
  // const [popupCalendly, setPopupCalendly] = useState(false);

  const nbRegions = props.data.items[0].fields.regions.length;

  const prixUnitEDG = 8;
  const prixUnitMDG = 18;
  const prixUnitHDG = 30;

  var slider = useRef();

  var zoneAPrice = props.data.items[0].fields.livraisons.fields.prixZoneA;
  var zoneBPrice = props.data.items[0].fields.livraisons.fields.prixZoneB;
  var zoneCPrice = props.data.items[0].fields.livraisons.fields.prixZoneC;

  // console.log("DATE : ", dd, "/", mm, "/", yyyy);
  // console.log("threeDaysFromToday : ", threeDaysFromToday);

  // console.log("adresseVoie : ", adresseVoie);
  // console.log("adresseCP : ", adresseCP);
  // console.log("adresseVille : ", adresseVille);
  // console.log("adresseValue : ", adresseValue);

  // console.log({ livraisonMode });
  // console.log({ livraisonPrix });

  // console.log("blancTagsDisliked : ", blancTagsDisliked);
  // console.log("blancTagsNormal : ", blancTagsNormal);
  // console.log("blancTagsLiked : ", blancTagsLiked);
  // console.log("rougeTagsDisliked : ", rougeTagsDisliked);
  // console.log("rougeTagsNormal : ", rougeTagsNormal);
  // console.log("rougeTagsLiked : ", rougeTagsLiked);

  // console.log("-------------------");
  // console.log("PLUS Blanc : ", regionsPlusBlanc);
  // console.log("MOINS Blanc : ", regionsMoinsBlanc);
  // console.log("PLUS Rouge : ", regionsPlusRouge);
  // console.log("MOINS Rouge : ", regionsMoinsRouge);

  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}

  const toggleCardsRegionsOff = (index) => {
    var stateArray = [];
    for (let i = 0; i < nbRegions; i++) {
      //Quand on arrive à la ligne de l'événement cliqué
      if (i === index) {
        //Toggle l'ouverture
        stateArray.push(!regionCardOpened[index]);
      } else {
        //Fermer les autres
        stateArray.push(false);
      }
    }
    setRegionCardOpened(stateArray);
  };

  const updateOptions = () => {
    setChartoptions({
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          fontColor: "rgb(255, 255, 255)",
        },
      },
      // DISPLAY TEXT ON PIE SEGMENTS
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return sum;
            });
            let percentage = ((value * 100) / sum).toFixed(0) + "%";
            return percentage;
          },
          color: "white",
          font: {
            weight: "bold",
            size: 18,
          },
        },
      },
    });
    setChartoptionsMobile({
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          fontColor: "black",
        },
      },
      // DISPLAY TEXT ON PIE SEGMENTS
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return sum;
            });
            let percentage = ((value * 100) / sum).toFixed(0) + "%";
            return percentage;
          },
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    });
  };

  useEffect(() => {
    setChartdata({
      labels: ["Rouge", "Blanc", "Rosé", "Champagne"],
      datasets: [
        {
          label: "Répartition types de vin",
          backgroundColor: ["#F50208", "#0773A1", "#F08F7C", "#F8DBA1"],
          data: [propRouge, propBlanc, propRosé, propChampagne],
        },
      ],
    });

    setChart2data({
      labels: ["Haut de gamme", "Moyen de gamme", "Entrée de gamme"],
      datasets: [
        {
          label: "Répartition gammes de vin",
          backgroundColor: ["#ffe888", "#DCDCDC", "#d2be93"],
          data: [propHDG, propMDG, propEDG],
        },
      ],
    });
    updateOptions();

    const budgetTot = (nbTeilles) => {
      return Math.floor(
        nbTeilles * (propEDG / (propHDG + propMDG + propEDG)) * prixUnitEDG +
          nbTeilles * (propMDG / (propHDG + propMDG + propEDG)) * prixUnitMDG +
          nbTeilles * (propHDG / (propHDG + propMDG + propEDG)) * prixUnitHDG
      );
    };
    const btlsTot = (budget) => {
      return Math.floor(
        budget /
          (prixUnitEDG * (propEDG / (propHDG + propMDG + propEDG)) +
            prixUnitMDG * (propMDG / (propHDG + propMDG + propEDG)) +
            prixUnitHDG * (propHDG / (propHDG + propMDG + propEDG)))
      );
    };

    // console.log("NBBOUTEILLES POUR 800€ 33% : ", btlsTot(800));
    // console.log("VÉRIF BUDGET (SI 800 => OK) : ", budgetTot(btlsTot(800)));

    if (budgetTot(nbBouteilles) >= 500) {
      if (lockBudget === "budgetTotal") {
        if (budgetTotal > budgetTot(nbBouteilles)) {
          for (let btls = 1500; budgetTot(btls) >= budgetTotal; btls--) {
            setNbBouteilles(btls - 1);
          }
          // setBudgetTotal(budgetTot(nbBouteilles));
        } else {
          for (let btls = 0; budgetTot(btls) < budgetTotal; btls++) {
            // setNbBouteillesMini(index + 1);
            setNbBouteilles(btls + 1);
          }
        }
      } else if (lockBudget === "nbBouteilles") {
        if (nbBouteilles > btlsTot(budgetTotal)) {
          for (let bdgt = 10000; btlsTot(bdgt) >= nbBouteilles; bdgt--) {
            // setNbBouteillesMini(index + 1);
            setBudgetTotal(bdgt - 1);
          }
        } else {
          for (let bdgt = 0; btlsTot(bdgt) < nbBouteilles; bdgt++) {
            // setNbBouteillesMini(index + 1);
            setBudgetTotal(bdgt + 1);
          }
        }
      }
    } else {
      if (budgetTotal > budgetTot(nbBouteilles)) {
        for (let btls = 1500; budgetTot(btls) >= budgetTotal; btls--) {
          setNbBouteilles(btls - 1);
        }
        // setBudgetTotal(budgetTot(nbBouteilles));
      } else {
        for (let btls = 0; budgetTot(btls) < budgetTotal; btls++) {
          // setNbBouteillesMini(index + 1);
          setNbBouteilles(btls + 1);
        }
      }
    }

    var stateArray = [];
    for (let i = 0; i < props.data.items[0].fields.regions.length; i++) {
      stateArray.push(false);
    }
    setRegionCardOpened(stateArray);
  }, [
    propRouge,
    propBlanc,
    propRosé,
    propChampagne,
    propHDG,
    propMDG,
    propEDG,
    budgetTotal,
    nbBouteilles,
    nbRegions,
    props.data,
    lockBudget,
  ]);

  useEffect(() => {
    if (livraisonCreneauDate !== undefined) {
      var newLivraisonCreneau = new Date(livraisonCreneauDate);
      newLivraisonCreneau.setHours(livraisonCreneauTime, 0, 0);
      setLivraisonCreneau(newLivraisonCreneau);
    }
  }, [livraisonCreneauDate, livraisonCreneauTime]);

  // LIVRAISON OFFERTE SI CODE PARRAIN OK, SINON CALCUL SELON BUDGET
  useEffect(() => {
    if (isCodeParrainOk) {
      // setLivraisonOfferte("free");
      // setLivraisonPrix(0);
      setReducParrainage(true);
    } else {
      if (budgetTotal < 990) {
        setLivraisonOfferte("normal");
        switch (livraisonMode) {
          case "zoneA":
            setLivraisonPrix(zoneAPrice);
            break;
          case "zoneB":
            setLivraisonPrix(zoneBPrice);
            break;
          case "zoneC":
            setLivraisonPrix(zoneCPrice);
            break;
          default:
            setLivraisonPrix(zoneAPrice);
            break;
        }
      }
      if (budgetTotal >= 800 && budgetTotal < 990) {
        setLivraisonOfferte("almost");
      } else if (budgetTotal >= 990) {
        setLivraisonOfferte("free");
        setLivraisonPrix(0);
      }
    }
  }, [
    isCodeParrainOk,
    budgetTotal,
    livraisonMode,
    zoneAPrice,
    zoneBPrice,
    zoneCPrice,
  ]);

  useEffect(() => {
    console.log("adresseCP : ", adresseCP);
    if (
      props.data.items[0].fields.livraisons.fields.zoneA.includes(adresseCP)
    ) {
      // console.log("Code postal = zone A");
      setLivraisonMode("zoneA");
    } else if (
      props.data.items[0].fields.livraisons.fields.zoneB.includes(adresseCP)
    ) {
      // console.log("Code postal = zone B");
      setLivraisonMode("zoneB");
    } else if (adresseCP === "") {
      console.log("Code postal = hors France");
      setLivraisonMode("");
    } else {
      // console.log("Code postal = zone C");
      setLivraisonMode("zoneC");
      setLivraisonCreneauTime(9);
    }
  }, [adresseCP, props.data.items]);

  // START SLIDE
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    swipe: false,
    initialSlide: 0,
    arrows: false,
    beforeChange: (current, next) => startChangeSlide(next),
  };

  const sliderStyles = {
    track: {
      display: "block",
      height: "30px",
      zIndex: "0",
      minWidth: "200px",
      maxWidth: "300px",
      width: "100%",
      backgroundColor: "#ffdfdf",
      boxShadow: "2px 2px 5px 2px rgba(42, 42, 42, 0.17)",
      cursor: "pointer",
      transition: "all 0.3s",
    },
    active: {
      backgroundColor: "#000000",
    },
    thumb: {
      width: 0,
      height: 0,
    },
    disabled: {
      opacity: 0.5,
    },
  };

  const startChangeSlide = (next) => {
    setCurrentSlide(next);
    // console.log("current slide", next);
    // console.log("current slide state", currentSlide);
  };

  const allCardsGammeOff = () => {
    setCardHDGOpened(false);
    setCardMDGOpened(false);
    setCardEDGOpened(false);
  };

  // const [ref, inView] = useInView({
  //   triggerOnce: false,
  //   rootMargin: "-100px 0px",
  // });

  return (
    <div className="q_container">
      <Navbar logo={props.logo} page="questionnaire" />
      <div
        className="q_page"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (questionnaireTerminé) {
              setConfirmPopup(true);
            } else {
              if (
                currentSlide === 1 &&
                parrainCode !== "" &&
                !isCodeParrainOk
              ) {
              } else {
                slider.slickNext();
                window.scrollTo(0, 0);
              }
            }
          }
        }}
      >
        <PopupNewsletter
          visible={popupNewsletter}
          toggleStateNewsletter={() => {
            setPopupNewsletter(!popupNewsletter);
          }}
        />
        {/* POPUP INFO VIN NATURE ET BIO --------------- */}
        <Rodal
          visible={infoNaturePopup}
          onClose={(event) => setInfoNaturePopup(false)}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <div className="smallPopupTitre">Le vin nature</div>
              <div className="smallPopupText">
                En plus d'une viticulture biologique, la vinification se veut la
                plus naturelle possible. Les levures aromatiques externes sont
                proscrites, et seules celles issues du produit originel déjà
                contenues dans le raisin sont à l'œuvre. Aucun sucre n'est
                ajouté, le vin est peu ou pas filtré (ce qui peut donner parfois
                une couleur un peu trouble).
              </div>
              <div className="smallPopupEnd">
                <div>
                  Retrouve l'article complet sur{" "}
                  <a href="/blog/les-traitements-de-la-vigne" target="_blank">
                    <i>S.O.I.F</i>
                  </a>
                  , le blog de La Cave des Vendanges d'Antoine.
                </div>
              </div>
            </div>
          </div>
        </Rodal>
        {/* POPUP CALENDLY --------------- */}
        {/* <Rodal
          visible={popupCalendly}
          onClose={(event) => {
            setPopupCalendly(false);
            setSentPopup(true);
          }}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <InlineWidget url="https://calendly.com/antoine-maffray/30min" />
            </div>
          </div>
        </Rodal> */}

        {/* POPUP CONFIRMATION QUESTIONNAIRE ENVOYÉ --------------- */}
        <Rodal
          visible={sentPopup}
          onClose={(event) => (window.location.href = "/")}
          closeMaskOnClick={true}
          showCloseButton={true}
        >
          <div className="smallPopup">
            <div className="smallPopupInner">
              <div className="smallPopupTitre">
                Toutes les informations ont bien été transmises.
              </div>
              <div className="sentPopupStepsContainer">
                <div className="stepContainer">
                  <img className="stepImage" src={checked} alt="checked" />
                  <span className="stepTexte">Questionnaire</span>
                </div>
                {aContacter !== "non" && (
                  <>
                    <div className="stepArrowContainer">
                      <img className="stepImage" src={arrow} alt="arrow" />
                      <div className="stepTexte"></div>
                    </div>
                    <div className="stepContainer">
                      <img className="stepImage" src={phone} alt="phone" />
                      <span className="stepTexte">Débrief</span>
                    </div>
                  </>
                )}
                <div className="stepArrowContainer">
                  <img className="stepImage" src={arrow} alt="arrow" />
                  <div className="stepTexte"></div>
                </div>
                <div className="stepContainer">
                  <img className="stepImage" src={payment} alt="pay" />
                  <span className="stepTexte">Paiement</span>
                </div>
                <div className="stepArrowContainer">
                  <img className="stepImage" src={arrow} alt="arrow" />
                  <div className="stepTexte"></div>
                </div>
                <div className="stepContainer">
                  <img className="stepImage" src={shipping} alt="colis" />
                  <span className="stepTexte">Livraison</span>
                </div>
              </div>
              {/* <div>{customerAirTableID}</div> */}
              {(() => {
                if (aContacter === "mail") {
                  return (
                    <div className="smallPopupEnd">
                      <div className="smallPopupTexte">
                        Antoine te contacte bientôt pour un petit débrief par
                        mail !
                      </div>
                      <Link to={"/paiement?id=" + customerAirTableID}>
                        <button className="q_button">PAYER MAINTENANT</button>
                      </Link>
                    </div>
                  );
                } else if (aContacter === "tel") {
                  return (
                    <div className="smallPopupEnd">
                      <a
                        href={
                          "https://calendly.com/antoine-maffray/30min?name=" +
                          prenomNom +
                          "&email=" +
                          email
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="q_button"
                        // onClick={() => {
                        //   setSentPopup(false);
                        //   setPopupCalendly(true);
                        // }}
                      >
                        BOOKER UN APPEL
                      </a>
                      <a
                        href={"/paiement?id=" + customerAirTableID}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="q_button">PAYER MAINTENANT</button>
                      </a>
                    </div>
                  );
                } else if (aContacter === "non") {
                  return (
                    <div className="smallPopupEnd">
                      <Link to={"/paiement?id=" + customerAirTableID}>
                        <button className="q_button">PAYER MAINTENANT</button>
                      </Link>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </Rodal>
        {/* POPUP CONFIRMATION ENVOI QUESTIONNAIRE --------------- */}

        {/* POPUP PAS DE LIVRAISON ICI --------------- */}
        <PopupNoDelivery
          noDeliveryPopup={noDeliveryPopup}
          onClosePopup={() => {
            setAdresseVoie("");
            setAdresseCP("");
            setAdresseVille("");
            setNoDeliveryPop(false);
          }}
          slider={slider}
          onClickButton={() => {
            setNoDeliveryPop(false);
            setPopupNewsletter(true);
          }}
          codesPostaux={
            props.data.items[0].fields.livraisons.fields.codesPostaux
          }
          adresseVille={adresseVille}
        />
        {/* POPUP PAS DE LIVRAISON ICI --------------- */}

        {/* POPUP RÉCAPITULATIF --------------- */}
        <PopupRecap
          visible={confirmPopup}
          slider1={() => slider.slickGoTo(1)}
          slider2={() => slider.slickGoTo(2)}
          slider3={() => slider.slickGoTo(3)}
          slider4={() => slider.slickGoTo(4)}
          slider5={() => slider.slickGoTo(5)}
          slider6={() => slider.slickGoTo(6)}
          slider7={() => slider.slickGoTo(7)}
          slider8={() => slider.slickGoTo(8)}
          slider9={() => slider.slickGoTo(9)}
          slider10={() => slider.slickGoTo(10)}
          setConfirmPopup={(bool) => setConfirmPopup(bool)}
          setNoDeliveryPop={(bool) => setNoDeliveryPop(bool)}
          isLoading={isLoading}
          handleSend={async () => {
            setIsLoading(true);
            const response = await axios.post(
              props.backendurl + "/savequestionnaire",
              {
                prenomNom: prenomNom,
                nbBouteilles: nbBouteilles,
                nature: nature,
                propRouge: propRouge,
                propBlanc: propBlanc,
                propRosé: propRosé,
                propChampagne: propChampagne,
                propHDG: propHDG,
                propMDG: propMDG,
                propEDG: propEDG,
                budgetTotal: reducParrainage
                  ? Math.trunc(budgetTotal * 0.9 * 100) / 100
                  : budgetTotal,
                blancTagsDisliked: blancTagsDisliked,
                blancTagsNormal: blancTagsNormal,
                blancTagsLiked: blancTagsLiked,
                rougeTagsDisliked: rougeTagsDisliked,
                rougeTagsNormal: rougeTagsNormal,
                rougeTagsLiked: rougeTagsLiked,
                regionsMoinsBlanc: regionsMoinsBlanc,
                regionsPlusBlanc: regionsPlusBlanc,
                regionsMoinsRouge: regionsMoinsRouge,
                regionsPlusRouge: regionsPlusRouge,
                telephone: telephone,
                email: email,
                aContacter: aContacter,
                adresseVoie: adresseVoie,
                adresseNotes: adresseNotes,
                adresseCP: adresseCP,
                adresseVille: adresseVille,
                livraisonMode: livraisonMode,
                livraisonPrix: livraisonPrix,
                livraisonCreneau: livraisonCreneau,
                parrainCode: parrainCode,
              }
            );
            setIsLoading(false);
            console.log(response);
            console.log("ID : ", response.data.id);
            // QUESTIONNAIRE AJOUTÉ OU MISE À JOUR EN BDD => ENVOI LIEN DE PAIEMENT
            if (response.data.id) {
              var newCustomerId = response.data.id;
              setConfirmPopup(false);
              setSentPopup(true);
              setCustomerAirTableID(newCustomerId);
              axios.post(props.backendurl + "/sendlink", {
                // customerId: customerAirTableID,
                customerId: newCustomerId,
              });
              //SAVE RÉPONSES IMPOSSIBLE, COMMANDE DÉJÀ PAYÉE
            } else if (response.data) {
              console.log(
                "ENVOI DES RÉPONSES IMPOSSIBLE : COMMANDE DÉJÀ PAYÉE"
              );
              console.log("response.data : ", response.data);
              const win = window.open(
                "/paiement?id=" + response.data,
                "_blank"
              );
              win.focus();
            }
          }}
          codesPostaux={
            props.data.items[0].fields.livraisons.fields.codesPostaux
          }
          prenomNom={prenomNom}
          nbBouteilles={nbBouteilles}
          nature={nature}
          propRouge={propRouge}
          propBlanc={propBlanc}
          propRosé={propRosé}
          propChampagne={propChampagne}
          propHDG={propHDG}
          propMDG={propMDG}
          propEDG={propEDG}
          budgetTotal={
            reducParrainage
              ? Math.trunc(budgetTotal * 0.9 * 100) / 100
              : budgetTotal
          }
          // rougeLegerPuissant={rougeLegerPuissant}
          // blancSecFruite={blancSecFruite}
          // goutsCarteBlanche={goutsCarteBlanche}
          blancTagsDisliked={blancTagsDisliked}
          blancTagsNormal={blancTagsNormal}
          blancTagsLiked={blancTagsLiked}
          rougeTagsDisliked={rougeTagsDisliked}
          rougeTagsNormal={rougeTagsNormal}
          rougeTagsLiked={rougeTagsLiked}
          regionsMoinsBlanc={regionsMoinsBlanc}
          regionsPlusBlanc={regionsPlusBlanc}
          regionsMoinsRouge={regionsMoinsRouge}
          regionsPlusRouge={regionsPlusRouge}
          telephone={telephone}
          email={email}
          aContacter={aContacter}
          adresseValue={adresseValue}
          livraisonPrix={livraisonPrix}
          livraisonCreneau={livraisonCreneau}
          adresseNotes={adresseNotes}
        />
        {/* POPUP RÉCAPITULATIF --------------- */}
        <Slider
          {...settings}
          // style={{ paddingTop: "70px" }}
          ref={(c) => (slider = c)}
          id="slider"
        >
          {/* ______________________ SLIDE 0 START */}
          <div className="q_slide_container">
            <div className="q_slide">
              <h5
                className={
                  currentSlide === 0
                    ? "q_question start animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_question start"
                }
              >
                On va commencer, mais d'abord, pourquoi es-tu là ?
              </h5>
              <div className="startQuestionnaireContainer">
                <button
                  className={
                    currentSlide === 0
                      ? "q_button start animate__animated animate__fadeInDown animate__delay-3s"
                      : "q_button start"
                  }
                  onClick={() => {
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="q_button_picto">
                    <HiOutlineSparkles />
                  </div>
                  <div className="q_button_text">CRÉER UNE NOUVELLE CAVE</div>
                </button>
                <Link to="/offrir-cadeau">
                  <button
                    className={
                      currentSlide === 0
                        ? "q_button start animate__animated animate__fadeInDown animate__delay-4s"
                        : "q_button start"
                    }
                  >
                    <div className="q_button_picto">
                      <FiGift />
                    </div>
                    <div className="q_button_text">
                      OFFRIR
                      <br />
                      UNE CAVE
                    </div>
                  </button>
                </Link>
                <Link to="/composer-cadeau">
                  <button
                    className={
                      currentSlide === 0
                        ? "q_button start animate__animated animate__fadeInDown animate__delay-5s"
                        : "q_button start"
                      // : "q_button start invalid"
                    }
                    // disabled
                  >
                    <div className="q_button_picto">
                      <HiOutlineMailOpen />
                    </div>
                    <div className="q_button_text">
                      CRÉER LA CAVE QU'ON M'A OFFERTE
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* ______________________ SLIDE 1 NOM */}
          <div className="q_slide_container">
            <div className="q_slide">
              <div style={{ width: "100%" }}>
                <h5
                  className={
                    currentSlide === 1
                      ? "q_question start animate__animated animate__fadeInDown animate__delay-1s"
                      : "q_question start"
                  }
                >
                  C'est parti, comment tu t'appelles ?
                </h5>
                <input
                  placeholder="Prénom Nom"
                  name="name"
                  value={prenomNom}
                  autoComplete="nope"
                  onChange={(e) => setPrenomNom(e.target.value)}
                  type="input"
                  className={
                    currentSlide === 1
                      ? "form-control-material animate__animated animate__fadeInDown animate__delay-3s"
                      : "form-control-material"
                  }
                />
              </div>
              <AnimatePresence exitBeforeEnter>
                {!isCodeParrainOk && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key="codeparrainNOK"
                    style={{ width: "100%" }}
                  >
                    <h5
                      className={
                        currentSlide === 1
                          ? "q_question animate__animated animate__fadeInDown animate__delay-3s"
                          : "q_question"
                      }
                    >
                      Tu viens de la part de quelqu'un ?
                    </h5>
                    <input
                      placeholder="Code parrain"
                      name="parrain"
                      value={parrainCode}
                      autoComplete="nope"
                      onChange={(e) =>
                        setParrainCode(e.target.value.toUpperCase())
                      }
                      type="input"
                      className={
                        currentSlide === 1
                          ? "form-control-material animate__animated animate__fadeInDown animate__delay-4s"
                          : "form-control-material"
                      }
                    />
                  </motion.div>
                )}
                {isCodeParrainOk && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key="codeparrainOK"
                    style={{ width: "100%" }}
                  >
                    <h5
                      className={
                        currentSlide === 1
                          ? "q_question animate__animated animate__fadeInDown animate__delay-3s"
                          : "q_question"
                      }
                    >
                      10% de réduction grâce à
                    </h5>
                    <input
                      placeholder="Code parrain"
                      disabled
                      name="parrain"
                      value={nameParrain}
                      autoComplete="nope"
                      onChange={(e) =>
                        setParrainCode(e.target.value.toUpperCase())
                      }
                      type="input"
                      className={
                        currentSlide === 1
                          ? "form-control-material animate__animated animate__fadeInDown animate__delay-4s"
                          : "form-control-material"
                      }
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              {isLoading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <img
                    style={{ height: "100px" }}
                    src={preloader}
                    alt="loading"
                  ></img>
                </motion.div>
              )}
              {!isLoading && (
                <button
                  name="prenomNom"
                  className={
                    currentSlide === 1
                      ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                      : "q_button"
                  }
                  style={{
                    visibility: prenomNom === "" ? "hidden" : "visible",
                  }}
                  disabled={prenomNom === ""}
                  onClick={async () => {
                    if (
                      questionnaireTerminé &&
                      (parrainCode === "" || isCodeParrainOk)
                    ) {
                      setConfirmPopup(true);
                    } else {
                      if (parrainCode === "" || isCodeParrainOk) {
                        slider.slickNext();
                        window.scrollTo(0, 0);
                      } else {
                        //check code parrain
                        setIsLoading(true);
                        const response = await axios.post(
                          props.backendurl + "/checkcodeparrain",
                          {
                            parrainCode: parrainCode,
                          }
                        );
                        setIsLoading(false);
                        console.log("RESPONSE CHECK PARRAIN : ", response);
                        if (response.data.message === "CODE OK") {
                          setIsCodeParrainOk(true);
                          setNameParrain(response.data.nameParrain);
                        } else {
                          setIsCodeParrainOk(false);
                          setParrainCode("");
                          setNameParrain("");
                        }
                      }
                    }
                  }}
                >
                  {parrainCode !== "" && !isCodeParrainOk
                    ? "VÉRIFIER"
                    : "SUIVANT"}
                </button>
              )}
            </div>
          </div>
          {/* ______________________ SLIDE 2 NBBOUTEILLES */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 2
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 2
                      ? "q_question prev animate__animated animate__fadeInDown animate__delay-1s"
                      : "q_question prev"
                  }
                >
                  Ok, enchanté{" "}
                  {prenomNom.substr(0, prenomNom.indexOf(" ")) + " "}
                  <span role="img" aria-label="coucou">
                    👋
                  </span>
                  <br />À combien de bouteilles estimes-tu la taille de ta cave
                  ?
                </h5>
              </div>
              <div>
                <div
                  className="nbBouteilleRappel"
                  onMouseDown={() => setLockBudget("nbBouteilles")}
                  onTouchStart={() => setLockBudget("nbBouteilles")}
                >
                  <div
                    className={
                      currentSlide === 2
                        ? "budgetsTitre animate__animated animate__fadeInDown animate__delay-3s"
                        : "budgetsTitre"
                    }
                  >
                    Nombre de bouteilles
                  </div>
                  <div
                    className={
                      props.currentSlide === 2
                        ? "animate__animated animate__fadeInDown animate__delay-1s"
                        : ""
                    }
                  >
                    <SliderPack
                      value={nbBouteilles}
                      setValue={(e) => setNbBouteilles(e)}
                      min={24}
                      max={150}
                      eur=""
                      steps={6}
                    />
                  </div>
                </div>
              </div>
              {/* BOTTLES */}
              <div>
                <img
                  className={
                    nbBouteilles > 24
                      ? "q_bottle_img bottle_1"
                      : "q_bottle_img bottle_1 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 36
                      ? "q_bottle_img bottle_2"
                      : "q_bottle_img bottle_2 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 48
                      ? "q_bottle_img bottle_3"
                      : "q_bottle_img bottle_3 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 60
                      ? "q_bottle_img bottle_4"
                      : "q_bottle_img bottle_4 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 72
                      ? "q_bottle_img bottle_5"
                      : "q_bottle_img bottle_5 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 90
                      ? "q_bottle_img bottle_6"
                      : "q_bottle_img bottle_6 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles > 120
                      ? "q_bottle_img bottle_7"
                      : "q_bottle_img bottle_7 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
                <img
                  className={
                    nbBouteilles >= 150
                      ? "q_bottle_img bottle_8"
                      : "q_bottle_img bottle_8 hidden"
                  }
                  src={bouteille}
                  alt=""
                ></img>
              </div>
              <br />
              <button
                className={
                  currentSlide === 2
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }
                }}
              >
                SUIVANT
              </button>
            </div>
          </div>

          {/* ______________________ SLIDE 3  NATURE ET BIO*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 3
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 3
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Ça marche, c'est noté !
                  <br />
                  On aimerait connaître ta position sur le vin nature :
                </h5>
              </div>
              <br />
              <div
                className={
                  currentSlide === 3
                    ? "bioButtons animate__animated animate__fadeInDown animate__delay-3s"
                    : "bioButtons"
                }
              >
                <button
                  className={
                    nature === "Oui"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Oui");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  J'adore !
                </button>
                <button
                  className={
                    nature === "Curieux"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Curieux");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  Je veux bien goûter
                </button>
                <button
                  className={
                    nature === "Non"
                      ? "q_button choice chosen"
                      : "q_button choice"
                  }
                  onClick={() => {
                    setNature("Non");
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  Très peu pour moi
                </button>
                <button
                  className="q_button infos choice"
                  onClick={() => {
                    setInfoNaturePopup(true);
                  }}
                >
                  C'est quoi ?
                </button>
              </div>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 4 PROPORTION TYPES*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 4
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 4
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  C'est l'heure de nous donner un aperçu de la répartition que
                  tu aimerais avoir dans ta cave idéale !
                </h5>
              </div>
              <div
                className={
                  currentSlide === 4
                    ? "propsetter animate__animated animate__fadeIn animate__delay-4s"
                    : "propsetter"
                }
              >
                <div className="propsetterChartjs">
                  <BrowserView>
                    <Doughnut
                      data={chartdata}
                      width={100}
                      height={50}
                      options={chartoptions}
                      // plugins={ChartDataLabels}
                    />
                  </BrowserView>
                  <MobileView>
                    <Doughnut
                      data={chartdata}
                      width={100}
                      height={50}
                      options={chartoptionsMobile}
                      // plugins={ChartDataLabels}
                    />
                  </MobileView>
                </div>
                <div className="propsetterControler">
                  {/* __________________________________________ PROPSETTER ROUGE */}

                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propRouge <= 0}
                        onClick={() => {
                          setPropRouge(propRouge - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille rouge"></div>
                        <div className="cardTitre">Vins rouges</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropRouge(propRouge + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propRouge}</span> */}
                  </div>

                  {/* __________________________________________ PROPSETTER Blanc */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propBlanc <= 0}
                        onClick={() => {
                          setPropBlanc(propBlanc - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille blanc"></div>
                        <div className="cardTitre">Vins blancs</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropBlanc(propBlanc + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propBlanc}</span> */}
                  </div>
                  {/* __________________________________________ PROPSETTER ROsé */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propRosé <= 0}
                        onClick={() => {
                          setPropRosé(propRosé - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille rosé"></div>
                        <div className="cardTitre">Vins rosés</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropRosé(propRosé + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propRosé}</span> */}
                  </div>
                  {/* __________________________________________ PROPSETTER Champagne */}
                  <div className="propsetterLine">
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        disabled={propChampagne <= 0}
                        onClick={() => {
                          setPropChampagne(propChampagne - 5);
                        }}
                      >
                        -
                      </button>
                    </span>
                    <div className="propsetterCard">
                      <div className="cardHeader">
                        <div className="cardPastille champagne"></div>
                        <div className="cardTitre">Vins effervescents</div>
                      </div>
                    </div>
                    <span className="propsetterButtonContainer">
                      <button
                        className="propsetterButton"
                        onClick={() => {
                          setPropChampagne(propChampagne + 5);
                        }}
                      >
                        +
                      </button>
                    </span>
                    {/* <span>{propChampagne}</span> */}
                  </div>
                </div>
              </div>
              <button
                className={
                  currentSlide === 4
                    ? "q_button animate__animated animate__fadeInDown animate__delay-3s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Suivant
              </button>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 5 PROPORTION GAMMES*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 5
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 5
                      ? "q_question budget animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question budget"
                  }
                >
                  C'est l'heure de définir un budget ! <br />
                  On a fixé le budget minimum à 500€ pour garantir des prix
                  vraiment bas sans sacrifier la qualité. <br />
                  N’hésite pas à te joindre à un ami si besoin pour partager une
                  même cave.
                </h5>
              </div>
              <div
                className={
                  currentSlide === 5
                    ? "propsetter animate__animated animate__fadeIn animate__delay-4s"
                    : "propsetter"
                }
              >
                <div className="propsetterChartjs">
                  <BrowserView>
                    <Doughnut
                      data={chart2data}
                      width={100}
                      height={50}
                      options={chartoptions}
                      // plugins={ChartDataLabels}
                    />
                  </BrowserView>
                  <MobileView>
                    <Doughnut
                      data={chart2data}
                      width={100}
                      height={50}
                      options={chartoptionsMobile}
                      // plugins={ChartDataLabels}
                    />
                  </MobileView>
                </div>
                <div className="propsetterControler">
                  {/* __________________________________________ PROPSETTER EDG */}
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      allCardsGammeOff();
                    }}
                  >
                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("nbBouteilles")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propMDG === 0 && propHDG === 0) || propEDG <= 0
                          }
                          onClick={() => {
                            setPropEDG(propEDG - 5);
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardEDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(false);
                          setCardMDGOpened(false);
                          setCardEDGOpened(!cardEDGOpened);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille EDG"></div>
                          <div className="cardTitre">Entrée de gamme</div>
                          <div
                            className={
                              cardEDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 8€ la bouteille</i>
                            Un copain qui débarque à l'improviste ? Il y a
                            toujours un coup de blanc au frais ou un rouge
                            gourmand pour l’accueillir.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropEDG(propEDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propRosé}</span> */}
                    </div>
                    {/* __________________________________________ PROPSETTER MDG */}
                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("nbBouteilles")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propEDG === 0 && propHDG === 0) || propMDG <= 0
                          }
                          onClick={() => {
                            setPropMDG(propMDG - 5);
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardMDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(false);
                          setCardMDGOpened(!cardMDGOpened);
                          setCardEDGOpened(false);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille MDG"></div>
                          <div className="cardTitre">Milieu de gamme</div>
                          <div
                            className={
                              cardMDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 18€ la bouteille</i>
                            12h30, je viens de finir de cuisiner un risotto aux
                            cèpes du marché. J’ai bien envie de me récompenser
                            et de tirer mon plat vers le haut avec une belle
                            bouteille.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropMDG(propMDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propBlanc}</span> */}
                    </div>
                    {/* __________________________________________ PROPSETTER HDG */}

                    <div
                      className="propsetterLine"
                      onMouseDown={() => setLockBudget("nbBouteilles")}
                    >
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          disabled={
                            (propEDG === 0 && propMDG === 0) || propHDG <= 0
                          }
                          onClick={() => {
                            setPropHDG(propHDG - 5);
                          }}
                        >
                          -
                        </button>
                      </span>
                      <div
                        className={
                          cardHDGOpened
                            ? "propsetterCard opened"
                            : "propsetterCard"
                        }
                        onClick={() => {
                          setCardHDGOpened(!cardHDGOpened);
                          setCardMDGOpened(false);
                          setCardEDGOpened(false);
                        }}
                      >
                        <div className="cardHeader">
                          <div className="cardPastille HDG"></div>
                          <div className="cardTitre">Haut de gamme</div>
                          <div
                            className={
                              cardHDGOpened ? "cardArrow" : "cardArrow rotate"
                            }
                          >
                            <FaAngleDown />
                          </div>
                        </div>
                        <div className="cardContent">
                          <div className="cardContentImg"></div>
                          <div className="cardContentText">
                            <i>Autour de 30€ la bouteille</i>
                            Les beaux-parents viennent déjeuner dimanche pour
                            célébrer les 3 ans du petit Auguste. Il faut sortir
                            le grand jeu pour marquer cet événement de la vie.
                          </div>
                        </div>
                      </div>
                      <span className="propsetterButtonContainer">
                        <button
                          className="propsetterButton"
                          onClick={() => {
                            setPropHDG(propHDG + 5);
                          }}
                        >
                          +
                        </button>
                      </span>
                      {/* <span>{propRouge}</span> */}
                    </div>
                  </OutsideClickHandler>
                  {/* <div className="budgetsTitre">Nombre de bouteilles</div>
                        <button className="q_button nbbottles">
                          {nbBouteilles}
                        </button> */}
                  <div
                    className="nbBouteilleRappel"
                    onMouseDown={() => setLockBudget("nbBouteilles")}
                    onTouchStart={() => setLockBudget("nbBouteilles")}
                  >
                    <div className="budgetsTitre">Nombre de bouteilles</div>
                    <InputSlider
                      className="inputSlider"
                      styles={sliderStyles}
                      axis="x"
                      x={nbBouteilles}
                      xmin={16}
                      xmax={150}
                      onChange={({ x }) => setNbBouteilles(x)}
                    />
                    <span className="labelSlider rappel">{nbBouteilles}</span>
                  </div>
                  <div
                    className="nbBouteilleRappel"
                    onMouseDown={() => setLockBudget("budgetTotal")}
                    onTouchStart={() => setLockBudget("budgetTotal")}
                  >
                    <div className="budgetsTitre">Budget total</div>
                    <InputSlider
                      className="inputSlider"
                      styles={sliderStyles}
                      axis="x"
                      x={budgetTotal}
                      xmin={500}
                      xmax={4000}
                      onChange={({ x }) => setBudgetTotal(x)}
                    />
                    <span className="labelSlider rappel">{budgetTotal} €</span>
                  </div>
                  <div className="nbBouteilleRappel">
                    <div className="budgetsTitre">
                      {reducParrainage
                        ? "[Parrainage -10%] -" +
                          Math.trunc(budgetTotal * 0.1 * 100) / 100 +
                          "€"
                        : "+ Livraison"}
                    </div>
                    <div
                      className={"disabledSliderBlock " + livraisonOfferte}
                    />
                    <span className={"labelDisabledSlider " + livraisonOfferte}>
                      {(() => {
                        if (reducParrainage) {
                          return (
                            Math.trunc(budgetTotal * 0.9 * 100) / 100 + "€"
                          );
                        } else {
                          switch (livraisonOfferte) {
                            case "normal":
                              return "À partir de " + zoneAPrice + "€";
                            case "almost":
                              return "Bientôt offerte !";
                            case "free":
                              return (
                                <>
                                  <del>{zoneAPrice}€</del> OFFERTE
                                </>
                              );
                            default:
                              return "À partir de " + zoneAPrice + "€";
                          }
                        }
                      })()}
                    </span>
                  </div>
                </div>
                <div className="propsetter-footer">
                  <button
                    className={
                      currentSlide === 5
                        ? "q_button animate__animated animate__fadeInDown animate__delay-3s"
                        : "q_button"
                    }
                    onClick={() => {
                      if (questionnaireTerminé) {
                        setConfirmPopup(true);
                      } else {
                        slider.slickNext();
                        window.scrollTo(0, 0);
                      }
                    }}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ______________________ SLIDE 5 GOUT VIN */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 6
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 6
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  On aimerait en savoir plus sur tes goûts.
                  <br />
                  Dans ces deux listes de mots, lesquels te parlent plus que les
                  autres, et lesquels ne te disent rien ?<br />
                </h5>
              </div>
              {(currentSlide === 6 ||
                blancTagsDisliked !== "" ||
                blancTagsLiked !== "" ||
                rougeTagsDisliked !== "" ||
                rougeTagsLiked !== "") && (
                <QuestionnaireTaste
                  data={props.data}
                  setBlancTagsDisliked={(e) => setBlancTagsDisliked(e)}
                  setBlancTagsNormal={(e) => setBlancTagsNormal(e)}
                  setBlancTagsLiked={(e) => setBlancTagsLiked(e)}
                  setRougeTagsDisliked={(e) => setRougeTagsDisliked(e)}
                  setRougeTagsNormal={(e) => setRougeTagsNormal(e)}
                  setRougeTagsLiked={(e) => setRougeTagsLiked(e)}
                  blancTagsDisliked={blancTagsDisliked}
                  blancTagsNormal={blancTagsNormal}
                  blancTagsLiked={blancTagsLiked}
                  rougeTagsDisliked={rougeTagsDisliked}
                  rougeTagsNormal={rougeTagsNormal}
                  rougeTagsLiked={rougeTagsLiked}
                />
              )}
              <div className="bioButtons" style={{ marginTop: "30px" }}>
                <button
                  className={
                    currentSlide === 6
                      ? "q_button choice animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button choice"
                  }
                  onClick={() => {
                    if (questionnaireTerminé) {
                      setConfirmPopup(true);
                    } else {
                      slider.slickNext();
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  Valider
                </button>
              </div>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 6 RÉGIONS*/}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 7
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <div>
                <h5
                  className={
                    currentSlide === 7
                      ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                      : "q_question"
                  }
                >
                  Ok, on va rapidement définir les régions à favoriser dans ta
                  cave, et celles à exclure.
                </h5>
              </div>
              <div
                className={
                  currentSlide === 7
                    ? "regionPickersContainer animate__animated animate__fadeIn animate__delay-3s"
                    : "regionPickersContainer "
                }
              >
                <div className="regionsTitreContainer">
                  <div className="regionsTitreBloc">
                    <div className="regionsTitreBlocHead left">
                      <span className="regionsTitre line1">EXCLURE</span>
                    </div>
                    <div className="regionsTitreBody">
                      <span className="regionsTitre">Blanc</span>
                      <span className="regionsTitre">Rouge</span>
                    </div>
                  </div>
                  <div className="regionsTitreBloc">
                    <div className="regionsTitreBlocHead right">
                      <span className="regionsTitre line1">FAVORISER</span>
                    </div>
                    <div className="regionsTitreBody">
                      <span className="regionsTitre">Blanc</span>
                      <span className="regionsTitre">Rouge</span>
                    </div>
                  </div>
                </div>
                <div className="regionPickers">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      toggleCardsRegionsOff();
                    }}
                  >
                    <RegionsCheckboxes
                      regionsMoinsBlanc={regionsMoinsBlanc}
                      regionsPlusBlanc={regionsPlusBlanc}
                      regionsMoinsRouge={regionsMoinsRouge}
                      regionsPlusRouge={regionsPlusRouge}
                      data={props.data}
                      setRegionsMoinsBlanc={(e) => setRegionsMoinsBlanc(e)}
                      setRegionsPlusBlanc={(e) => setRegionsPlusBlanc(e)}
                      setRegionsMoinsRouge={(e) => setRegionsMoinsRouge(e)}
                      setRegionsPlusRouge={(e) => setRegionsPlusRouge(e)}
                      regionCardOpened={regionCardOpened}
                      setRegionCardOpened={(e) => setRegionCardOpened(e)}
                      nbRegions={nbRegions}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
              <button
                className={
                  currentSlide === 7
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }
                }}
              >
                {regionsPlusBlanc.length > 0 ||
                regionsMoinsBlanc.length > 0 ||
                regionsPlusRouge.length > 0 ||
                regionsMoinsRouge.length > 0
                  ? "Valider"
                  : "Carte blanche"}
              </button>
              <br />
            </div>
          </div>
          {/* ______________________ SLIDE 8 TÉLÉPHONE */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 8
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <h5
                className={
                  currentSlide === 8
                    ? "q_question animate__animated animate__fadeInDown animate__delay-2s"
                    : "q_question"
                }
              >
                Merci pour toutes ces infos.
              </h5>
              <span
                className={
                  currentSlide === 8
                    ? "subquestion animate__animated animate__fadeInDown animate__delay-2s"
                    : "subquestion"
                }
              >
                Pour continuer on a besoin de tes coordonnées.
              </span>
              <input
                placeholder="Ton numéro"
                name="telephone"
                type="input"
                className={
                  currentSlide === 8
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-3s"
                    : "form-control-material"
                }
                onChange={(e) => setTelephone(e.target.value.trim())}
              />
              <br />
              <input
                placeholder="Ton email"
                name="email"
                type="input"
                className={
                  currentSlide === 8
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-4s"
                    : "form-control-material"
                }
                onChange={(e) => setEmail(e.target.value.trim())}
              />
              <br />
              <motion.span
                initial={{ opacity: 0 }}
                animate={{
                  opacity:
                    telephone === "" || !validator.validate(email) ? 0 : 1,
                }}
                className="subquestion"
              >
                À la cave on porte beaucoup d’importance à chaque composition,
                et on pense que ça passe également par une petite discussion de
                vive voix, si tu en ressens le besoin, bien entendu.
                {/* <br />
                Il est également possible d’échanger par mail ou avec le chat
                directement sur le site, c’est Antoine qui est juste derrière. */}
                <br />
                Sinon tu peux aussi payer directement pour que ta cave soit
                expédiée plus vite.
              </motion.span>
              <br />
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity:
                    telephone === "" || !validator.validate(email) ? 0 : 1,
                }}
                className="contactButtonsContainer"
              >
                <button
                  className={
                    currentSlide === 8
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  disabled={telephone === "" || !validator.validate(email)}
                  onClick={() => {
                    setAContacter("tel");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Débrief par téléphone
                </button>
                <button
                  className={
                    currentSlide === 8
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  style={{
                    visibility:
                      telephone === "" || !validator.validate(email)
                        ? "hidden"
                        : "visible",
                  }}
                  disabled={telephone === "" || !validator.validate(email)}
                  onClick={() => {
                    setAContacter("mail");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Débrief par email
                </button>
                <button
                  className={
                    currentSlide === 8
                      ? "q_button animate__animated animate__fadeInDown animate__delay-5s"
                      : "q_button"
                  }
                  style={{
                    visibility:
                      telephone === "" || !validator.validate(email)
                        ? "hidden"
                        : "visible",
                  }}
                  disabled={telephone === "" || !validator.validate(email)}
                  onClick={() => {
                    setAContacter("non");
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }}
                >
                  Pas de débrief
                </button>
              </motion.div>
            </div>
          </div>
          {/* ______________________ SLIDE 8 TÉLÉPHONE */}
          {/* ______________________ SLIDE 9 ADRESSE */}
          <div className="q_slide_container">
            <div className="q_slide">
              <button
                className={
                  currentSlide === 9
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>
              <h5
                className={
                  currentSlide === 9
                    ? "q_question animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_question"
                }
              >
                Et enfin, la livraison !<br />
                Où est-ce qu'on achemine tes précieuses bouteilles ?
                <br />
              </h5>
              {/* <input
                placeholder="Numéro et voie"
                name="adresseVoie"
                type="input"
                className={
                  currentSlide === 9
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-2s"
                    : "form-control-material"
                }
                onChange={(e) => setDataAdresse(e.target.value)}
              />
              <br /> */}
              <div
                className={
                  currentSlide === 9
                    ? "form-address-material animate__animated animate__fadeInDown animate__delay-2s"
                    : "form-address-material "
                }
                autoComplete={false}
              >
                <AlgoliaPlaces
                  style={{
                    padding: "13px",
                    height: "4%",
                    borderRadius: "10px",
                    fontFamily: "Helvetica",
                    color: "black",
                  }}
                  placeholder="Ton adresse de livraison"
                  options={{
                    appId: "plZ1WX40I63E",
                    apiKey: "7cefbbe25b1724a2c4ef55503e2de1cc",
                    language: "fr",
                    countries: ["fr"],
                    type: "address",
                    autoComplete: "off",
                  }}
                  onChange={({
                    query,
                    rawAnswer,
                    suggestion,
                    suggestionIndex,
                  }) => {
                    console.log("suggestion : ", suggestion);
                    setAdresseVoie(suggestion.name);
                    setAdresseCP(suggestion.postcode);
                    setAdresseVille(suggestion.city);
                    setAdresseValue(suggestion.value);
                  }}
                  onClear={() =>
                    // "Fired when the input is cleared.")
                    {
                      setAdresseVoie("");
                      setAdresseCP("");
                      setAdresseVille("");
                      console.log("adresse cleared");
                    }
                  }
                  onLimit={({ message }) => {
                    console.log(message);
                  }}
                />
              </div>
              <div className="livraisonModeContainer">
                <ButtonLivraisonMode
                  currentSlide={currentSlide}
                  // setLivraisonMode={(e) => setLivraisonMode(e)}
                  setLivraisonMode={() => console.log("disabled")}
                  livraisonMode={livraisonMode}
                  value="zoneA"
                  price={livraisonOfferte === "free" ? 0 : zoneAPrice}
                  oldPrice={zoneAPrice + "€"}
                  territoire="Grand Paris"
                />
                <ButtonLivraisonMode
                  currentSlide={currentSlide}
                  // setLivraisonMode={(e) => setLivraisonMode(e)}
                  setLivraisonMode={() => console.log("disabled")}
                  livraisonMode={livraisonMode}
                  value="zoneB"
                  price={livraisonOfferte === "free" ? 0 : zoneBPrice}
                  // price="INDISPO"
                  oldPrice={zoneBPrice + "€"}
                  territoire="Île de France"
                />
                <ButtonLivraisonMode
                  currentSlide={currentSlide}
                  // setLivraisonMode={(e) => setLivraisonMode(e)}
                  setLivraisonMode={() => console.log("disabled")}
                  livraisonMode={livraisonMode}
                  value="zoneC"
                  price={livraisonOfferte === "free" ? 0 : zoneCPrice}
                  // price="INDISPO"
                  oldPrice={zoneCPrice + "€"}
                  territoire="France métro."
                />
              </div>
              <h5
                className={
                  currentSlide === 9
                    ? "q_question animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_question"
                }
              >
                Ici, donne toute indication qui pourrait aider le livreur
                <br />
              </h5>
              <input
                placeholder="Code, bâtiment, étage..."
                type="input"
                autoComplete="nope"
                className={
                  currentSlide === 9
                    ? "form-control-material animate__animated animate__fadeInDown animate__delay-5s"
                    : "form-control-material"
                }
                onChange={(e) => setAdresseNotes(e.target.value)}
              />
              <button
                type="submit"
                name="adresses"
                className={
                  currentSlide === 9
                    ? "q_button animate__animated animate__fadeInDown animate__delay-6s"
                    : "q_button"
                }
                style={{
                  visibility:
                    adresseVoie === "" ||
                    adresseCP === "" ||
                    adresseVille === ""
                      ? "hidden"
                      : "visible",
                }}
                disabled={
                  adresseVoie === "" || adresseCP === "" || adresseVille === ""
                }
                onClick={() => {
                  if (questionnaireTerminé) {
                    setConfirmPopup(true);
                  } else {
                    slider.slickNext();
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Suivant
              </button>
            </div>
          </div>
          {/* ______________________ SLIDE 9 ADRESSE */}
          {/* ______________________ SLIDE 10 LIVRAISON */}
          <div className="q_slide_container">
            <div className="q_slide last_slide">
              <button
                className={
                  currentSlide === 10
                    ? "q_button prev animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button prev"
                }
                onClick={() => {
                  slider.slickPrev();
                }}
              >
                <FaAngleUp />
              </button>

              <h5
                className={
                  currentSlide === 10
                    ? "q_question animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_question"
                }
              >
                Il ne reste plus qu'à choisir un créneau pour la livraison !
                <br />
              </h5>
              <div className="livraisonCreneauContainer">
                <QuestionnaireCreneau
                  currentSlide={currentSlide}
                  componentSlide={10}
                  livraisonMode={livraisonMode}
                  livraisonCreneau={livraisonCreneau}
                  livraisonCreneauTime={livraisonCreneauTime}
                  livraisonCreneauDate={livraisonCreneauDate}
                  setLivraisonCreneauTime={(e) => setLivraisonCreneauTime(e)}
                  setLivraisonCreneauDate={(e) => setLivraisonCreneauDate(e)}
                />
              </div>
              <button
                type="submit"
                name="adresses"
                className={
                  currentSlide === 10
                    ? "q_button animate__animated animate__fadeInDown animate__delay-4s"
                    : "q_button"
                }
                style={{
                  visibility:
                    adresseValue === "" || livraisonCreneau === "Invalid Date"
                      ? "hidden"
                      : "visible",
                }}
                disabled={
                  adresseValue === "" || livraisonCreneau === "Invalid Date"
                }
                onClick={() => {
                  setQuestionnaireTerminé(true);
                  setConfirmPopup(true);
                }}
              >
                ENVOYER
              </button>
            </div>
          </div>
          {/* ______________________ SLIDE 10 LIVRAISON */}
        </Slider>
      </div>
    </div>
  );
}
export default Questionnaire;
