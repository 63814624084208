import "../css/LineRecap.sass";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import React from "react";

function LineRecap(props) {
  return (
    <div className="lineRecap">
      <div className="titre">{props.titre}</div>
      {(() => {
        if (props.disabled) {
          return (
            <div className="valueContainer disabled">
              <div className="modif">
                <FaCheckCircle />
              </div>
              <div className="valeur">{props.value}</div>
              {/* <div className="valeur">{props.value}</div> */}
            </div>
          );
        } else if (props.isInvalid) {
          // LIGNE INVALIDE
          return (
            <div
              className="valueContainer arenseigner"
              onClick={() => {
                props.setConfirmPopup(false);
                props.slider();
              }}
            >
              <div className="modif">
                <FaTimesCircle />
              </div>
              {props.isInvalid && (
                <div className="arenseigner">À renseigner</div>
              )}
              {/* <div className="valeur">{props.value}</div> */}
            </div>
          );
        } else {
          // LIGNE VALIDE
          return (
            <div
              className="valueContainer"
              onClick={() => {
                props.setConfirmPopup(false);
                props.slider();
              }}
            >
              <div className="valid">
                <FaCheckCircle />
              </div>
              {/* {(() => {
                if (props.isInvalid) {
                  return <div className="arenseigner">À renseigner</div>;
                }
              })()} */}
              {(() => {
                if (
                  typeof props.value === "string" &&
                  props.value.includes("\n")
                ) {
                  return (
                    <div className="valeur">
                      {props.value.split("\n").map((str) => (
                        <div>{str}</div>
                      ))}
                    </div>
                  );
                } else {
                  return <div className="valeur">{props.value}</div>;
                }
              })()}
            </div>
          );
        }
      })()}
    </div>
  );
}

export default LineRecap;
