import React from "react";
import "../css/FlippingCard.sass";

function FlippingCard(props) {
  return (
    <div className={"flippingCard " + props.marge}>
      <div className="cardInner">
        <div className="cardFront">
          <div className="leftContainer">
            <img className="leftImg" src={props.image} alt=""></img>
          </div>
          <div className="rightContainer">
            <div className="titreFlipCard">{props.titre}</div>
            <div className="texteFlipCard">{props.texte}</div>
          </div>
        </div>
        <div className="cardBack">
          <div className="titre">{props.titre}</div>
          <div className="backContent">{props.detail}</div>
        </div>
      </div>
    </div>
  );
}

export default FlippingCard;
