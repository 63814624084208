import "rodal/lib/rodal.css";

import LineRecap from "../LineRecap.js";
import React from "react";
import Rodal from "rodal";
import parsePhoneNumber from "libphonenumber-js";
import preloader from "../../img/preloader.gif";

// import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

var validator = require("email-validator");

function PopupRecap(props) {
  const isDataValid = () => {
    //Si un des champs est invalide, mettre en rouge
    if (
      props.prenomNom === "" ||
      props.nature === "" ||
      props.telephone === "" ||
      props.email === "" ||
      props.adresseVoie === "" ||
      props.adresseCP === "" ||
      props.adresseVille === ""
    ) {
      return false;
    } else {
      //Si tout est OK
      return true;
    }
  };

  // function contains(target, pattern) {
  //   var value = 0;
  //   pattern.forEach(function (word) {
  //     value = value + target.includes(word);
  //   });
  //   return value === 1;
  // }

  return (
    <Rodal
      visible={props.visible}
      onClose={() => {
        props.setConfirmPopup(false);
      }}
      className="confirmationPopup"
      closeMaskOnClick={true}
      showCloseButton={true}
    >
      <div className="recap">
        <div className="recapHeader">
          <span className="titre">La Cave des Vendanges d'Antoine</span>
          <span className="sousTitre">~ récapitulatif commande ~</span>
        </div>
        <div className="recapContent">
          <div className="recapLeft">
            <div className="recapCategorie">
              <span className="recapCategorieTitre">Coordonnées</span>
              <LineRecap
                slider={() => props.slider1()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Prénom Nom"
                value={props.prenomNom}
                isInvalid={props.prenomNom === ""}
              />
              <LineRecap
                slider={() => props.slider8()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Téléphone"
                value={props.telephone}
                isInvalid={
                  parsePhoneNumber(props.telephone, "FR") !== undefined &&
                  parsePhoneNumber(props.telephone, "FR") !== ""
                    ? !parsePhoneNumber(props.telephone, "FR").isValid()
                    : true
                }
              />
              <LineRecap
                slider={() => props.slider8()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Email"
                value={props.email}
                isInvalid={!validator.validate(props.email)}
              />
              <LineRecap
                slider={() => props.slider8()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Contact ?"
                value={props.aContacter}
              />
            </div>
            <div className="recapCategorie">
              <span className="recapCategorieTitre">Livraison</span>
              <LineRecap
                slider={() => props.slider9()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Adresse"
                value={props.adresseValue}
                isInvalid={props.adresseValue === ""}
              />
              <LineRecap
                slider={() => props.slider9()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Détails"
                value={props.adresseNotes}
              />
              <LineRecap
                slider={() => props.slider10()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Créneau"
                value={
                  props.livraisonCreneau === undefined
                    ? "À renseigner"
                    : "Le " +
                      props.livraisonCreneau.toLocaleDateString() +
                      " entre " +
                      props.livraisonCreneau.getHours() +
                      "h et " +
                      (props.livraisonCreneau.getHours() + 3) +
                      "h"
                }
                isInvalid={props.livraisonCreneau === undefined}
              />
            </div>
            <div className="recapCategorie">
              <span className="recapCategorieTitre">Cave</span>
              <LineRecap
                slider={() => props.slider2()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Nb de bouteilles"
                value={props.nbBouteilles}
              />
              <LineRecap
                slider={() => props.slider5()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Budget total"
                value={props.budgetTotal + "€"}
              />
              <LineRecap
                slider={() => props.slider5()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Livraison"
                value={props.livraisonPrix + "€"}
              />
            </div>
          </div>
          <div className="recapRight">
            <div className="recapCategorie">
              <span className="recapCategorieTitre">Préférences</span>
              <LineRecap
                slider={() => props.slider3()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Vin nature"
                value={props.nature}
                isInvalid={props.nature === ""}
              />
              <LineRecap
                slider={() => props.slider6()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Vin blanc"
                value={
                  props.blancTagsLiked !== "" || props.blancTagsDisliked !== ""
                    ? "+ : " +
                      props.blancTagsLiked +
                      "\n" +
                      "- : " +
                      props.blancTagsDisliked
                    : "Carte blanche"
                }
              />
              <LineRecap
                slider={() => props.slider6()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Vin rouge"
                value={
                  props.rougeTagsLiked !== "" || props.rougeTagsDisliked !== ""
                    ? "+ : " +
                      props.rougeTagsLiked +
                      "\n" +
                      "- : " +
                      props.rougeTagsDisliked
                    : "Carte blanche"
                }
              />
              <LineRecap
                slider={() => props.slider7()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Régions favorisées - blanc"
                value={
                  props.regionsPlusBlanc.length !== 0
                    ? props.regionsPlusBlanc.map((i) => i + ", ")
                    : "Carte blanche"
                }
              />
              <LineRecap
                slider={() => props.slider7()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Régions exclues - blanc"
                value={
                  props.regionsMoinsBlanc.length !== 0
                    ? props.regionsMoinsBlanc.map((i) => i + ", ")
                    : "Carte blanche"
                }
              />
              <LineRecap
                slider={() => props.slider7()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Régions favorisées - rouge"
                value={
                  props.regionsPlusRouge.length !== 0
                    ? props.regionsPlusRouge.map((i) => i + ", ")
                    : "Carte blanche"
                }
              />
              <LineRecap
                slider={() => props.slider7()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Régions exclues - rouge"
                value={
                  props.regionsMoinsRouge.length !== 0
                    ? props.regionsMoinsRouge.map((i) => i + ", ")
                    : "Carte blanche"
                }
              />
            </div>

            <div
              className="recapCategorie"
              onClick={() => {
                props.setConfirmPopup(false);
                props.slider4();
              }}
            >
              <span className="recapCategorieTitre">Répartitions</span>
              <LineRecap
                slider={() => props.slider4()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Types de vins"
                value={
                  Math.floor(
                    (props.propRouge /
                      (props.propRouge +
                        props.propBlanc +
                        props.propRosé +
                        props.propChampagne)) *
                      100
                  ) +
                  "% Rouges \n" +
                  Math.floor(
                    (props.propRosé /
                      (props.propRouge +
                        props.propBlanc +
                        props.propRosé +
                        props.propChampagne)) *
                      100
                  ) +
                  "%  Rosés \n" +
                  Math.floor(
                    (props.propBlanc /
                      (props.propRouge +
                        props.propBlanc +
                        props.propRosé +
                        props.propChampagne)) *
                      100
                  ) +
                  "%  Blancs \n" +
                  Math.floor(
                    (props.propChampagne /
                      (props.propRouge +
                        props.propBlanc +
                        props.propRosé +
                        props.propChampagne)) *
                      100
                  ) +
                  "% Effervescents"
                }
              />
            </div>
            <div
              className="recapCategorie"
              onClick={() => {
                props.setConfirmPopup(false);
                props.slider5();
              }}
            >
              <LineRecap
                slider={() => props.slider5()}
                setConfirmPopup={() => {
                  props.setConfirmPopup();
                }}
                titre="Gammes"
                value={
                  Math.floor(
                    (props.propEDG /
                      (props.propHDG + props.propMDG + props.propEDG)) *
                      100
                  ) +
                  "% Entrée \n" +
                  Math.floor(
                    (props.propMDG /
                      (props.propHDG + props.propMDG + props.propEDG)) *
                      100
                  ) +
                  "% Moyen \n" +
                  Math.floor(
                    (props.propHDG /
                      (props.propHDG + props.propMDG + props.propEDG)) *
                      100
                  ) +
                  "% Haut"
                }
              />
            </div>
          </div>
        </div>
        <div className="recapBottom">
          {props.isLoading ? (
            <div className="popupRecapLoadingContainer">
              <img
                style={{ height: "100%" }}
                src={preloader}
                alt="loading"
              ></img>
            </div>
          ) : (
            <button
              className={isDataValid() ? "q_button" : "q_button invalid"}
              type="submit"
              disabled={!isDataValid()}
              onClick={async () => {
                await props.handleSend();
              }}
            >
              ENVOYER
            </button>
          )}
        </div>
      </div>
    </Rodal>
  );
}

export default PopupRecap;
