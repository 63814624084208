import "../css/Card.sass";

import { Link } from "react-router-dom";
import React from "react";
import pince from "../img/pince.png";

function Card(props) {
  return (
    <div
      className="cardContainer"
      style={{ width: props.width, height: props.height }}
    >
      <div className="cardPince">
        <img src={pince} alt=""></img>
      </div>
      <div className="cardTopPart">
        <img src={props.imageSrc} className="cardImage" alt="" />
      </div>
      <div className="cardTextOnly">
        <h1 className="cardTextBlocTitre">{props.titre}</h1>
        <h2 className="cardTextBlocTexte">{props.texte}</h2>
        <div className="buttonsContainer">
          <Link
            className="buttonContainer"
            to="/blog/qui-sommes-nous"
            style={{ textDecoration: "none", height: "40px" }}
          >
            <button className="a_button">
              NOTRE
              <br />
              HISTOIRE
            </button>
          </Link>
          <Link
            className="buttonContainer"
            to="/composer"
            style={{ textDecoration: "none", height: "40px" }}
          >
            <button className="a_button">
              JE CRÉE
              <br /> MA CAVE
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Card;
