import "../css/Blog.sass";

import React, { useEffect, useState } from "react";

import Footer from "../components/Footer.js";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import PopupNewsletter from "../components/questionnaire/PopupNewsletter.js";
import moment from "moment";

function Blog(props) {
  const [categorie, setCategorie] = useState("");
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
  const [popupNewsletter, setPopupNewsletter] = useState(false);

  console.log("DATA BLOG : ", props.data);

  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}

  const categoriesFilter = props.data.items[0].fields.categoriesActives.fields.categories.map(
    (catContentful) => {
      return (
        <div
          className={
            categorie === catContentful
              ? "blogCategorieButton selected"
              : "blogCategorieButton"
          }
          onClick={() => {
            if (categorie === catContentful) {
              setCategorie("");
            } else {
              setCategorie(catContentful);
            }
          }}
        >
          {catContentful}
        </div>
      );
    }
  );

  useEffect(() => {
    const listPublishedBlogArticles = props.data.items[0].fields.blogPosts.filter(
      (post) => post.fields !== undefined
    );

    console.log("----------");
    console.log("CATEGO : ", categorie);
    console.log(props.data.items[0].fields.blogPosts);

    if (categorie === "") {
      console.log("!CATEGORY");
      var filteredItems = listPublishedBlogArticles;
    } else if (categorie !== "") {
      console.log("CATEGORY");
      filteredItems = listPublishedBlogArticles.filter((itemShop) => {
        return itemShop.fields.categories.includes(categorie);
      });
    }
    // console.log("filteredBlogPosts : ", filteredBlogPosts);

    const listBlogArticles = filteredItems.map((post) => {
      var date = moment(post.fields.publishDate);
      const categoriesPost = post.fields.categories.map(
        (categorieContentful) => {
          return (
            <div
              className={"categorie color" + categorieContentful}
              onClick={(e) => {
                if (categorieContentful === categorie) {
                  setCategorie("");
                } else {
                  setCategorie(categorieContentful);
                }
              }}
            >
              {"#" + categorieContentful.toLowerCase()}
            </div>
          );
        }
      );
      return (
        <>
          <div className="articleBloc">
            <Link
              to={"/blog/" + post.fields.slug}
              style={{ textDecoration: "none" }}
            >
              <h2 className="titre">{post.fields.title}</h2>
            </Link>
            <div className="subtitre">
              <div className="date">{date.format("DD/MM/YY")}</div>
              <div className="categoriesContainer">{categoriesPost}</div>
            </div>
            <Link
              to={"/blog/" + post.fields.slug}
              style={{ textDecoration: "none" }}
            >
              <div className="imageContainer">
                <img
                  className="image"
                  src={post.fields.heroImage.fields.file.url}
                  alt="blog post"
                />
              </div>
              <div className="apercu">{post.fields.description}</div>
            </Link>
          </div>
          <hr class="solid" />
        </>
      );
    });

    setFilteredBlogPosts(listBlogArticles);
  }, [categorie, props.data.items]);

  moment.locale("fr");

  // const categoriesButtons = props.data.

  return (
    <div className="blogContainer">
      <Navbar logo={props.logo} page="blog" />
      <PopupNewsletter
        visible={popupNewsletter}
        toggleStateNewsletter={() => {
          setPopupNewsletter(!popupNewsletter);
        }}
      />
      <div className="titreContainer">
        <h1 className="titreBlog">S.O.I.F</h1>
        <h1 className="soustitreBlog">LE BLOG</h1>
      </div>
      <div
        className="ParallaxContainer"
        style={{
          backgroundImage:
            "url(" +
            props.data.items[0].fields.banniere.fields.image.fields.file.url +
            ")",
        }}
      ></div>
      <div className="blogFilters">
        <div className="blogCategoriesFilter">{categoriesFilter}</div>
      </div>
      <div className="blogContent">
        <div className="blogFeatured"></div>
        <div className="blogArticles">{filteredBlogPosts}</div>
      </div>
      <Footer
        toggleStateNewsletter={() => {
          setPopupNewsletter({ newsletterPopup: !popupNewsletter });
        }}
      />
    </div>
  );
}

export default Blog;
