import "../css/Questionnaire.sass";
import "../css/Cadeau1Questions.sass";
import "animate.css";

import React, { useState } from "react";

// import CadeauCard from "../components/cadeau/CadeauCard.js";
// import CadeauPaiement from "../components/cadeau/CadeauPaiement.js";
// import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import SliderPack from "../components/SliderPack";
import axios from "axios";
import { useHistory } from "react-router-dom";

var validator = require("email-validator");

function QuestionnaireOffrir(props) {
  // const [customerId, setCustomerId] = useState("");

  const [emailOffreur, setEmailOffreur] = useState("");
  const [budgetTotal, setBudgetTotal] = useState(500);
  const [email, setEmail] = useState("");
  const [prenomNom, setPrenomNom] = useState("");
  const [cadeauMot, setCadeauMot] = useState("");
  // const [codeCadeau, setCodeCadeau] = useState("");
  const history = useHistory();
  const [prenomNomValid, setPrenomNomValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailOffreurValid, setEmailOffreurValid] = useState(false);
  const [cadeauMotValid, setCadeauMotValid] = useState(true);

  // Créer cadeau non payé non répondu en base
  // Lancer paiement
  // Si réussite : update cadeau non payé en cadeau payé
  // Si échec paiement : supprimer cadeau non payé
  // TODO BACK : /createunpaidgift
  // TODO BACK : /updatepaidgift

  //GÉNÉRER CODE À 5 CHIFFRES
  function generateCodeCadeau() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * 5));
    }
    return result;
  }

  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}
  return (
    <div className="question1_container">
      <Navbar logo={props.logo} page="questionnaire" />
      <div className="cadeauInfosBloc">
        <h4 className="titreCadeau1Question">Le montant du cadeau</h4>
        <div
          style={{ height: "100px" }}
          className="animate__animated animate__fadeInDown animate__delay-1s"
        >
          <SliderPack
            value={budgetTotal}
            setValue={(e) => setBudgetTotal(e)}
            min={500}
            max={3000}
            eur="€"
            steps={10}
          />
        </div>
        <input
          placeholder="Ton email"
          name="email"
          value={emailOffreur}
          type="input"
          className={
            emailOffreurValid
              ? "question1_input animate__animated animate__fadeInDown animate__delay-2s"
              : "question1_input invalid animate__animated animate__fadeInDown animate__delay-2s"
          }
          onChange={(e) => {
            setEmailOffreur(e.target.value);
            if (validator.validate(e.target.value)) {
              setEmailOffreurValid(true);
            } else {
              setEmailOffreurValid(false);
              console.log("mauvais email offreur");
            }
          }}
        />
      </div>
      <div className="cadeauInfosBloc">
        <h4 className="titreCadeau1Question">Ses infos</h4>
        <input
          placeholder="Son prénom et nom"
          value={prenomNom}
          autoComplete="nope"
          type="input"
          className={
            prenomNomValid
              ? "question1_input animate__animated animate__fadeInDown animate__delay-3s"
              : "question1_input invalid animate__animated animate__fadeInDown animate__delay-3s"
          }
          onChange={(e) => {
            setPrenomNom(e.target.value);
            if (prenomNom !== undefined) {
              setPrenomNomValid(true);
            } else {
              setPrenomNomValid(false);
              console.log("mauvais prenom nom");
            }
          }}
        />
        <input
          placeholder="Son email"
          value={email}
          autoComplete="nope"
          type="input"
          className={
            emailValid
              ? "question1_input animate__animated animate__fadeInDown animate__delay-4s"
              : "question1_input invalid animate__animated animate__fadeInDown animate__delay-4s"
          }
          onChange={(e) => {
            setEmail(e.target.value);
            if (validator.validate(e.target.value)) {
              setEmailValid(true);
            } else {
              setEmailValid(false);
              console.log("mauvais email receveur");
            }
          }}
        />
      </div>
      <div className="cadeauInfosBloc blocBottom">
        <h4 className="titreCadeau1Question">Un petit mot sur la carte ?</h4>
        <textarea
          placeholder="80 charactères maximum"
          value={cadeauMot}
          autoComplete="nope"
          onChange={(e) => {
            setCadeauMot(e.target.value);
            if (cadeauMot !== undefined && cadeauMot.length < 80) {
              setCadeauMotValid(true);
            } else {
              setCadeauMotValid(false);
              console.log("Mot doit être < 80 chars");
            }
          }}
          type="input"
          className={
            cadeauMotValid
              ? "question1_input textareagift animate__animated animate__fadeInDown animate__delay-5s"
              : "question1_input textareagift invalid animate__animated animate__fadeInDown animate__delay-5s"
          }
          cols="40"
          rows="2"
        ></textarea>
        <button
          className={
            prenomNomValid && emailValid && emailOffreurValid
              ? "q_button cadeauButton animate__animated animate__fadeInDown animate__delay-5s"
              : "q_button cadeauButton invalid animate__animated animate__fadeInDown animate__delay-5s"
          }
          disabled={!prenomNomValid || !emailValid || !emailOffreurValid}
          onClick={async () => {
            const newCodeCadeau = generateCodeCadeau();
            // setCodeCadeau(newCodeCadeau);
            console.log("Création cadeau non payé : ", newCodeCadeau);
            console.log("Cadeau Mot : ", cadeauMot);
            const response = await axios.post(
              props.backendurl + "/createunpaidgift",
              {
                prenomNom: prenomNom,
                budgetTotal: budgetTotal,
                email: email,
                emailOffreur: emailOffreur,
                cadeauMot: cadeauMot,
                codeCadeau: newCodeCadeau,
              }
            );
            console.log(response);
            // UNPAID GIFT CRÉÉ EN BDD => GO PAIEMENT
            if (
              response.data.message === "unpaidgift created" ||
              response.data.message === "unpaidgift updated"
            ) {
              console.log("ID : ", response.data.id);
              var newCustomerId = response.data.id;
              axios.post(props.backendurl + "/sendlink", {
                // customerId: customerAirTableID,
                customerId: newCustomerId,
              });
              // slideToSlide(1);
              history.push("/paiement?id=" + newCustomerId);
              //SAVE RÉPONSES IMPOSSIBLE, COMMANDE DÉJÀ PAYÉE
            } else if (response.data.message === "erreur") {
              console.log("ERREUR BACKEND");
            }
          }}
        >
          GÉNÉRER LA CARTE CADEAU
        </button>
      </div>
    </div>
  );
}
export default QuestionnaireOffrir;
