import "./index.css";

import * as serviceWorker from "./serviceWorker";

import { ContentfulClient, ContentfulProvider } from "react-contentful";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51Hi5rRBwcMvkCnEEEAJSRtnxSw8jGuSFJtFHCHTSDtbM1uJeYD7drT8SlTprSjXYNobEGJ75F8PB49SdLt8PU9a100Gkf1QZBF"
  // "pk_test_51Hi5rRBwcMvkCnEEl9Qud9uN72dCWg15qtJjtTxGkgFeKmp4U5QzDf974nx08lSCqVhhDnrK25bBKnHEUdwjzlc4009TsyEeWl"
);

var backendurl = "https://lacave-back.herokuapp.com";
// var backendurl = "http://localhost:3100";

const contentfulClient = new ContentfulClient({
  accessToken: "_Dg44avSaFH_aB0jT8Wilid6WXATK_qJNlM3klBXS9s",
  space: "guch2e4karc9",
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <ContentfulProvider client={contentfulClient}>
          <App backendurl={backendurl} />
        </ContentfulProvider>
      </Elements>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
