import "../css/Faq.sass";

import React, { useEffect, useState } from "react";

import Footer from "../components/Footer.js";
// import { Link } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import ReactMarkdown from "react-markdown/with-html";

// import { FaAngleRight } from "react-icons/fa";

function Faq(props) {
  //State tableau, une entrée par Question
  const [questionOpened, setQuestionOpened] = useState([]);

  //Tableau tampon, vide
  useEffect(() => {
    var stateArray = [];
    for (let i = 0; i < props.data.question.length; i++) {
      stateArray.push(false);
    }
    setQuestionOpened(stateArray);
  }, [props.data]);

  console.log("FAQ QUERY DATA : ", props.data);

  //Au clic sur un événement :
  const toggleQuestionOpened = (index) => {
    //vider le tableau tampon
    var stateArray = [];
    for (let i = 0; i < props.data.question.length; i++) {
      //Quand on arrive à la ligne de la question cliquée
      if (i === index) {
        //Toggle l'ouverture
        stateArray.push(!questionOpened[index]);
      } else {
        //Fermer les autres
        stateArray.push(false);
      }
    }
    setQuestionOpened(stateArray);
  };

  const listQuestions = props.data.question.map((question, index) => {
    return (
      <div
        key={index}
        className={
          questionOpened[index] ? "questioncell opened" : "questioncell"
        }
        onClick={() => {
          toggleQuestionOpened(index);
        }}
      >
        <div className="questionTitreContainer">
          <div className="questionTitre">
            {index + 1} - {question.fields.blocTitre}
          </div>
        </div>

        <div className="questionReponse">
          <ReactMarkdown
            source={question.fields.blocTexte}
            escapeHtml={false}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="faqContainer">
      <Navbar logo={props.logo} page="blog" />
      <div className="titreContainer">
        <div className="titreFaq">Questions féquemment posées</div>
        {/* <div className="soustitreFaq">& Conditions générales de vente</div> */}
        {/* <div className="soustitreBlog">LE BLOG</div> */}
      </div>
      <div
        className="ParallaxContainer"
        style={{
          backgroundImage:
            "url(" + props.data.banniereFaq.fields.file.url + ")",
        }}
      ></div>
      <div className="faqContent">
        <div className="faqQuestions">
          {/* <div className="faqTitle">Questions féquemment posées</div> */}
          {listQuestions}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
