import "../../css/QuestionnaireCreneau.sass";

import React, { useEffect } from "react";

import DayPicker from "react-day-picker";
import SliderPackCreneau from "../SliderPackCreneau";

// import { motion, useTransform, useViewportScroll } from "framer-motion";

function QuestionnaireCreneau(props) {
  const {
    livraisonMode,
    livraisonCreneauTime,
    livraisonCreneauDate,
    setLivraisonCreneauTime,
    setLivraisonCreneauDate,
    currentSlide,
    componentSlide,
  } = props;

  var shipNotBeforeDay = new Date();
  var today = new Date();
  // Si aujourd'hui === jeudi vendredi ou samedi, not before mercredi d'après
  if (today.getDay() >= 4) {
    shipNotBeforeDay.setDate(
      shipNotBeforeDay.getDate() + (10 - today.getDay())
    );
  } else {
    shipNotBeforeDay.setDate(shipNotBeforeDay.getDate() + 3);
  }
  // console.log("Aujourd'hui : ", today.getDay());
  // console.log("10 - aujourd'hui.getDay : ", 10 - today.getDay());
  // console.log("shipNotBeforeDay.getDay() : ", shipNotBeforeDay.getDay());

  useEffect(() => {
    if (
      livraisonCreneauDate &&
      livraisonCreneauDate.getDay() === 6 &&
      livraisonMode === "zoneC"
    ) {
      setLivraisonCreneauTime(9);
    }
  }, [livraisonCreneauDate, setLivraisonCreneauTime, livraisonMode]);

  return (
    <>
      <div
        className={
          currentSlide === componentSlide
            ? "livraisonCreneauBloc animate__animated animate__fadeInDown animate__delay-4s"
            : "livraisonCreneauBloc "
        }
      >
        <DayPicker
          className={
            currentSlide === componentSlide
              ? "input-creneau animate__animated animate__fadeInDown animate__delay-5s"
              : "input-creneau"
          }
          disabledDays={[
            {
              daysOfWeek: [0],
            },
            // {
            //   before: new Date(2020, 11, 7),
            // },
            {
              before: shipNotBeforeDay,
            },
          ]}
          selectedDays={livraisonCreneauDate}
          onDayClick={(day, modifiers = {}) => {
            if (modifiers.disabled) {
              return;
            }
            console.log("day : ", day.getDay());
            setLivraisonCreneauDate(modifiers.selected ? undefined : day);
          }}
          // locale={locale}
          // months={MONTHS[locale]}
          // weekdaysLong={WEEKDAYS_LONG[locale]}
          weekdaysShort={["Di", "Lu", "Ma", "Mer", "Je", "Ve", "Sa"]}
          months={[
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
          ]}
          firstDayOfWeek={1}
          // labels={LABELS[locale]}
          // (e) => setLivraisonCreneauDate(e.target.value)}
        />
      </div>
      <div
        className={
          currentSlide === componentSlide
            ? "livraisonCreneauBloc animate__animated animate__fadeInDown animate__delay-1s"
            : "livraisonCreneauBloc"
        }
      >
        {(livraisonMode === "zoneA" || livraisonMode === "zoneB") && (
          <SliderPackCreneau
            value={livraisonCreneauTime}
            setValue={(e) => setLivraisonCreneauTime(e)}
            min={7}
            max={18}
            unit="h"
            steps={1}
            currentSlide={currentSlide}
            slide={10}
          />
        )}
        {livraisonMode === "zoneC" && livraisonCreneauDate && (
          <>
            <button
              className={
                currentSlide === componentSlide
                  ? livraisonCreneauTime === 9
                    ? "q_button chosen animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button animate__animated animate__fadeInDown animate__delay-1s"
                  : "q_button"
              }
              onClick={() => {
                setLivraisonCreneauTime(9);
              }}
            >
              De 8h30 à 12h30
            </button>
            {livraisonCreneauDate.getDay() !== 6 && (
              <button
                className={
                  currentSlide === componentSlide
                    ? livraisonCreneauTime === 14
                      ? "q_button chosen animate__animated animate__fadeInDown animate__delay-1s"
                      : "q_button animate__animated animate__fadeInDown animate__delay-1s"
                    : "q_button"
                }
                onClick={() => {
                  setLivraisonCreneauTime(14);
                }}
              >
                De 13h30 à 18h
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default QuestionnaireCreneau;
