import React from "react";
import { FaAngleDown } from "react-icons/fa";

function RegionsCheckboxes(props) {
  const {
    regionsMoinsBlanc,
    regionsPlusBlanc,
    regionsMoinsRouge,
    regionsPlusRouge,
    data,
    setRegionsPlusBlanc,
    setRegionsMoinsBlanc,
    setRegionsPlusRouge,
    setRegionsMoinsRouge,
    regionCardOpened,
    setRegionCardOpened,
    nbRegions,
  } = props;

  const toggleCardsRegionsOff = (index) => {
    var stateArray = [];
    for (let i = 0; i < nbRegions; i++) {
      //Quand on arrive à la ligne de l'événement cliqué
      if (i === index) {
        //Toggle l'ouverture
        stateArray.push(!regionCardOpened[index]);
      } else {
        //Fermer les autres
        stateArray.push(false);
      }
    }
    setRegionCardOpened(stateArray);
  };

  const regionsList = data.items[0].fields.regions.map((region, index) => {
    return (
      <span className="propsetterLine regions" key={index}>
        {/* --------------------------- CHECKBOX - BLANC */}
        <span className="propsetterButtonContainer">
          <input
            id={"cbx" + index}
            className="propsetterButton"
            type="checkbox"
            name="regionsMoins"
            checked={regionsMoinsBlanc.includes(region.fields.titre)}
          />
          <label
            className={
              (!regionsMoinsBlanc.includes(region.fields.titre) &&
                regionsMoinsBlanc.length >= 7) ||
              regionsPlusBlanc.includes(region.fields.titre)
                ? "cbx blanc disabled"
                : "cbx blanc"
            }
            for="cbx"
            onClick={() => {
              if (
                !(
                  (!regionsMoinsBlanc.includes(region.fields.titre) &&
                    regionsMoinsBlanc.length >= 7) ||
                  regionsPlusBlanc.includes(region.fields.titre)
                )
              ) {
                if (regionsMoinsBlanc.includes(region.fields.titre)) {
                  const nextValue = regionsMoinsBlanc.filter(
                    (value) => value !== region.fields.titre
                  );
                  setRegionsMoinsBlanc(nextValue);
                } else {
                  const nextValue = regionsMoinsBlanc.concat(
                    region.fields.titre
                  );
                  setRegionsMoinsBlanc(nextValue);
                }
              }
            }}
          >
            <div className="flip">
              <div className="front"></div>
              <div className="back checkBlanc">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </label>
        </span>
        {/* --------------------------- CHECKBOX - BLANC */}
        {/* --------------------------- CHECKBOX - ROUGE */}
        <span className="propsetterButtonContainer">
          <input
            id={"cbx" + index}
            className="propsetterButton"
            type="checkbox"
            name="regionsMoins"
            checked={regionsMoinsRouge.includes(region.fields.titre)}
          />
          <label
            className={
              (!regionsMoinsRouge.includes(region.fields.titre) &&
                regionsMoinsRouge.length >= 7) ||
              regionsPlusRouge.includes(region.fields.titre)
                ? "cbx rouge disabled"
                : "cbx rouge"
            }
            for="cbx"
            onClick={() => {
              if (
                !(
                  (!regionsMoinsRouge.includes(region.fields.titre) &&
                    regionsMoinsRouge.length >= 7) ||
                  regionsPlusRouge.includes(region.fields.titre)
                )
              ) {
                if (regionsMoinsRouge.includes(region.fields.titre)) {
                  const nextValue = regionsMoinsRouge.filter(
                    (value) => value !== region.fields.titre
                  );
                  setRegionsMoinsRouge(nextValue);
                } else {
                  const nextValue = regionsMoinsRouge.concat(
                    region.fields.titre
                  );
                  setRegionsMoinsRouge(nextValue);
                }
              }
            }}
          >
            <div className="flip">
              <div className="front"></div>
              <div className="back checkRouge">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </label>
        </span>
        {/* --------------------------- CHECKBOX - ROUGE */}
        {/* --------------------------- CARD REGION */}
        <div
          className={
            regionCardOpened[index] ? "propsetterCard opened" : "propsetterCard"
          }
          onClick={() => {
            toggleCardsRegionsOff(index);
          }}
        >
          <div className="cardHeader">
            <div className="cardTitre">{region.fields.titre}</div>
            <div
              className={
                regionCardOpened[index] ? "cardArrow" : "cardArrow rotate"
              }
            >
              <FaAngleDown />
            </div>
          </div>
          <div className="cardContent">
            <div className="cardContentImg"></div>
            <div className="cardContentText">{region.fields.description}</div>
          </div>
        </div>
        {/* --------------------------- CARD REGION */}
        {/* --------------------------- CHECKBOX + BLANC */}
        <span className="propsetterButtonContainer">
          <input
            id={"cbx" + index}
            className="propsetterButton"
            type="checkbox"
            name="regionsPlusBlanc"
            checked={regionsPlusBlanc.includes(region.fields.titre)}
          />
          <label
            className={
              (!regionsPlusBlanc.includes(region.fields.titre) &&
                regionsPlusBlanc.length >= 7) ||
              regionsMoinsBlanc.includes(region.fields.titre)
                ? "cbx blanc disabled"
                : "cbx blanc"
            }
            for="cbx"
            onClick={() => {
              if (
                !(
                  (!regionsPlusBlanc.includes(region.fields.titre) &&
                    regionsPlusBlanc.length >= 7) ||
                  regionsMoinsBlanc.includes(region.fields.titre)
                )
              ) {
                if (regionsPlusBlanc.includes(region.fields.titre)) {
                  const nextValue = regionsPlusBlanc.filter(
                    (value) => value !== region.fields.titre
                  );
                  setRegionsPlusBlanc(nextValue);
                } else {
                  const nextValue = regionsPlusBlanc.concat(
                    region.fields.titre
                  );
                  setRegionsPlusBlanc(nextValue);
                }
              }
            }}
          >
            <div className="flip">
              <div className="front"></div>
              <div className="back checkBlanc">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </label>
        </span>
        {/* --------------------------- CHECKBOX + BLANC */}
        {/* --------------------------- CHECKBOX + ROUGE */}
        <span className="propsetterButtonContainer">
          <input
            id={"cbx" + index}
            className="propsetterButton"
            type="checkbox"
            name="regionsPlusRouge"
            checked={regionsPlusRouge.includes(region.fields.titre)}
          />
          <label
            className={
              (!regionsPlusRouge.includes(region.fields.titre) &&
                regionsPlusRouge.length >= 7) ||
              regionsMoinsRouge.includes(region.fields.titre)
                ? "cbx rouge disabled"
                : "cbx rouge"
            }
            for="cbx"
            onClick={() => {
              if (
                !(
                  (!regionsPlusRouge.includes(region.fields.titre) &&
                    regionsPlusRouge.length >= 7) ||
                  regionsMoinsRouge.includes(region.fields.titre)
                )
              ) {
                if (regionsPlusRouge.includes(region.fields.titre)) {
                  const nextValue = regionsPlusRouge.filter(
                    (value) => value !== region.fields.titre
                  );
                  setRegionsPlusRouge(nextValue);
                } else {
                  const nextValue = regionsPlusRouge.concat(
                    region.fields.titre
                  );
                  setRegionsPlusRouge(nextValue);
                }
              }
            }}
          >
            <div className="flip">
              <div className="front"></div>
              <div className="back checkRouge">
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </label>
        </span>
        {/* --------------------------- CHECKBOX + ROUGE */}
      </span>
    );
  });
  return regionsList;
}

export default RegionsCheckboxes;
