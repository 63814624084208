import "./App.css";

// import { motion } from "framer-motion";
import { Helmet, HelmetProvider } from "react-helmet-async";
//Router
import { Route, Switch, useLocation } from "react-router-dom";

import BlogArticle from "./pages/BlogArticle.js";
import NotFound from "./pages/NotFound";
import Paiement from "./pages/Paiement.js";
import QueriesAccueil from "./queries/QueriesAccueil.js";
import QueriesBlog from "./queries/QueriesBlog.js";
import QueriesCgv from "./queries/QueriesCgv.js";
import QueriesContact from "./queries/QueriesContact.js";
import QueriesFaq from "./queries/QueriesFaq.js";
import QueriesQuestionnaire from "./queries/QueriesQuestionnaire.js";
import QueriesQuestionnaireOffert from "./queries/QueriesQuestionnaireOffert.js";
import QuestionnaireOffrir from "./pages/QuestionnaireOffrir";
import React from "react";

function App(props) {
  const location = useLocation();

  let vhmobileheight = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
    "--vhmobileheight",
    `${vhmobileheight}px`
  );
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vhmobileheight = window.innerHeight * 0.01;
    // console.log("vhmobileheight : ", vhmobileheight);
    document.documentElement.style.setProperty(
      "--vhmobileheight",
      `${vhmobileheight}px`
    );
  });

  var backendurl = props.backendurl;
  const logo = process.env.PUBLIC_URL + "/LOGO_ORANGE.svg";
  const socialBanner =
    "https://lacavedesvendangesdantoine.com/BANNIERE_SOCIALS.jpg";

  console.log("socialBanner : ", socialBanner);

  const setMetaTags = ({ title, description, url = "", imageUrl }) => (
    <HelmetProvider>
      <Helmet>
        <title>{title ? `${title}` : ""}</title>
        {/* Description : 150-160 caractères, unique par page */}
        <meta name="description" content={description} />

        <meta
          property="og:url"
          content={"https://lacavedesvendangesdantoine.com/" + url}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <meta property="og:description" content={description} />
        <meta
          property="og:site_name"
          content="La Cave des Vendanges d'Antoine"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@lesvendangesdantoine" />
        <meta
          name="twitter:url"
          content={"https://lacavedesvendangesdantoine.com/" + url}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
    </HelmetProvider>
  );

  const Routes = [
    {
      path: "/",
      title: "La Cave des Vendanges d'Antoine",
      component: <QueriesAccueil logo={logo} backendurl={backendurl} />,
      exact: true,
    },
    {
      path: "/composer",
      title: "Composer - La Cave",
      component: <QueriesQuestionnaire logo={logo} backendurl={backendurl} />,
      exact: true,
    },
    {
      path: "/offrir-cadeau",
      title: "Offrir - La Cave",
      component: <QuestionnaireOffrir logo={logo} backendurl={backendurl} />,
      exact: true,
    },
    {
      path: "/composer-cadeau",
      title: "Composer cadeau - La Cave",
      component: (
        <QueriesQuestionnaireOffert logo={logo} backendurl={backendurl} />
      ),
      exact: true,
    },
    {
      path: "/blog",
      title: "S.O.I.F Le Blog - La Cave",
      component: <QueriesBlog logo={logo} />,
      exact: true,
    },
    {
      path: "/offrir-cadeau",
      title: "Offrir - La Cave",
      component: <QuestionnaireOffrir logo={logo} backendurl={backendurl} />,
      exact: true,
    },
    {
      path: "/faq",
      title: "FAQ - La Cave",
      component: <QueriesFaq logo={logo} props={props} />,
      exact: true,
    },
    {
      path: "/conditions-generales-de-vente",
      title: "CGV - La Cave",
      component: <QueriesCgv logo={logo} props={props} />,
      exact: true,
    },
    {
      path: "/contact",
      title: "Contact - La Cave",
      component: <QueriesContact logo={logo} props={props} />,
      exact: true,
    },
  ];

  //_________________________________________ RETURN
  return (
    <div className="App">
      {/* <AnimatePresence exitBeforeEnter> */}
      <Switch location={location} key={location.pathname}>
        {Routes.map((route) => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => {
              return (
                <div>
                  {setMetaTags({
                    title: route.title,
                    description: "Ta cave à vin, sur mesure.",
                    imageUrl: socialBanner,
                  })}
                  {route.component}
                </div>
              );
            }}
            // component={route.component}
          />
        ))}
        <Route
          exact
          path="/paiement"
          render={({ location, props, match }) => {
            return (
              <div>
                {setMetaTags({
                  title: "Paiement - La Cave des Vendanges d'Antoine",
                  description: "Ta cave à vin, sur mesure.",
                  imageUrl: socialBanner,
                })}
                <Paiement
                  backendurl={backendurl}
                  location={location}
                  props={props}
                  match={match}
                  logo={logo}
                />
              </div>
            );
          }}
        />
        <Route
          exact
          path="/blog/:article_slug"
          render={({ props, match }) => {
            return (
              <div>
                {setMetaTags({
                  title: "S.O.I.F Le Blog - La Cave",
                  description: "Ta cave à vin, sur mesure.",
                  imageUrl: socialBanner,
                })}
                <BlogArticle logo={logo} props={props} match={match} />
              </div>
            );
          }}
        />
        <Route
          render={() => {
            return (
              <div>
                {setMetaTags({
                  title: "Page introuvable - La Cave",
                  description: "Ta cave à vin, sur mesure.",
                  imageUrl: socialBanner,
                })}
                <NotFound />
              </div>
            );
          }}
        />
      </Switch>
      {/* </AnimatePresence> */}
    </div>
  );
}

export default App;
