import Faq from "../pages/Faq.js";
import React from "react";
import { useContentful } from "react-contentful";

function QueriesBlog(props) {
  const { data } = useContentful({
    contentType: "FAQ PAGE",
    query: {
      // "fields.slug[in]": `/${props.match.slug || ""}`,
      content_type: "faqPage",
      "sys.id": "7rMZ1NuwNrzxNwvRKMi4UZ",
    },
  });
  if (!data) {
    return null;
  }

  return <Faq logo={props.logo} data={data.items[0].fields} />;
}

export default QueriesBlog;
